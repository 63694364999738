const Informes = ({ items }) => {
  return (
    <div className="row">
        {items.map((items) => (
          <div className="col-md-2">
            <div key={items.id}>
              <a href={items.link} target="_blank" rel="noreferrer">
                <img
                  src={items.url}
                  className="img-fluid rounded-3 shadow-sm"
                  alt={items.alt}
                />
              </a>
            </div> 
            <br />
          </div>

        ))}
      
    </div>
    
  );
};

export default Informes;