import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Enlaces from "./enlaces/Enlaces";
import Informes from "./informes/Informes";
import Carousel from "../../layout/Carousel/Carousel/Carousel";
import CarouselItemIMG from "../../layout/Carousel/Carousel/CarouselItemIMG";
import ModalInit from "../../layout/Modal/ModalInit";

import { dataCIndex, dataCAR, dataCA } from "../../data/2024/dataCarouselIndex";
import { dataInformes } from "../../data/dataInformes";
import { modalsInitData } from "../../data/2024/dataIndex";
import Countdown from "./Countdown"; // Ajusta la ruta según tu estructura
import Micrositios from "./Micrositios";

import "./Home.css";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "@justinribeiro/lite-youtube";

const ITE = () => {
  useEffect(() => {
    document.title = `ITE`;
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleOpenModal = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const handleImageClick = (e, item) => {
    e.preventDefault();
    handleOpenModal(`https://itetlax.org.mx/assets/img/imgC_AR/${item.imgUrl}`);
  };

  return (
    <>
      {modalsInitData.map((modal) => (
        <ModalInit
          key={modal.id}
          imgLink={modal.imgLink}
          url={modal.url}
          linkToo={modal.linkToo}
        />
      ))}

      <Carousel idCarousel="carouselTopIndex">
        {dataCIndex.map((cIndex) => (
          <CarouselItemIMG
            key={cIndex.id}
            id={cIndex.id}
            imgUrl={"https://itetlax.org.mx/assets/img/imgC_I/" + cIndex.imgUrl}
            alt={cIndex.alt}
            itemActive={cIndex.itemActive}
            url={cIndex.url}
            linkToo={cIndex.linkToo}
          />
        ))}
      </Carousel>

      <Countdown />

      <h3 className="bottom_title mt-5 enlaces-custom">Enlaces</h3>
      <Enlaces />
      <br></br>
      <br></br>
      <div className="row g-3">
        {/* Columna Actividades de Interés */}
        <div className="col-md-6">
          {/* Título para móvil (fuera del card) */}
          <h3 className="bottom_title mt-3 mb-3 enlaces-custom d-md-none">
            Actividades de Interés
          </h3>

          <div className="card responsive-carousel-container">
            <div className="card-body p-0 d-flex flex-column">
              {/* Título para desktop (dentro del card) */}
              <h3 className="bottom_title mt-3 mb-3 enlaces-custom d-none d-md-block">
                Actividades de Interés
              </h3>

              <div className="responsive-carousel-wrapper position-relative">
                <div
                  id="responsiveCarousel1"
                  className="carousel slide h-100"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner h-100 m-0 p-0">
                    {dataCAR.map((item) => (
                      <div
                        key={`responsive-act-${item.id}`}
                        className={`carousel-item h-100 m-0 p-0 ${
                          item.itemActive === "active" ? "active" : ""
                        }`}
                      >
                        <div className="responsive-image-container d-flex justify-content-center align-items-center m-0 p-0">
                          {item.url ? (
                            <a
                              href={item.id !== 6 ? item.url : "#"}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0"
                              onClick={(e) =>
                                item.id === 6 ? handleImageClick(e, item) : null
                              }
                            >
                              <img
                                src={`https://itetlax.org.mx/assets/img/imgC_AR/${item.imgUrl}`}
                                alt={item.alt}
                                className="responsive-carousel-image"
                                style={{ cursor: "pointer" }}
                              />
                            </a>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0"
                              onClick={() =>
                                item.id === 6
                                  ? handleOpenModal(
                                      `https://itetlax.org.mx/assets/img/imgC_AR/${item.imgUrl}`
                                    )
                                  : null
                              }
                            >
                              <img
                                src={`https://itetlax.org.mx/assets/img/imgC_AR/${item.imgUrl}`}
                                alt={item.alt}
                                className="responsive-carousel-image"
                                style={{
                                  cursor: item.id === 6 ? "pointer" : "default",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="responsive-carousel-controls">
                  <button
                    className="responsive-carousel-btn prev"
                    data-bs-target="#responsiveCarousel1"
                    data-bs-slide="prev"
                  >
                    ‹
                  </button>
                  <button
                    className="responsive-carousel-btn next"
                    data-bs-target="#responsiveCarousel1"
                    data-bs-slide="next"
                  >
                    ›
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Columna Agenda */}
        <div className="col-md-6">
          {/* Título para móvil (fuera del card) */}
          <h3 className="bottom_title mt-3 mb-3 enlaces-custom d-md-none">
            Agenda
          </h3>

          <div className="card responsive-carousel-container">
            <div className="card-body p-0 d-flex flex-column">
              {/* Título para desktop (dentro del card) */}
              <h3 className="bottom_title mt-3 mb-3 enlaces-custom d-none d-md-block">
                Agenda
              </h3>

              <div className="responsive-carousel-wrapper position-relative">
                <div
                  id="responsiveCarousel2"
                  className="carousel slide h-100"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner h-100 m-0 p-0">
                    {dataCA.map((item) => (
                      <div
                        key={`responsive-age-${item.id}`}
                        className={`carousel-item h-100 m-0 p-0 ${
                          item.itemActive === "active" ? "active" : ""
                        }`}
                      >
                        <div className="responsive-image-container d-flex justify-content-center align-items-center m-0 p-0">
                          {item.url ? (
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0"
                            >
                              <img
                                src={`https://itetlax.org.mx/assets/img/imgC_A/${item.imgUrl}`}
                                alt={item.alt}
                                className="responsive-carousel-image"
                                style={{ cursor: "pointer" }}
                              />
                            </a>
                          ) : (
                            <img
                              src={`https://itetlax.org.mx/assets/img/imgC_A/${item.imgUrl}`}
                              alt={item.alt}
                              className="responsive-carousel-image"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="responsive-carousel-controls">
                  <button
                    className="responsive-carousel-btn prev"
                    data-bs-target="#responsiveCarousel2"
                    data-bs-slide="prev"
                  >
                    ‹
                  </button>
                  <button
                    className="responsive-carousel-btn next"
                    data-bs-target="#responsiveCarousel2"
                    data-bs-slide="next"
                  >
                    ›
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <style jsx>{`
        .responsive-carousel-container {
          height: 33vh; /* Aumentado de 28vh */
          min-height: 270px; /* Aumentado de 220px */
          width: 100%;
          margin: 0 auto;
          transition: all 0.3s ease;
          border: none !important;
          box-shadow: none !important;
          background-color: white !important;
          overflow: hidden;
        }

        .responsive-carousel-wrapper {
          height: 100%;
          width: 100%;
          background-color: white !important;
        }

        .responsive-image-container {
          height: 100%;
          width: 100%;
          background-color: white !important;
          position: relative;
        }

        .responsive-carousel-image {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          height: auto;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          transition: all 0.3s ease;
        }

        .carousel-inner,
        .carousel-item {
          height: 100%;
          background-color: white !important;
        }

        .responsive-carousel-controls {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          gap: 1vw;
          z-index: 100;
          padding-bottom: 0.5vh;
          background: none !important;
        }

        .responsive-carousel-btn {
          width: 2.5vw;
          height: 2.5vw;
          min-width: 25px;
          min-height: 25px;
          background-color: rgba(141, 7, 123, 0.93);
          color: white;
          border: none;
          border-radius: 50%;
          font-size: calc(12px + 0.3vw);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-bottom: 0.5vh;
        }

        .responsive-carousel-btn:hover {
          background-color: rgb(211, 19, 115);
          transform: scale(1.1);
        }

        @media (min-width: 576px) {
          .responsive-carousel-container {
            height: 38vh; /* +5vh */
            min-height: 290px; /* +50px */
          }
        }

        @media (min-width: 768px) {
          .responsive-carousel-container {
            height: 43vh;
            min-height: 320px;
          }
        }

        @media (min-width: 992px) {
          .responsive-carousel-container {
            height: 48vh;
            min-height: 370px;
          }
        }

        @media (min-width: 1200px) {
          .responsive-carousel-container {
            height: 53vh;
            min-height: 420px;
          }
        }

        @media (min-width: 1400px) {
          .responsive-carousel-container {
            height: 58vh;
            min-height: 470px;
          }
        }

        @media (min-width: 1600px) {
          .responsive-carousel-container {
            height: 63vh;
            min-height: 520px;
          }
        }

        @media (min-width: 1920px) {
          .responsive-carousel-container {
            height: 68vh;
            min-height: 570px;
          }

          .responsive-carousel-btn {
            min-width: 30px;
            min-height: 30px;
          }
        }

        @media (min-width: 2000px) {
          .responsive-carousel-container {
            height: 73vh;
            min-height: 620px;
          }
        }

        @media (max-width: 576px) and (orientation: landscape) {
          .responsive-carousel-container {
            height: 48vh;
            min-height: auto;
          }
        }
      `}</style>

      <Micrositios />

      <br></br>
      <br></br>

      <h3 className="bottom_title mt-5 enlaces-custom">
        Documentos de Interés
      </h3>
      <Informes items={dataInformes} itemsID={"itemsInformes"} />
      <br></br>
      <br></br>

      <div className="row">
        <div className="col-12 col-md-6">
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"
          />

          <h3 className="bottom_title mt-5 enlaces-custom">
            Estrados Electrónicos
          </h3>

          {/* Subtítulo pegado al botón */}
          <p
            className="text-center"
            style={{
              color: "#a90786",
              fontSize: "1.50rem",
              fontWeight: "700", // o 'bold' para negritas
              marginBottom: "2px",
              marginTop: "0",
              paddingBottom: "0",
            }}
          >
            ¡Consúltalos aquí!
          </p>
          <div
            style={{
              position: "relative",
              minHeight: "110px", // Aumentado ligeramente para acomodar el botón más abajo
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingTop: "0",
            }}
          >
            <i
              className="bi bi-hand-index-thumb-fill"
              style={{
                position: "absolute",
                top: "40px", // Ajustado para seguir al botón (antes 50px)
                left: "55%",
                transform: "translateX(-50%) rotate(-25deg)",
                fontSize: "3.0rem", // Aumentado de 2.3rem a 2.5rem
                color: "#ee8daf", // Cambiado de amarillo a blanco perlado
                zIndex: 2,
                animation: "pointing 1.5s infinite alternate",
                filter: "drop-shadow(2px 2px 2px rgba(0,0,0,0.2))",
              }}
            ></i>

            <a
              href="/EstradosElectronicos"
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "#a90786",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 0 0 0 rgba(169, 7, 134, 0.7)",
                animation: "pulse 2s infinite",
                textDecoration: "none",
                position: "relative",
                marginTop: "20px",
                border: "none",
                cursor: "pointer",
              }}
            ></a>
          </div>

          <style>{`
    @keyframes pulse {
      0% { transform: scale(0.95); box-shadow: 0 0 0 0 rgba(169, 7, 134, 0.7); }
      70% { transform: scale(1); box-shadow: 0 0 0 6px rgba(169, 7, 134, 0); }
      100% { transform: scale(0.95); box-shadow: 0 0 0 0 rgba(169, 7, 134, 0); }
    }
    @keyframes pointing {
      0% { transform: translateX(-50%) rotate(-25deg) translateY(0); }
      100% { transform: translateX(-50%) rotate(-25deg) translateY(-5px); }
    }
  `}</style>
        </div>

        {/* Sección de Transparencia (col-md-6) */}
        <div className="col-12 col-md-6 mt-4 mt-md-0">
          <h3 className="bottom_title mt-5 mb-5 enlaces-custom">
            Transparencia
          </h3>
          <div className="d-flex flex-column align-items-center gap-3">
            <a
              href="https://www.plataformadetransparencia.org.mx/"
              rel="noreferrer"
              className="w-100 text-center"
            >
              <img
                src="https://itetlax.org.mx/assets/img/icons/e9.png"
                alt="Plataforma Nacional de Transparencia"
                className="img-fluid w-60 shadow rounded-4"
              />
            </a>
            <a
              href="http://consultapublicamx.inai.org.mx:8080/vut-web/?idSujetoObigadoParametro=5387&idEntidadParametro=29&idSectorParametro=24"
              rel="noreferrer"
              className="w-100 text-center"
            >
              <img
                src="https://itetlax.org.mx/assets/img/icons/e10.png"
                alt="Consulta Publica"
                className="img-fluid w-60 shadow rounded-4"
              />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="mt-5 container">
        <h3 className="bottom_title mt-5 enlaces-custom">Videos INE</h3>
        <div
          id="carouselVideosINE"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <lite-youtube
                      videoid="jvOZFERXuiM"
                      playlabel="Cápsula en Náhuatl"
                      nocookie
                    ></lite-youtube>
                  </div>
                  <a
                    href="https://youtu.be/jvOZFERXuiM"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">Cápsula en Náhuatl</h5>
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <iframe
                      src="https://www.youtube-nocookie.com/embed/EEHZkyv-sTE"
                      title="Cápsula en Yuhmú (variante del Otomí)"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <a
                    href="https://youtu.be/EEHZkyv-sTE"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">
                      Cápsula en Yuhmú (variante del Otomí)
                    </h5>
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <lite-youtube
                      videoid="SvfEVaA6jF8"
                      playlabel="Votación de Personas con Discapacidad"
                      nocookie
                    ></lite-youtube>
                  </div>
                  <a
                    href="https://youtu.be/SvfEVaA6jF8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">
                      Votación de Personas con Discapacidad
                    </h5>
                  </a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <lite-youtube
                      videoid="7sg3kvUYxRY"
                      playlabel="Voto de Personas con Discapacidad Trans, No Binarias"
                      nocookie
                    ></lite-youtube>
                  </div>
                  <a
                    href="https://youtu.be/7sg3kvUYxRY"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">
                      Voto de Personas con Discapacidad Trans, No Binarias
                    </h5>
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <lite-youtube
                      videoid="dizDZWMNJxE"
                      playlabel="Proceso de Votación de las Personas con Discapacidad"
                      nocookie
                    ></lite-youtube>
                  </div>
                  <a
                    href="https://youtu.be/dizDZWMNJxE"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">
                      Proceso de Votación de las Personas con Discapacidad
                    </h5>
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="Home__video-embed ratio ratio-16x9 w-100">
                    <lite-youtube
                      videoid="r9X_4Jlju3E"
                      playlabel="Consulta Infantil y Juvenil 2024"
                      nocookie
                    ></lite-youtube>
                  </div>
                  <a
                    href="https://youtu.be/r9X_4Jlju3E"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="mt-3 text-center">
                      Consulta Infantil y Juvenil 2024
                    </h5>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselVideosINE"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselVideosINE"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
      <br></br>
      <br></br>

      <h3 className="bottom_title mt-5 enlaces-custom">Sitios de interés</h3>
      <div className="container mt-4 margin-bottom-1">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card border-0 shadow-hover">
              <a
                href="https://ine.mx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://itetlax.org.mx/assets/img/imgHome/ine.webp"
                  className="img-thumbnail"
                  style={{ width: "100%", height: "200px" }}
                  alt="Imagen 1"
                />
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card border-0 shadow-hover">
              <a
                href="https://www.te.gob.mx/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://itetlax.org.mx/assets/img/imgHome/tribunal-electoral.webp"
                  className="img-thumbnail"
                  style={{ width: "100%", height: "200px" }}
                  alt="Imagen 2"
                />
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card border-0 shadow-hover">
              <a
                href="https://www.observatoriomujerestlaxcala.mx/contents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://itetlax.org.mx/assets/img/imgHome/oppmt.webp"
                  className="img-thumbnail"
                  style={{ width: "100%", height: "200px" }}
                  alt="Imagen 3"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body className="text-center p-0">
          <div className="position-relative">
            {/* Imagen en el modal */}
            <img
              src={modalImage}
              alt="Imagen del Modal"
              className="img-fluid w-100"
            />

            <div className="position-absolute bottom-0 w-100 d-flex">
              <Button
                style={{
                  backgroundColor: "#5d2872",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: "white",
                  width: "50%",
                  borderRadius: "0",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#8a5a9b";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#5d2872";
                }}
                onClick={() =>
                  window.open(
                    "https://itetlax.org.mx/assets/pdf/carousel/Formulario.pdf",
                    "_blank"
                  )
                }
              >
                DESCARGAR FORMULARIO
              </Button>

              <Button
                style={{
                  backgroundColor: "#e55398",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: "white",
                  width: "50%",
                  borderRadius: "0",
                  fontWeight: "bold",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                  transition: "all 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#ff7faf";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#e55398";
                }}
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSdZb2vkV6LQwamOR7zY3XC_gIHwkq-qbuQoAiWm6rQWQx6Ktg/viewform",
                    "_blank"
                  )
                }
              >
                FORMULARIO EN LÍNEA
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ITE;
