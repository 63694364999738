import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import TitlePages from "../../layout/TitlePages";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../layout/Breadcrumbs";
import Expandible from "../../layout/HelperDataTable/Expandible";
import { FilterComponent, useCompleteTableConfig } from "../../constants";
import { dataAcuerdos2025 } from "../../data/2025/dataAcuerdos";
import {
  dataEstrados,
  dataAmonestaciones,
  dataInformes,
} from "../../data/2025/dataEstradosConvocatorias";

const baseUrlAcuerdos = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2025/";
const baseUrlMediosImpugnacion = "https://itetlax.org.mx/assets/pdf/MediosImpugnacion/2025/";

const PdfLink = ({ url, baseUrl }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const TableRow = ({ title, url, baseUrl }) =>
  title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <PdfLink url={url} baseUrl={baseUrl} />
      </td>
    </tr>
  ) : null;

const EstradosElectronicos = () => {
  useEffect(() => {
    document.title = `Estrados Electrónicos`;
  }, []);

  // Renderizado del panel de detalles para "Medios de impugnación"
  const renderDetailPanelEstrados = ({ row }) => {
    const { folio, pdfMedio, pdfCedula } = row.original;
  
    return (
      <Box
        id="Box"
        sx={{
          marginLeft: "56px",
          width: "calc(100% - 56px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {/* Primer inciso: FOLIO */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px", // Espacio entre los incisos
          }}
        >
          <PdfLink url={pdfMedio} baseUrl={baseUrlMediosImpugnacion} /> {/* Usa la ruta específica */}
          <span style={{ marginLeft: "8px" }}>{`FOLIO ${folio}`}</span>
        </Box>
  
        {/* Segundo inciso: CÉDULA DE PUBLICITACIÓN FOLIO */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PdfLink url={pdfCedula} baseUrl={baseUrlMediosImpugnacion} /> {/* Usa la ruta específica */}
          <span style={{ marginLeft: "8px" }}>
            {`CÉDULA DE PUBLICITACIÓN FOLIO ${folio}`}
          </span>
        </Box>
      </Box>
    );
  };

  // Renderizado del panel de detalles para "Amonestaciones Públicas"
  const renderDetailPanelAmonestaciones = ({ row }) => {
    const { numCedula, pdfUrl } = row.original;

    return (
      <Box
        id="Box"
        sx={{
          marginLeft: "56px",
          width: "calc(100% - 56px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Icono de PDF */}
        <PdfLink url={pdfUrl} baseUrl={baseUrlAcuerdos} />
        <span style={{ marginLeft: "8px" }}>
          {`AMONESTACIÓN PUBLICA ITE-CG ${numCedula}-2025`}
        </span>
      </Box>
    );
  };
  
  // Renderizado del panel de detalles para "Informes"
  const renderDetailPanelInformes = ({ row }) => {
    const { titulo, linkFile } = row.original;

    return (
      <Box
        id="Box"
        sx={{
          textAlign: "left",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div className="table-responsive" style={{ width: "100%" }}>
          <table className="table table-hover table-sm table-bordered table align-middle w-100">
            <thead></thead>
            <tbody>
              <tr className="table-secondary">
                <td className="text-left">
                  <PdfLink url={linkFile} baseUrl="" />
                </td>
                <td
                  className="text-left"
                  style={{
                    width: "100%",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    whiteSpace: "normal",
                  }}
                >
                  {titulo}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const columnsEstrados = useMemo(
    () => [
      {
        accessorFn: (row) => `MEDIO DE IMPUGNACIÓN ${row.folio}`, // Texto fijo + folio personalizado
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  // Columnas para "Amonestaciones Públicas"
  const columnsAmonestaciones = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `AMONESTACIÓN PUBLICA ITE-CG ${row.numCedula}-2025`,
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 400,
      },
    ],
    []
  );

  // Columnas para "Informes"
  const columnsInformes = useMemo(
    () => [
      {
        accessorKey: "mes",
        header: "MES",
        footer: "MES",
        size: 100,
      },
      {
        accessorKey: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 500,
      },
    ],
    []
  );

  // Configuración de la tabla para "Medios de impugnación"
  const tableEstrados = useCompleteTableConfig(
    dataEstrados,
    columnsEstrados,
    renderDetailPanelEstrados // Panel de detalles
  );

  // Configuración de la tabla para "Amonestaciones Públicas"
  const tableAmonestaciones = useCompleteTableConfig(
    dataAmonestaciones,
    columnsAmonestaciones,
    renderDetailPanelAmonestaciones // Panel de detalles
  );

  // Configuración de la tabla para "Informes"
  const tableInformes = useCompleteTableConfig(
    dataInformes,
    columnsInformes,
    renderDetailPanelInformes // Panel de detalles
  );

  // Columnas para "Acuerdos 2025"
  const columnsAcuerdos = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorFn: (row) => `ITE-CG ${row.id}-2025`,
        id: "acuerdo",
        header: "ACUERDO",
        footer: "ACUERDO",
        size: 55,
      },
      {
        accessorFn: (row) =>
          row.nameDoc ? `${row.typeDoc} ${row.nameDoc}` : "",
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  // Renderizado del panel de detalles para "Acuerdos 2025"
  const renderDetailPanelAcuerdos = ({ row }) => {
    const anexoTitles = Object.keys(row.original).filter(
      (key) => key.startsWith("titleAnexo") && row.original[key]
    );

    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={2}>
                  <br />
                  <strong>A C U E R D O</strong>
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>
                  {`${row.original.typeDoc} ITE-CG ${row.original.id}-2025 ${
                    row.original.nameDoc || ""
                  }`}
                </td>
                <td>
                  {row.original.id && (
                    <PdfLink
                      baseUrl={baseUrlAcuerdos}
                      url={row.original.id + ".pdf"}
                    />
                  )}
                </td>
              </tr>
              {anexoTitles.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <br />
                    <strong>A N E X O</strong>
                    <br />
                    <br />
                  </td>
                </tr>
              )}
              {anexoTitles.map((key) => {
                const index = key.replace("titleAnexo", "");
                return (
                  <TableRow
                    key={index}
                    title={row.original[key]}
                    baseUrl={baseUrlAcuerdos}
                    url={`${row.original.id}.${index}.pdf`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  // Configuración de la tabla para "Acuerdos 2025"
  const tableAcuerdos = useCompleteTableConfig(
    dataAcuerdos2025,
    columnsAcuerdos,
    renderDetailPanelAcuerdos
  );

  return (
    <>
      <div className="margin-bottom-2">
        {/* Contenedor fijo para el título y el breadcrumb */}
        <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1000, padding: '10px 0' }}>
          <Breadcrumbs path={[{ label: "Estrados Electrónicos" }]} />
          <TitlePages title="Estrados Electrónicos" subTitle="" />
        </div>

        <Expandible />

        {/* Tabla de "Medios de impugnación" */}
        <div className="w-75 mx-auto mt-5">
          <TitlePages title="" subTitle="Medios de impugnación" />
        </div>
        <MaterialReactTable table={tableEstrados} />
        <br />

        {/* Tabla de "Amonestaciones Públicas" */}
        <div className="w-75 mx-auto mt-5">
          <TitlePages title="" subTitle="Amonestaciones Publicas" />
        </div>
        <MaterialReactTable table={tableAmonestaciones} />
        <br />
        <br />

        {/* Tabla de "Acuerdos 2025" */}
        <div className="w-75 mx-auto mt-5">
          <TitlePages title="" subTitle="Acuerdos 2025" />
        </div>
        <MaterialReactTable table={tableAcuerdos} />
        <br />
        <br />

        {/* Tabla de "Informes" */}
        <div className="w-75 mx-auto mt-5">
          <TitlePages
            title=""
            subTitle="Información relativa al Proceso Electoral Local Extraordinario del Poder Judicial 2024-2025."
          />
        </div>
        <MaterialReactTable table={tableInformes} />
        <br />
        <br />
      </div>
    </>
  );
};

export default EstradosElectronicos;

// PropTypes
PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};