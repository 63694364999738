import React from "react";
import TitlePages from "../../../../layout/TitlePages";
import Breadcrumbs from "../../../../layout/Breadcrumbs";
import { dataPlanes } from "../../../../data/Planes/dataPlanes"; 

const PlanesdeTrabajo = () => {
  const planes2024 = dataPlanes.filter((plan) => plan.year === 2024);

  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Transparencia", url: "/Transparencia" },
            { label: "Planes de Trabajo", url: "/Section" },
            { label: "2024" },
          ]}
        />

        <TitlePages title="Transparencia" subTitle="Planes de Trabajo 2024" />

        <div className="w-100 mt-3 Content__grid">
          {planes2024.map((plan) => (
            <div key={plan.id} className="margin-bottom-1">
              <a href={plan.url} target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid hover-effect"
                  src={plan.image}
                  alt={"Plan de Trabajo " + plan.title}
                />
              </a>
              <p className="mt-2">{plan.title.toUpperCase()}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PlanesdeTrabajo;