import React, { useEffect } from "react";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";

const Jge = () => {
  useEffect(() => {
    document.title = `Junta General Ejecutiva`;
  }, []);

  return (
    <>

<div class="margin-bottom-2">

   
      <Breadcrumbs path={[{ label: "Junta General Ejecutiva" }]} />
      <TitlePages title="Junta General Ejecutiva" subTitle="" />
      <a
        href="https://itetlax.org.mx/assets/img/imgIntITE/no2.webp"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://itetlax.org.mx/assets/img/imgIntITE/no2.webp"
          className="img-fluid w-100 mx-auto"
          alt="JGE"
        />
      </a>
      </div>
    </>
    
  );
};
export default Jge;
