// 2014
export const convenios2014 = [
  {
    id: 1,
    title: "CONVENIO RECAUDACIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2014/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2014,
  },
];

// 2015
export const convenios2015 = [
    {
      id: 1,
      title: "CONVENIO DE COORDINACIÓN SPF RF ISR ITE",
      url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2015/1.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2015,
    },
    {
      id: 2,
      title: "CONVENIO INE",
      url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2015/2.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2015,
    },
    {
      id: 3,
      title: "CONVENIO GENERAL INE ITE",
      url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2015/3.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2015,
    },
];

// 2016
export const convenios2016 = [
  {
    id: 1,
    title: "CONVENIO CAIPTLAX",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2016/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2016,
  },
  {
    id: 2,
    title: "CONVENIO FEPADE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2016/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2016,
  },
  {
    id: 3,
    title: "CONVENIO COPARMEX",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2016/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2016,
  },
];

// 2017
export const convenios2017 = [
  {
    id: 1,
    title: "CONVENIO TRIBUNAL ELECTORAL DE JALISCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 2,
    title: "CONVENIO TRIBUNAL ELECTORAL DE TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 3,
    title: "CONVENIO ESPECÍFICO ITE EL TRIEJAL",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 4,
    title: "CONVENIO GENERAL DE COLABORACIÓN ITE TEJ IIE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 5,
    title: "CONVENIO MARCO DE COORDINACIÓN ITE INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 6,
    title: "CONVENIO ITE IPN 18",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
  {
    id: 7,
    title: "CONVENIO ITE SPF RF ISR",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2017/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2017,
  },
];

// 2018
export const convenios2018 = [
  {
    id: 1,
    title: "CONVENIO ITE SPF RF ISR.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 2,
    title: "CONVENIOS ITE TRIBUNAL ELECTORAL DE JALISCO.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 3,
    title: "CONVENIO ITE IEM TET.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 4,
    title:
      "CONVENIO INSITUTO ESTATAL DE LA MUJER, TRIBUNAL ELECTORAL DE TLAXCALA.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 5,
    title: "CONVENIO UNAM ANEXO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 6,
    title: "CONVENIO ITE LA UNAM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 7,
    title: "CONVENIO ITE EL EJECUTIVO ESTATAL",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 8,
    title: "CONVENIO ITE LA ASOCIACIÓN  DE RESTAURANTES CAFETERIAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 9,
    title: "CONVENIO ITE TET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 10,
    title: "CONVENIO ITE INE TLX SEPE USET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/10.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 11,
    title: "CONVENIO ITE INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/11.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 12,
    title: "CONVENIO ITE UVT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/12.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 13,
    title: "CONVENIO ITE LA UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/13.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 14,
    title: "CONVENIO ITE COESPO SIPINNA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/14.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
  {
    id: 15,
    title: "CONVENIO ITE CECUTLAX",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2018/15.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2018,
  },
];


// 2019
export const convenios2019 = [
  {
    id: 1,
    title: "CONVENIO ITE COORDINACIÓN FISCAL",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2019/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2019,
  },

  {
    id: 2,
    title: "CONVENIO ITE AYUNTAMIENTO APIZACO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2019/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2019,
  },
  {
    id: 3,
    title: "CONVENIO ITE EL CECUTLAX IPN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2019/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2019,
  },
  {
    id: 4,
    title: "CONVENIO ITE UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2019/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2019,
  },
  {
    id: 5,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2019/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2019,
  },
];


// 2020
export const convenios2020 = [
  {
    id: 1,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 2,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 3,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 4,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 5,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 6,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 7,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 8,
    title: "CONVENIO ITE  SHCP UIF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },
  {
    id: 9,
    title: "CONVENIO SEPE-INE-ITE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2020/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2020,
  },

];


// 2021
export const convenios2021 = [
  {
    id: 1,
    title: "CONVENIO ACUERDO DE TRABAJO PARTICIPACIÓN CIUDADANA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 2,
    title: "CONVENIO ITE PCG-0209",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 3,
    title: "CONVENIO ITE-IMPUESTO SOBRE LA RECAUDACIÓN DE RENTA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 4,
    title: "CONVENIO TET-ITE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 5,
    title: "CONVENIO ITE-PODER JUDICIAL DEL ESTADO DE TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 6,
    title: "CONVENIO ITE-IEM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 7,
    title: "CONVENIO ITE-CONALEP",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 8,
    title: "CONVENIO ITE-COBAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 9,
    title: "CONVENIO ITE-CECYTE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 10,
    title: "CONVENIO ITE-COMISIÓN EJECUTIVA DEL SISTEMA ANTICORRUPCIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/10.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },

  {
    id: 11,
    title: "CONVENIO ITE-SEPE USET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/11.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 12,
    title: "CONVENIO ITE-CONTRALORÍA DEL EJECUTIVO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/12.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 13,
    title: "CONVENIO ITE-PGJ-CJM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/13.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 14,
    title: "CONVENIO INE ITE ANEXO FINANCIERO DEL ANEXO TÉCNICO NÚMERO UNO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/14.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 15,
    title: "CONVENIO ITE ANTICORREPCIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/15.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 16,
    title: "CONVENIO ITE CECYTE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/16.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 17,
    title: "CONVENIO ITE CEDH",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/17.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 18,
    title: "CONVENIO ITE CJET PJET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/18.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 19,
    title: "CONVENIO ITE COBAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/19.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 20,
    title: "CONVENIO ITE COLTLAX",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/20.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 21,
    title: "CONVENIO ITE CONALEP",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/21.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 22,
    title: "CONVENIO ITE CONTRALORÍA DEL EJECUTIVO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/22.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 23,
    title: "CONVENIO ITE IEM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/23.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 24,
    title: "CONVENIO ITE IMJUVE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/24.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 25,
    title: "CONVENIO ITE INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/25.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 26,
    title: "CONVENIO ITE INE GOBIERNO DEL ESTADO SSC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/26.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 27,
    title: "CONVENIO ITE OFIAL MAYOR DE GOBIERNO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/27.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 28,
    title: "CONVENIO ITE OFICIAL MAYOR DE GOBIERNO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/28.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 29,
    title: "CONVENIO ITE PGJE Y CJM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/29.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 30,
    title: "CONVENIO ITE RECAUDACIÓN SOBRE LA RENTA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/30.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 31,
    title: "CONVENIO ITE TET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/31.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 32,
    title: "CONVENIO ITE UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/32.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 33,
    title: "CONVENIO ITE USET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/33.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
  {
    id: 34,
    title: "CONVENIO LA UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2021/34.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2021,
  },
];


// 2022
export const convenios2022 = [
  {
    id: 1,
    title: "CONVENIO MPIO. SAN FRANCISCO TETLANOHCAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 2,
    title: "CONVENIO RED MUNDIAL DE JOVENES POLÍTICOS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 3,
    title: "CONVENIO UAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 4,
    title: "CONVENIO UNAM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 5,
    title: "CONVENIO UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 6,
    title: "CONVENIO IEM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 7,
    title: "CONVENIO INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 8,
    title: "CONVENIO MPIO. AMAXAC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 9,
    title: "CONVENIO MPIO. AMAXAC DE GUERREO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 10,
    title: "CONVENIO MPIO. APIZACO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/10.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 11,
    title: "CONVENIO MPIO. BENITO JUÁREZ",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/11.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 12,
    title: "CONVENIO MPIO. BENITO JUÁREZ",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/12.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 13,
    title: "CONVENIO MPIO. CALPULALPAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/13.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 14,
    title: "CONVENIO MPIO. CALPULALPAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/14.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 15,
    title: "CONVENIO MPIO. CHIAUTEMPAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/15.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 16,
    title: "CONVENIO MPIO. CUAXOMULCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/16.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 17,
    title: "CONVENIO MPIO. CUAXOMULCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/17.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 18,
    title: "CONVENIO MPIO. EMILIANO ZAPATA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/18.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 19,
    title: "CONVENIO MPIO. EMILIANO ZAPATA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/19.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 20,
    title: "CONVENIO MPIO. IXTACUIXTLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/20.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 21,
    title: "CONVENIO MPIO. IXTENCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/21.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 22,
    title: "CONVENIO MPIO. LA MAGDALENA TLALTELULCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/22.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 23,
    title: "CONVENIO MPIO. LÁZARO CÁRDENAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/23.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 24,
    title: "CONVENIO MPIO. MUÑOZ DOMINGO ARENAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/24.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 25,
    title: "CONVENIO MPIO. PANOTLA 07 SEP",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/25.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 26,
    title: "CONVENIO MPIO. PANOTLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/26.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 27,
    title: "CONVENIO MPIO. SAN FCO. TETLANOHCAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/27.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 28,
    title: "CONVENIO MPIO. SAN JOSÉ TEACALCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/28.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 29,
    title: "CONVENIO MPIO. SAN JUAN HUACTZINCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/29.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 30,
    title: "CONVENIO MPIO. SAN LORENZO AXOCOMANITLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/30.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 31,
    title: "CONVENIO MPIO. SAN PABLO DEL MONTE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/31.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 32,
    title: "CONVENIO MPIO. SANTA CRUZ TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/32.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 33,
    title: "CONVENIO MPIO. SANTA ISABEL XILOXOXTLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/33.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 34,
    title: "CONVENIO MPIO. TEPETITLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/34.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 35,
    title: "CONVENIO MPIO. TEPEYANCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/35.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 36,
    title: "CONVENIO MPIO. TETLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/36.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 37,
    title: "CONVENIO MPIO. TEXOLOC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/37.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 38,
    title: "CONVENIO MPIO. TOTOLAC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/38.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 39,
    title: "CONVENIO MPIO. TZOMPANTEPEC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/39.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 40,
    title: "CONVENIO MPIO. TZOMPANTEPEC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/40.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 41,
    title: "CONVENIO MPIO. XICOHTZINCO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/41.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 42,
    title: "CONVENIO MPIO. XILOXOXTLA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/42.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 43,
    title: "CONVENIO MPIO. ZACUALPAN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/43.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 44,
    title: "CONVENIO MPIO. ZITLALTEPEC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/44.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 45,
    title: "CONVENIO RED MUNDIAL DE JOVENES POLITICOS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/45.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 46,
    title: "CONVENIO SECRETARÍA DE FINANZAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/46.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 47,
    title: "CONVENIO SEPE USET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/47.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 48,
    title: "CONVENIO TRANSPARENCIA ELECTORAL",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/48.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 49,
    title: "CONVENIO UAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/49.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 50,
    title: "CONVENIO UNAM",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/50.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },
  {
    id: 51,
    title: "CONVENIO UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2022/51.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2022,
  },

];


// 2023
export const convenios2023 = [
  {
    id: 1,
    title: "CONVENIO ITE - CIUDADANÍA A.C",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 2,
    title: "CONVENIO ITE - SECRETARÍA DE FINANZAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 3,
    title: "CONVENIO ITE - UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 4,
    title: "CONVENIO ITE - SISTEMA ANTICORRUPCIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 5,
    title: "CONVENIO CEDH",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 6,
    title: "CONVENIO COLEGIO DE TLAXCALA, A.C.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 7,
    title: "CONVENIO DDESER",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 8,
    title: "CONVENIO GENERAL INE-ITE PEC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 9,
    title: "CONVENIO INPI",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 10,
    title: "CONVENIO ITE LA FUNDACIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/10.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 11,
    title: "CONVENIO SECRETARÍA DE BIENESTAR TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/11.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 12,
    title: "CONVENIO UAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/12.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 13,
    title: "CONVENIO UAT FACULTAD DERECHO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/13.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 14,
    title: "CONVENIO IEM TET ITE  ",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/14.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 15,
    title: "CONVENIO ITE-PJET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/15.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 16,
    title: "CONVENIO ITE INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/16.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 17,
    title: "CONVENIO ITE EL COLEGIO DE TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/17.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 18,
    title: "CONVENIO CEDH",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/18.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 19,
    title: "CONVENIO CIUDADANÍAS, A.C.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/19.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 20,
    title: "CONVENIO COLEGIO DE TLAXCALA, A.C.",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/20.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 21,
    title: "CONVENIO DDESER",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/21.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 22,
    title: "CONVENIO GENERAL INE-ITE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/22.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 23,
    title: "CONVENIO IEM TET ITE ",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/23.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 24,
    title: "CONVENIO INPI",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/24.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 25,
    title: "CONVENIO ITE EL COLEGIO DE TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/25.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 26,
    title: "CONVENIO ITE INE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/26.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 27,
    title: "CONVENIO ITE LA FUNDACIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/27.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 28,
    title: "CONVENIO ITE-PJET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/28.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 29,
    title: "CONVENIO LA UPET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/29.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 30,
    title: "CONVENIO PLATAFORMA DIGITAL",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/30.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 31,
    title: "CONVENIO SECRETARÍA DE BIENESTAR TLAXCALA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/31.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 32,
    title: "CONVENIO SECRETARÍA FINANZAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/32.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 33,
    title: "CONVENIO UAT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/33.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 34,
    title: "CONVENIO UAT 21 AGO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/34.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
  {
    id: 35,
    title: "CONVENIO UAT FACULTAD DERECHO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2023/35.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2023,
  },
];


// 2024
export const convenios2024 = [
  {
    id: 1,
    title: "CONVENIO ITE Y EL INSTITUTO ESTATAL ELECTORAL DE CHIHUAHUA",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 2,
    title: "CONVENIO INE ITE Y GOBIERNO DEL ESTADO",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 3,
    title: "CONVENIO ITE Y AMCE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 4,
    title: "CONVENIO ITE Y SF",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 5,
    title: "CONVENIO ITE Y CANIRAC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 6,
    title: "CONVENIO ITE Y LA SMYT",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/6.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 7,
    title: "CONVENIO ITE Y SAET",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/7.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 8,
    title: "CONVENIO ITE Y DIRECCIÓN DE ATENCIÓN A MIGRANTE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/8.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 9,
    title: "CONVENIO DE APOYO Y COLABORACIÓN ITE INSTITUTO ELECTORAL DE CHI",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/9.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 10,
    title: "CONVENIO DE COLABORACIÓN ITE AMCEE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/10.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 11,
    title: "CONVENIO DE COLABORACIÓN ITE LA CANIRAC",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/11.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 12,
    title: "CONVENIO DE COLABORACIÓN ITE LA SECRETARIA DE MOVILIDAD Y TRANS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/12.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 13,
    title: "CONVENIO DE COLABORACIÓN SISTEMA DE ANTICORRUPCIÓN",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/13.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 14,
    title: "CONVENIO GENERAL Y APOYO ITE MIGRANTES DEL GOB. DEL ESTADO 02 A",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/14.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 15,
    title: "CONVENIO ITE SECRETARÍA DE FINANZAS",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/15.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
  {
    id: 16,
    title: "CONVENIO MARCO DE COLABORACIÓN GOB ESTADO INE ITE",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2024/16.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2024,
  },
];

export const convenios2025 = [
  {
    id: 1,
    title: "CONVENIO DE COORDINACIÓN SECRETARÍA DE FINANZAS ITE 16 ENE 2025",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2025/1.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2025,
  },
  {
    id: 2,
    title: "CONVENIO USET INE ITE 29 ENE 2025",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2025/2.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2025,
  },
  {
    id: 3,
    title: "CONVENIO ITE AMCEE 31 ENE 2025",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2025/3.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2025,
  },
  {
    id: 4,
    title: "CONVENIO DE COLABORACIÓN UPET Y ITE 25 FEB 2025",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2025/4.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2025,
  },
  {
    id: 5,
    title: "CONVENIO GENERAL DE COORDINACIÓN Y COLABORACIÓN INE ITE 14 MAR ",
    url: "https://itetlax.org.mx/assets/pdf/transparencia/convenios/2025/5.pdf",
    image: "https://itetlax.org.mx/assets/img/icons/2.png",
    year: 2025,
  },
];



export const dataConvenios = [
  ...convenios2014,
  ...convenios2015,
  ...convenios2016,
  ...convenios2017,
  ...convenios2018,
  ...convenios2019,
  ...convenios2020,
  ...convenios2021,
  ...convenios2022,
  ...convenios2023,
  ...convenios2024,
  ...convenios2025,
];