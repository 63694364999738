import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { useCompleteTableConfig } from "../../constants";
import { dataInstrumentos } from "../../data/2024/dataArchivo";

const baseUrl = "https://itetlax.org.mx/assets/pdf/archivistico/";

const FileLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}.pdf`;

  return (
    <a className="link" href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const InstrumentosNormativos = () => {
  useEffect(() => {
    document.title = `Instrumentos Archivísticos`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "NO.",
        footer: "NO.",
      },
      {
        accessorKey: "nameDoc",
        header: "Normatividad",
        footer: "Normatividad",
      },
      {
        accessorKey: "post",
        header: "Publicada",
        footer: "Publicada",
      },
    ],
    []
  );

  const renderArchivos = ({ row }) => {
    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={2}>
                  <br />
                  <strong>A R C H I V O S</strong>
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>
                  {row.original.pdf && <FileLink url={row.original.pdf} />}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const table = useCompleteTableConfig(
    dataInstrumentos,
    columns,
    renderArchivos
  );

  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs path={[{ label: "Instrumentos Archivísticos" }]} />
        <TitlePages title="Archivo" subTitle="Instrumentos Archivísticos" />
        <MaterialReactTable table={table} />
      </div>
    </>
  );
};

export default InstrumentosNormativos;

FileLink.propTypes = {
  url: PropTypes.string.isRequired,
};
