import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";

const NavbarMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  // Cerrar el menú al cambiar de ruta
  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const handleLinkClick = () => {
    setExpanded(false); // Contraer el menú al hacer clic en un enlace
  };

  return (
    <Navbar
      expanded={expanded}
      expand="lg"
      className="bg-body-tertiary mb-0 sticky-top mt-2"
    >
      <Container>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)} // Alternar expansión del menú
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="mx-auto">
            {/* Acerca de ITE */}
            <NavDropdown title="Acerca de ITE" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./FinesInstitucionales" onClick={handleLinkClick}>
                Fines Institucionales
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./Historia" onClick={handleLinkClick}>
                Historia
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./PrincipiosRectores" onClick={handleLinkClick}>
                Principios Rectores
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./EstructuraOrganica" onClick={handleLinkClick}>
                Estructura Orgánica
              </NavDropdown.Item>
            </NavDropdown>

            {/* Consejo General */}
            <NavDropdown title="Consejo General" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./Integracion" onClick={handleLinkClick}>
                Integración
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./Comisiones" onClick={handleLinkClick}>
                Comisiones Permanentes y Temporales
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./JGE" onClick={handleLinkClick}>
                Junta General Ejecutiva
              </NavDropdown.Item>
            </NavDropdown>

            {/* Acuerdos */}
            <NavDropdown title="Acuerdos" id="basic-nav-dropdown">
              <NavDropdown.ItemText className="text-secondary">
                Acuerdos ITE
              </NavDropdown.ItemText>
              <NavDropdown title="Anteriores" id="anterioresITE" drop="end">
                <NavDropdown.Item as={Link} to="./AcuerdosAnteriores" onClick={handleLinkClick}>
                  Mostrar todos
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {[2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015].map((year) => (
                  <NavDropdown.Item
                    key={year}
                    as={Link}
                    to={`./Acuerdos${year}`}
                    onClick={handleLinkClick}
                  >
                    {year}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown.Item as={Link} to="./Acuerdos2025" onClick={handleLinkClick}>
                2025
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.ItemText className="text-secondary">
                Acuerdos INE
              </NavDropdown.ItemText>
              <NavDropdown title="Anteriores" id="anterioresINE" drop="end">
                <NavDropdown.Item as={Link} to="./AcuerdosINE" onClick={handleLinkClick}>
                  Mostrar todos
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {[2024, 2023, 2022, 2021, 2020, 2016, 2015].map((year) => (
                  <NavDropdown.Item
                    key={year}
                    as={Link}
                    to={`./AcuerdosINE${year}`}
                    onClick={handleLinkClick}
                  >
                    {year}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown.Item as={Link} to="./AcuerdosINE2025" onClick={handleLinkClick}>
                2025
              </NavDropdown.Item>
            </NavDropdown>

            {/* Demarcaciones */}
            <NavDropdown title="Demarcaciones" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./Acuerdos" onClick={handleLinkClick}>
                Acuerdos
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./Mapas" onClick={handleLinkClick}>
                Mapas
              </NavDropdown.Item>
            </NavDropdown>

            {/* Transparencia */}
            <NavDropdown title="Transparencia" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./Transparencia" onClick={handleLinkClick}>
                Transparencia
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./Infografias" onClick={handleLinkClick}>
                Infografías
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./PreguntasFrecuentes" onClick={handleLinkClick}>
                Preguntas Frecuentes
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./DatosAbiertos" onClick={handleLinkClick}>
                Datos Abiertos
              </NavDropdown.Item>
            </NavDropdown>

            {/* Comunicación Social */}
            <NavDropdown title="Comunicación Social" id="basic-nav-dropdown">
              <NavDropdown title="Boletines" id="boletinesITE" drop="end">
                {[2025, 2024, 2023, 2022, 2021, 2020, 2019, 2017, 2016, 2015].map((year) => (
                  <NavDropdown.Item
                    key={year}
                    as={Link}
                    to={`./Boletines${year}`}
                    onClick={handleLinkClick}
                  >
                    {year}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown title="Monitoreos" id="monitoreosITE" drop="end">
                {[2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024].map((year) => (
                  <NavDropdown.Item
                    key={year}
                    as={Link}
                    to={`./Monitoreos${year}`}
                    onClick={handleLinkClick}
                  >
                    {year}
                  </NavDropdown.Item>
                ))}
                <NavDropdown.Item as={Link} to="./MonitoreosPELE2024" onClick={handleLinkClick}>
                  PELE 2024
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item as={Link} to="./ITEConecta" onClick={handleLinkClick}>
                ITE Conecta
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./EnCorto" onClick={handleLinkClick}>
                En Corto
              </NavDropdown.Item>
            </NavDropdown>

            {/* Cultura Cívica */}
            <NavDropdown title="Cultura Cívica" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./ActividadesLudicas" onClick={handleLinkClick}>
                Actividades Lúdicas
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./EleccionesEscolares" onClick={handleLinkClick}>
                Elecciones Escolares
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./ParlamentoInfantil" onClick={handleLinkClick}>
                Parlamento Infantil
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./ParlamentoJuvenil" onClick={handleLinkClick}>
                Parlamento Juvenil
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./ValoresDemocracia" onClick={handleLinkClick}>
                Valores de la Democracia
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://itetlax.org.mx/assets/pdf/ccivica/2.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                Catálogo de Acciones en Educación Cívica
              </NavDropdown.Item>
            </NavDropdown>

            {/* Archivo */}
            <NavDropdown title="Archivo" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="./InstrumentosNormativos" onClick={handleLinkClick}>
                Instrumentos Normativos
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./PlaneacionArchivistica" onClick={handleLinkClick}>
                Planeación Archivística
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="./InstrumentosArchivisticos" onClick={handleLinkClick}>
                Instrumentos Archivísticos
              </NavDropdown.Item>
            </NavDropdown>

            {/* Enlaces adicionales */}
            <Nav.Link as={Link} to="/Licitaciones" onClick={handleLinkClick}>
              Licitaciones
            </Nav.Link>
            <Nav.Link
              href="https://encuesta-satisfaccion.itetlax.org.mx/"
              target="_blank"
              rel="noreferrer"
              onClick={handleLinkClick}
            >
              Encuesta de Satisfacción
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMenu;