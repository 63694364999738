import React, { useState, useEffect } from "react";
import NavbarEnlaces from "../../layout/NavbarEnlaces";
import ListBadge from "../../layout/ListBadge";
import {
  dataPartidosPoliticos,
  dataPartidosPoliticos2,
} from "../../data/dataEnlacesPP";
import { Link } from "react-router-dom";

export const Accordion = ({ idAccordion, children }) => {
  return (
    <div className="accordion" id={idAccordion}>
      {children}
    </div>
  );
};

export const AccordionItem = ({
  flushID,
  titleItem,
  contentItem,
  idAccordion,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${flushID}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`collapse-${flushID}`}
        >
          {titleItem}
        </button>
      </h2>
      <div
        id={`collapse-${flushID}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading-${flushID}`}
        data-bs-parent={`#${idAccordion}`}
      >
        <div className="accordion-body">{contentItem}</div>
      </div>
    </div>
  );
};

export const PartidosPoliticos = () => {
  return (
    <div>
      <ListBadge ifNumbered={""} listsBadgeItem={dataPartidosPoliticos} />
    </div>
  );
};

export const PartidosPoliticos2 = () => {
  useEffect(() => {
    document.title = `Partidos Políticos`;
  }, []);

  const filteredData = dataPartidosPoliticos2.filter(
    (item) =>
      item.titleItem === "Documentos Básicos" ||
      item.titleItem === "Procesos Electorales Locales" 
  );

  console.log("Datos filtrados:", filteredData); 

  return (
    <>
      <div className="margin-bottom-2">
        <Accordion idAccordion="PartidosPoliticos2">
          {filteredData.map((infoAccordion) => (
            <AccordionItem
              key={infoAccordion.id}
              flushID={infoAccordion.flushID}
              titleItem={
                infoAccordion.titleItem === "Procesos Electorales Locales"
                  ? "Plataformas Electorales" 
                  : infoAccordion.titleItem
              }
              contentItem={
                infoAccordion.titleItem === "Procesos Electorales Locales" ? (
                  <div className="w-100 mt-5 Grid__folder margin-bottom-2 d-flex justify-content-center">
                    {infoAccordion.children.map((carpeta) => (
                      <div className="text-center mx-5" key={carpeta.id}>
                        <Link to={carpeta.link}>
                          <img
                            className="img-fluid"
                            src={carpeta.icon}
                            alt={carpeta.title}
                          />
                        </Link>
                        <div
                          className="mt-2"
                          dangerouslySetInnerHTML={{ __html: carpeta.title }} 
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <ListBadge
                    ifNumbered={""}
                    listsBadgeItem={infoAccordion.children}
                  />
                )
              }
              idAccordion="PartidosPoliticos2"
            />
          ))}
        </Accordion>
      </div>
    </>
  );
};
export default function MainComponent() {
  return (
    <div>
      <NavbarEnlaces title="Partidos Políticos" />
      <PartidosPoliticos />
      <PartidosPoliticos2 />
    </div>
  );
}
