import React from "react";
import ListBadge from "../../layout/ListBadge";
import { dataPartidosPoliticos2 } from "../../data/dataEnlacesPP";

const ProcesOrdin = () => {
  // Obtener la información de "Plataformas Electorales"
  const plataformasElectorales = dataPartidosPoliticos2.find(
    (item) => item.id === 2
  );

  return (
    <div className="margin-bottom-1">
      <h4 className="centered-text-0">
        Proceso Electoral Local Ordinario
        <br />
        2023-2024
      </h4>{" "}
      <ListBadge
        ifNumbered={""}
        listsBadgeItem={plataformasElectorales.children}
      />
    </div>
  );
};

export default ProcesOrdin;
