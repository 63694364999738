import React, { useState, useEffect } from "react";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { EnCorto } from "../../data/EnCorto/dataEnCorto";
import "bootstrap/dist/css/bootstrap.min.css"; // Importar Bootstrap CSS

const EnCortoComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para abrir o cerrar el modal
  const [selectedVideoSrc, setSelectedVideoSrc] = useState(null); // Fuente del video seleccionado

  useEffect(() => {
    document.title = `En Corto`;
  }, []);

  // Función para abrir el modal
  const openModal = (videoSrc) => {
    setSelectedVideoSrc(videoSrc); // Establece la fuente del video seleccionado
    setIsModalOpen(true); // Muestra el modal
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoSrc(null); // Resetea el video seleccionado
  };

  return (
    <>

<div className="margin-bottom-2">

      <Breadcrumbs
        path={[{ label: "Comunicación Social" }, { label: "En Corto" }]}
      />
      <TitlePages title="Comunicación Social" subTitle="En Corto" />

      {/* Galería de videos */}
      <div className="container mt-4">
        <div className="row">
          {EnCorto.map((item) => (
            <div key={item.id} className="col-md-6 mb-4">
              <div
                className="card h-100 shadow-sm position-relative"
                onClick={() => openModal(`https://itetlax.org.mx/assets/videos/EnCorto/${item.id}.mp4`)}
                style={{ cursor: "pointer" }} // Cambia el cursor al pasar el mouse
              >
                <div className="ratio ratio-16x9">
                  <video muted className="w-100 h-100">
                    <source
                      src={`https://itetlax.org.mx/assets/videos/EnCorto/${item.id}.mp4`}
                      type="video/mp4"
                    />
                    Tu navegador no soporta la reproducción de video.
                  </video>
                  {/* Overlay con ícono y texto */}
                  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center bg-dark bg-opacity-50">
                    <i className="bi bi-play-circle-fill text-white fs-1"></i> {/* Ícono de reproducción */}
                    <p className="text-white mt-2 mb-0">Haz clic para reproducir</p> {/* Texto descriptivo */}
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{item.titleItem}</h5>
                  <p className="card-text">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
      {/* Modal de video ampliado */}
      {isModalOpen && (
        <div className="modal fade show" style={{ display: "block" }} onClick={closeModal}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-body p-0">
                <video controls className="w-100" autoPlay>
                  <source src={selectedVideoSrc} type="video/mp4" />
                  Tu navegador no soporta la reproducción de video.
                </video>
              </div>
              <button className="btn btn-danger position-absolute top-0 end-0 m-2" onClick={closeModal}>
                <i className="bi bi-x-lg"></i> {/* Ícono de cerrar */}
              </button>
            </div>
          </div>
        </div>
        
      )}
    </>
  );
};

export default EnCortoComponent;