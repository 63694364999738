import React, { useEffect } from "react";
import NavbarEnlaces from "../../../layout/NavbarEnlaces";
import Modal from "../../../layout/Modal/Modal";
import ListBadge from "../../../layout/ListBadge";
import {
  dataDiputaciones20232024,
  dataAyuntamientos20232024,
  dataPresidenciasC20232024,
} from "../../../data/dataProcesosElectorales";

const PE2024 = () => {
  useEffect(() => {
    document.title = `Proceso Electoral 2023 - 2024`;
  }, []);
  return (
    <>
      <div className="margin-bottom-2">
        <NavbarEnlaces title="Proceso Electoral 2023 - 2024" />
        <div className="Content__grid">
          <div>
            <a
              href="/#"
              rel="noreferrer"
              data-bs-toggle="modal"
              data-bs-target="#Ayuntamientos20232024"
            >
              <img
                className="img-fluid"
                src="https://itetlax.org.mx/assets/img/icons/e21.png"
                alt="Resultados Ayuntamiento 2023 - 2024"
              />
            </a>
            <p className="mt-2">Resultados Ayuntamiento 2023 - 2024</p>
          </div>
          <div>
            <a
              href="/#"
              rel="noreferrer"
              data-bs-toggle="modal"
              data-bs-target="#Diputaciones20232024"
            >
              <img
                className="img-fluid"
                src="https://itetlax.org.mx/assets/img/icons/e21.png"
                alt="Resultados Diputaciones MR 2023 - 2024"
              />
            </a>
            <p className="mt-2">Resultados Diputaciones MR 2023 - 2024</p>
          </div>
          <div>
            <a
              href="/#"
              rel="noreferrer"
              data-bs-toggle="modal"
              data-bs-target="#PresidenciasC20232024"
            >
              <img
                className="img-fluid"
                src="https://itetlax.org.mx/assets/img/icons/e21.png"
                alt="Resultados Presidencias de Comunidad 2023 - 2024"
              />
            </a>
            <p className="mt-2">
              Resultados Presidencias de Comunidad 2023 - 2024
            </p>
          </div>
        </div>

        <Modal
          idModal="Ayuntamientos20232024"
          titleModal="Resultados Ayuntamientos 2023 -2024"
          sizeModal=""
          contentModal={
            <ListBadge
              ifNumbered={""}
              listsBadgeItem={dataAyuntamientos20232024}
            />
          }
        />
        <Modal
          idModal="Diputaciones20232024"
          titleModal="Resultados Diputaciones 2023 -2024"
          sizeModal=""
          contentModal={
            <ListBadge
              ifNumbered={""}
              listsBadgeItem={dataDiputaciones20232024}
            />
          }
        />
        <Modal
          idModal="PresidenciasC20232024"
          titleModal="Resultados Presidencias de Comunidad 2023 -2024"
          sizeModal=""
          contentModal={
            <ListBadge
              ifNumbered={""}
              listsBadgeItem={dataPresidenciasC20232024}
            />
          }
        />
      </div>
    </>
  );
};
export default PE2024;
