import React, { useEffect } from "react";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { dataITEConecta } from "../../data/dataITEConecta";

const ITEConecta = () => {
  useEffect(() => {
    document.title = `ITE Conecta`;
  }, []);

  // Ordenar los videos de mayor a menor (por id)
  const sortedVideos = [...dataITEConecta].sort((a, b) => b.id - a.id);

  return (
    <>
      <Breadcrumbs
        path={[{ label: "Comunicación Social" }, { label: "ITE Conecta" }]}
      />
      <TitlePages title="Comunicación Social" subTitle="ITE Conecta" />
      <div className="table-container margin-bottom-2">
        <table style={{ width: "70%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>No. de Programa</th>
              <th style={{ width: "20%" }}>Fecha</th>{" "}
              {/* Ajustar tamaño de columna de fecha */}
              <th style={{ width: "40%" }}>Nombre</th>{" "}
              {/* Ajustar tamaño de columna de nombre */}
              <th style={{ width: "20%" }}>Video</th>
            </tr>
          </thead>
          <tbody>
            {sortedVideos.map(({ id, title, fecha }) => (
              <tr key={id}>
                <td className="text-center">
                  <b>{id}</b>
                </td>
                <td className="text-center">
                  <b>{fecha}</b>
                </td>
                <td>{title}</td>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a
                      href={`https://itetlax.org.mx/assets/videos/ite-conecta/${id}.mp4`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`https://itetlax.org.mx/assets/img/logo_video.png`} // Cambia esto a la ruta de la imagen
                        alt={title}
                        style={{
                          cursor: "pointer",
                          width: "5em",
                          height: "auto",
                        }} // Ajusta el tamaño de la imagen
                      />
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <style jsx>{`
        .table-container {
          display: flex;
          justify-content: center;
          margin-top: 20px; /* Espacio superior para la tabla */
        }

        table {
          border: 1px solid #ddd;
        }

        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        th {
          background-color: #f2f2f2;
        }
      `}</style>
    </>
  );
};

export default ITEConecta;
