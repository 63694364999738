import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faChevronDown,
  faChevronUp,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="footer-container">
      <div className="footer-tab-container">
        <button
          className="footer-tab"
          onClick={toggleExpand}
          aria-expanded={expanded}
        >
          Más información
          <FontAwesomeIcon
            icon={expanded ? faChevronUp : faChevronDown}
            className="tab-icon"
          />
        </button>
      </div>

      <footer className="footer-content">
        {expanded && (
          <div className="additional-info">
            <div className="four-columns-grid">
              <div className="footer-column">
                <div className="tit-foot">Acerca de</div>
                <ul>
                  <li>
                    <a href="./FinesInstitucionales">Fines institucionales</a>
                  </li>
                  <li>
                    <a href="./Historia">Historia</a>
                  </li>
                  <li>
                    <a href="./PrincipiosRectores">Principios rectores</a>
                  </li>
                  <li>
                    <a href="./EstructuraOrganica">Estructura Orgánica</a>
                  </li>
                </ul>
              </div>

              <div className="footer-column">
                <div className="tit-foot">Consejo general</div>
                <ul>
                  <li>
                    <a href="./Integracion">Integración</a>
                  </li>
                  <li>
                    <a href="./Comisiones">
                      Comisiones Permanentes y Temporales
                    </a>
                  </li>
                  <li>
                    <a href="./JGE">Junta General Ejecutiva</a>
                  </li>
                </ul>
              </div>

              <div className="footer-column">
                <div className="tit-foot">Demarcaciones</div>
                <ul>
                  <li>
                    <a href="./Acuerdos">Acuerdos</a>
                  </li>
                  <li>
                    <a href="./Mapas">Mapas</a>
                  </li>
                </ul>
              </div>

              <div className="footer-column">
                <div className="tit-foot">Queremos escucharte</div>
                <ul>
                  <li>
                    <a href="https://encuesta-satisfaccion.itetlax.org.mx/">
                      Encuesta de satisfacción
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="footer-main-content">
          <div className="footer-item">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="icon-footer icon-location"
            />
            <div className="footer-text">
              <span className="footer-title">Ubicación</span> <br />
              Ex-Fábrica San Manuel <br />
              S/N, Barrio Nuevo
            </div>
          </div>

          <div className="footer-item">
            <FontAwesomeIcon
              icon={faCopyright}
              className="icon-footer icon-copyright"
            />
            <div className="footer-text">
              <span className="footer-title">Derechos reservados</span>
            </div>
          </div>

          <div className="footer-item">
            <FontAwesomeIcon
              icon={faPhone}
              className="icon-footer icon-phone"
            />
            <div className="footer-text">
              <span className="footer-title">Teléfono</span> <br />
              246 465 03 40 <br />
              Ext. 111
            </div>
          </div>
        </div>

        <div className="footer-social-media">
          <a
            href="https://es-la.facebook.com/InstitutoTlaxcaltecadeElecciones/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon facebook"
          >
            <FontAwesomeIcon icon={faFacebookF} className="icon-social" />
          </a>
          <a
            href="https://x.com/ITETLAX"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon x-twitter"
          >
            <FontAwesomeIcon icon={faXTwitter} className="icon-social" />
          </a>
          <a
            href="https://www.instagram.com/ite_tlaxcala/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon instagram"
          >
            <FontAwesomeIcon icon={faInstagram} className="icon-social" />
          </a>
          <a
            href="https://www.tiktok.com/@ite.oficial"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon tiktok"
          >
            <FontAwesomeIcon icon={faTiktok} className="icon-social" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCUBfUX_c54NfhhvZRzK0k1w?themeRefresh=1"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon youtube"
          >
            <FontAwesomeIcon icon={faYoutube} className="icon-social" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
