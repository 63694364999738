import React from "react";
import { 
  FaUserFriends,       // ITE Género
  FaUniversalAccess,    // Acciones Afirmativas
  FaUsers,             // Participación Ciudadana
  FaGlobeAmericas,     // ITE Comunidades
  FaClipboardList,     // REPS
  FaGavel              // RNPS
} from "react-icons/fa";
import "./Micrositios.css";
const Micrositios = () => {
  // Función para manejar el click en el Link de REPS
  const handleREPSClick = (e) => {
    e.preventDefault();
    window.open("/REPS", "_blank");
  };

  return (
    <>
      <h3 className="bottom_title mt-5 enlaces-custom">Micrositios</h3>
      
      <div className="micrositios-grid-container">
        {/* Fila 1 */}
        <a href="https://ite-genero.itetlax.org.mx" className="micrositio-card" target="_blank" rel="noopener noreferrer">
          <div className="card-content">
            <FaUserFriends className="card-icon-left icon-gender" />
            <div className="card-text-container">
              <div className="tit-microsit">ITE Género</div>
            </div>
          </div>
        </a>

        <a href="https://ite-consulta-pcd.itetlax.org.mx/" className="micrositio-card" target="_blank" rel="noopener noreferrer">
          <div className="card-content">
            <FaUniversalAccess className="card-icon-left icon-access" />
            <div className="card-text-container">
              <div className="tit-microsit">Acciones Afirmativas</div>
            </div>
          </div>
        </a>

        <a href="https://participacion-ciudadana.itetlax.org.mx" className="micrositio-card" target="_blank" rel="noopener noreferrer">
          <div className="card-content">
            <FaUsers className="card-icon-left icon-participation" />
            <div className="card-text-container">
              <div className="tit-microsit">Participación Ciudadana</div>
            </div>
          </div>
        </a>

        {/* Fila 2 */}
        <a href="https://ite-comunidades.itetlax.org.mx" className="micrositio-card" target="_blank" rel="noopener noreferrer">
          <div className="card-content">
            <FaGlobeAmericas className="card-icon-left icon-community" />
            <div className="card-text-container">
              <div className="tit-microsit">Consulta previa a autoridades nombradas por usos y costumbres </div>
            </div>
          </div>
        </a>

        <a href="/REPS" className="micrositio-card" onClick={handleREPSClick}>
          <div className="card-content">
            <FaClipboardList className="card-icon-left icon-reps" />
            <div className="card-text-container">
              <div className="tit-microsit">Registro Estatal personas Sancionadas</div>
            </div>
          </div>    
        </a>

        <a href="https://www.ine.mx/actores-politicos/registro-nacional-de-personas-sancionadas/" className="micrositio-card" target="_blank" rel="noopener noreferrer">
          <div className="card-content">
            <FaGavel className="card-icon-left icon-rnps" />
            <div className="card-text-container">
              <div className="tit-microsit">Registro Nacional personas Sancionadas</div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Micrositios;