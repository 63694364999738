export const dataArt632024 = [
  {
    fraccion: "I",
    titulo: "Marco Normativo Aplicable de Sujeto Obligado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F01.xlsx",
    excel2: "LTAIPT_A63F01_2.xlsx",
    managerId: null,
  },
  {
    id: 1,
    fraccion: "II",
    titulo: "Estructura Orgánica",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "II-A",
    titulo: "Estructura Orgánica",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F02A.xlsx",
    excel2: "LTAIPT_A63F02A_2.xlsx",
    excel3: "LTAIPT_A63F02A_3.xlsx",
    excel4: "LTAIPT_A63F02A_4.xlsx",
    managerId: 1,
  },
  {
    fraccion: "II-B",
    titulo: "Organigrama",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F02B.xlsx",
    excel2: "LTAIPT_A63F02B_2.xlsx",
    excel3: "LTAIPT_A63F02B_3.xlsx",
    excel4: "LTAIPT_A63F02B_4.xlsx",
    managerId: 1,
  },
  {
    fraccion: "III",
    titulo: "Facultades de las áreas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F03.xlsx",
    excel2: "LTAIPT_A63F03_2.xlsx",
    excel3: "LTAIPT_A63F03_3.xlsx",
    excel4: "LTAIPT_A63F03_4.xlsx",
    managerId: null,  
  },
  {
    fraccion: "IV",
    titulo: "Metas y objetivos de las áreas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F04.xlsx",
    excel2: "LTAIPT_A63F04_2.xlsx",
    managerId: null,
  },
  {
    fraccion: "V",
    titulo: "Los indicadores relacionados con temas de interés",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F05.xlsx",
    excel2: "LTAIPT_A63F05_2.xlsx",
    excel3: "LTAIPT_A63F05_3.xlsx",
    excel4: "LTAIPT_A63F05_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "VI",
    titulo: "Indicadores de objetivos y resultados",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F06.xlsx",
    excel2: "LTAIPT_A63F06_2.xlsx",
    excel3: "LTAIPT_A63F06_3.xlsx",
    excel4: "LTAIPT_A63F06_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "VII",
    titulo: "Directorio de servidores públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F07.xlsx",
    excel2: "LTAIPT_A63F07_2.xlsx",
    excel3: "LTAIPT_A63F07_3.xlsx",
    excel4: "LTAIPT_A63F07_4.xlsx",
    managerId: null,
  },
  {
    id: 2,
    fraccion: "VIII",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "VIII-A",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F08A.xlsx",
    excel2: "LTAIPT_A63F08A_2.xlsx",
    excel3: "LTAIPT_A63F08A_3.xlsx",
    excel4: "LTAIPT_A63F08A_4.xlsx",
    managerId: 2,
  },
  {
    fraccion: "VIII-B",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F08B.xlsx",
    excel2: "LTAIPT_A63F08B_2.xlsx",
    excel3: "LTAIPT_A63F08B_3.xlsx",
    excel4: "LTAIPT_A63F08B_4.xlsx",
    managerId: 2,
  },
  {
    fraccion: "IX",
    titulo: "Gastos por concepto de viáticos y representación",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F09.xlsx",
    excel2: "LTAIPT_A63F09_2.xlsx",
    excel3: "LTAIPT_A63F09_3.xlsx",
    excel4: "LTAIPT_A63F09_4.xlsx",
    managerId: null,
  },
  {
    id: 3,
    fraccion: "X",
    titulo: "Personal plazas y vacantes",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "X-A",
    titulo: "Plazas vacantes del personal de base y confianza",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F10A.xlsx",
    excel2: "LTAIPT_A63F10A_2.xlsx",
    excel3: "LTAIPT_A63F10A_3.xlsx",
    excel4: "LTAIPT_A63F10A_4.xlsx",
    managerId: 3,
  },
  {
    fraccion: "X-B",
    titulo:
      "Total de plazas vacantes y ocupadas del personal de base y confianza",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F10B.xlsx",
    excel2: "LTAIPT_A63F10B_2.xlsx",
    excel3: "LTAIPT_A63F10B_3.xlsx",
    excel4: "LTAIPT_A63F10B_4.xlsx",
    managerId: 3,
  },
  {
    fraccion: "XI",
    titulo: "Contrataciones de servicios profesionales por honorarios",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F11.xlsx",
    excel2: "LTAIPT_A63F11_2.xlsx",
    excel3: "LTAIPT_A63F11_3.xlsx",
    excel4: "LTAIPT_A63F11_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XII",
    titulo:
      "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F12.xlsx",
    managerId: null,
  },
  {
    fraccion: "XIII",
    titulo: "Domicilio de la Unidad de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F13.xlsx",
    excel2: "LTAIPT_A63F13_2.xlsx",
    managerId: null,
  },
  {
    fraccion: "XIV",
    titulo: "Concursos para ocupar cargos públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F14.xlsx",
    excel2: "LTAIPT_A63F14_2.xlsx",
    excel3: "LTAIPT_A63F14_3.xlsx",
    excel4: "LTAIPT_A63F14_4.xlsx",
    managerId: null,
  },
  {
    id: 4,
    fraccion: "XV",
    titulo: "Subsidios, estímulos y apoyos",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    fraccion: "XV-A",
    titulo: "Programas sociales",
    cumplimiento: "No Aplica",
    managerId: 4,
  },
  {
    fraccion: "XV-B",
    titulo: "Padrón de beneficiarios de programas sociales",
    cumplimiento: "No Aplica",
    managerId: 4,
  },
  {
    id: 5,
    fraccion: "XVI",
    titulo: "Condiciones generales de trabajo y sindicatos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XVI-A",
    titulo: "Normatividad laboral",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F16A.xlsx",
    excel2: "LTAIPT_A63F16A_2.xlsx",
    excel3: "LTAIPT_A63F16A_3.xlsx",
    excel4: "LTAIPT_A63F16A_4.xlsx",
    managerId: 5,
  },
  {
    fraccion: "XVI-B",
    titulo: "Recursos públicos entregados a sindicatos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F16B.xlsx",
    excel2: "LTAIPT_A63F16B_2.xlsx",
    excel3: "LTAIPT_A63F16B_3.xlsx",
    excel4: "LTAIPT_A63F16B_4.xlsx",
    managerId: 5,
  },
  {
    fraccion: "XVII",
    titulo: "Información curricular de los(as) servidores(as) públicas(os)",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F17.xlsx",
    excel2: "LTAIPT_A63F17_2.xlsx",
    excel3: "LTAIPT_A63F17_3.xlsx",
    excel4: "LTAIPT_A63F17_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XVIII",
    titulo: "Sanciones administrativa",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F18.xlsx",
    managerId: null,
  },
  {
    fraccion: "XIX",
    titulo: "Servicios que ofrece el sujeto obligado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F19.xlsx",
    excel2: "LTAIPT_A63F19_2.xlsx",
    managerId: null,
  },
  {
    fraccion: "XX",
    titulo: "Trámites que se realizan",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F20.xlsx",
    excel2: "LTAIPT_A63F20_2.xlsx",
    managerId: null,
  },
  {
    id: 6,
    fraccion: "XXI",
    titulo: "Presupuesto asignado",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXI-A",
    titulo: "Presupuesto asignado anual",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F21A.xlsx",
    excel2: "LTAIPT_A63F21A_2.xlsx",
    excel3: "LTAIPT_A63F21A_3.xlsx",
    excel4: "LTAIPT_A63F21A_4.xlsx",
    managerId: 6,
  },
  {
    fraccion: "XXI-B",
    titulo: "Ejercicio de los egresos presupuestarios",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F21B.xlsx",
    excel2: "LTAIPT_A63F21B_2.xlsx",
    excel3: "LTAIPT_A63F21B_3.xlsx",
    excel4: "LTAIPT_A63F21B_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXI-C",
    titulo: "Cuenta pública",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F21C.xlsx",
    excel2: "LTAIPT_A63F21C_2.xlsx",
    excel3: "LTAIPT_A63F21C_3.xlsx",
    excel4: "LTAIPT_A63F21C_4.xlsx",
    managerId: 6,
  },
  {
    fraccion: "XXII",
    titulo: "Deuda Pública",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    id: 7,
    fraccion: "XXIII",
    titulo: "Gastos de publicidad oficial",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXIII-A",
    titulo: "Programa Anual de Comunicación Social o equivalente",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F23A.xlsx",
    excel2: "LTAIPT_A63F23A_3.xlsx",
    excel3: "LTAIPT_A63F23A_4.xlsx",
    managerId: 7,
  },
  {
    fraccion: "XXIII-B",
    titulo: "Contratación de servicios de publicidad oficial",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F23B.xlsx",
    excel2: "LTAIPT_A63F23B_2.xlsx",
    excel3: "LTAIPT_A63F23B_3.xlsx",
    excel4: "LTAIPT_A63F23B_4.xlsx",
    managerId: 7,
  },
  {
    fraccion: "XXIII-C",
    titulo: "Utilización de los tiempos oficiales en radio y tv",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F23C.xlsx",
    excel2: "LTAIPT_A63F23C_2.xlsx",
    excel3: "LTAIPT_A63F23C_3.xlsx",
    excel4: "LTAIPT_A63F23C_4.xlsx",
    managerId: 7,
  },
  {
    fraccion: "XXIII-D",
    titulo:
      "Hipervínculo a información de tiempos oficiales en radio y televisión",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXIV",
    titulo: "Resultados de auditorías realizadas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F24.xlsx",
    excel2: "LTAIPT_A63F24_2.xlsx",
    excel3: "LTAIPT_A63F24_3.xlsx",
    excel4: "LTAIPT_A63F24_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXV",
    titulo: "Resultados de la dictaminación de los estados financieros",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F25.xlsx",
    excel2: "LTAIPT_A63F25_2.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXVI",
    titulo: "Personas que usan recursos públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F26.xlsx",
    excel2: "LTAIPT_A63F26_2.xlsx",
    excel3: "LTAIPT_A63F26_3.xlsx",
    excel4: "LTAIPT_A63F26_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXVII",
    titulo:
      "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F27.xlsx",
    excel2: "LTAIPT_A63F27_2.xlsx",
    excel3: "LTAIPT_A63F27_3.xlsx",
    excel4: "LTAIPT_A63F27_4.xlsx",
    managerId: null,
  },
  {
    id: 8,
    fraccion: "XXVIII",
    titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F28_2.xlsx",
    excel2: "LTAIPT_A63F28_3.xlsx",
    excel3: "LTAIPT_A63F28_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXVIII-A",
    titulo:
      "Procedimientos de licitación pública e invitación a cuando menos tres personas",
    cumplimiento: "Aplica",
    managerId: 8,
  },
  {
    fraccion: "XXVIII-B",
    titulo: "Procedimientos de adjudicación directa",
    cumplimiento: "Aplica",
    managerId: 8,
  },
  {
    fraccion: "XXIX",
    titulo: "Informes emitidos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F29_1.xlsx",
    excel2: "LTAIPT_A63F29_2.xlsx",
    excel3: "LTAIPT_A63F29_3.xlsx",
    excel4: "LTAIPT_A63F29_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXX",
    titulo: "Estadísticas generadas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F30.xlsx",
    excel: "LTAIPT_A63F30_2.xlsx",
    managerId: null,
  },
  {
    id: 9,
    fraccion: "XXXI",
    titulo: "Informe financiero",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXI-A",
    titulo: "Gasto por Capítulo, Concepto y Partida",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F31A.xlsx",
    excel2: "LTAIPT_A63F31A_2.xlsx",
    excel3: "LTAIPT_A63F31A_3.xlsx",
    excel4: "LTAIPT_A63F31A_4.xlsx",
    managerId: 9,
  },
  {
    fraccion: "XXXI-B",
    titulo: "Informes financieros contables, presupuestales y programáticos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F31B.xlsx",
    excel2: "LTAIPT_A63F31B_2.xlsx",
    excel3: "LTAIPT_A63F31B_3.xlsx",
    excel4: "LTAIPT_A63F31B_4.xlsx",
    managerId: 9,
  },
  {
    fraccion: "XXXII",
    titulo: "Padrón de proveedores y contratistas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F32.xlsx",
    excel2: "LTAIPT_A63F32_2.xlsx",
    excel3: "LTAIPT_A63F32_3.xlsx",
    excel4: "LTAIPT_A63F32_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXXIII",
    titulo:
      "Convenios de coordinación, de concertación con el sector social o privado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F33_1.xlsx",
    excel2: "LTAIPT_A63F33_2.xlsx",
    excel3: "LTAIPT_A63F33_3.xlsx",
    excel4: "LTAIPT_A63F33_4.xlsx",
    managerId: null,
  },
  {
    id: 10,
    fraccion: "XXXIV",
    titulo: "Inventario",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXIV-A",
    titulo: "Inventario de altas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34A.xlsx",
    excel2: "LTAIPT_A63F34A_2.xlsx",
    excel3: "LTAIPT_A63F34A_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-B",
    titulo: "Inventario de altas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34B.xlsx",
    excel2: "LTAIPT_A63F34B_2.xlsx",
    excel3: "LTAIPT_A63F34B_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-C",
    titulo: "Inventario de bajas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34C.xlsx",
    excel2: "LTAIPT_A63F34C_2.xlsx",
    excel3: "LTAIPT_A63F34C_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-D",
    titulo: "Inventario de bienes inmuebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34D.xlsx",
    excel2: "LTAIPT_A63F34D_2.xlsx",
    excel3: "LTAIPT_A63F34D_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-E",
    titulo: "Inventario de altas practicadas a bienes inmuebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34E.xlsx",
    excel2: "LTAIPT_A63F34E_2.xlsx",
    excel3: "LTAIPT_A63F34E_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-F",
    titulo: "Inventario de bajas practicadas a bienes inmuebles",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34F.xlsx",
    excel2: "LTAIPT_A63F34F_2.xlsx",
    excel3: "LTAIPT_A63F34F_4.xlsx",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-G",
    titulo: "Inventario de bienes muebles e inmuebles donados",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F34G.xlsx",
    excel2: "LTAIPT_A63F34G_2.xlsx",
    excel3: "LTAIPT_A63F34G_4.xlsx",
    managerId: 10,
  },
  {
    id: 11,
    fraccion: "XXXV",
    titulo: "Recomendaciones derechos humanos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXV-A",
    titulo: "Recomendaciones de organismos garantes de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35A.xlsx",
    excel2: "LTAIPT_A63F35A_2.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXV-B",
    titulo: "Casos especiales de organismos garantes de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35B.xlsx",
    excel2: "LTAIPT_A63F35B_2.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXV-C",
    titulo: "Recomendaciones de organismos internacionales de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35C.xlsx",
    excel2: "LTAIPT_A63F35C_2.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXVI",
    titulo: "Resoluciones y laudos emitidos",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    id: 12,
    fraccion: "XXXVII",
    titulo: "Participación ciudadana",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXVII-A",
    titulo: "Mecanismos de participación ciudadana",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F37A.xlsx",
    managerId: 12,
  },
  {
    fraccion: "XXXVII-B",
    titulo: "Resultado de los mecanismos de participación",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F37B.xlsx",
    managerId: 12,
  },
  {
    id: 13,
    fraccion: "XXXVIII",
    titulo: "Otros programas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXVIII-A",
    titulo: "Programas que ofrecen",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F38A.xlsx",
    excel2: "LTAIPT_A63F38A_2.xlsx",
    excel3: "LTAIPT_A63F38A_3.xlsx",
    excel4: "LTAIPT_A63F38A_4.xlsx",
    managerId: 13,
  },
  {
    fraccion: "XXXVIII-B",
    titulo: "Trámites para acceder a programas que ofrecen",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F38B.xlsx",
    excel2: "LTAIPT_A63F38B_2.xlsx",
    excel3: "LTAIPT_A63F38B_3.xlsx",
    excel4: "LTAIPT_A63F38B_4.xlsx",
    managerId: 13,
  },
  {
    id: 14,
    fraccion: "XXXIX",
    titulo: "Actas y resoluciones Comité de Transparencia",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXIX-A",
    titulo: "Informe de sesiones del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39A.xlsx",
    excel2: "LTAIPT_A63F39A_2.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-B",
    titulo: "Informe de Resoluciones del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39B.xlsx",
    excel2: "LTAIPT_A63F39B_2.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-C",
    titulo: "Integrantes del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39C.xlsx",
    excel2: "LTAIPT_A63F39C_2.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-D",
    titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39D.xlsx",
    excel2: "LTAIPT_A63F39D_2.xlsx",
    managerId: 14,
  },
  {
    id: 15,
    fraccion: "XL",
    titulo: "Evaluación y encuesta programas financiados",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XL-A",
    titulo:
      "Evaluaciones y encuestas a programas financiados con recursos públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F40A.xlsx",
    excel2: "LTAIPT_A63F40A_2.xlsx",
    managerId: 15,
  },
  {
    fraccion: "XL-B",
    titulo: "Encuestas sobre programas financiados con recursos públicos",
    cumplimiento: "Aplica",
    managerId: 15,
  },
  {
    fraccion: "XLI",
    titulo: "Estudios financiados con recursos públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F41_2.xlsx",
    excel2: "LTAIPT_A63F41_3.xlsx",
    excel3: "LTAIPT_A63F41_4.xlsx",
    managerId: null,
  },
  {
    id: 16,
    fraccion: "XLII",
    titulo: "Jubilados y pensionados",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLII-A",
    titulo: "Hipervínculo al listado de pensionados y jubilados",
    cumplimiento: "Aplica",
    managerId: 16,
  },
  {
    fraccion: "XLII-B",
    titulo: "Listado de jubilados y pensionados y el monto que reciben",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F42B.xlsx",
    excel2: "LTAIPT_A63F42B_2.xlsx",
    excel3: "LTAIPT_A63F42B_3.xlsx",
    excel4: "LTAIPT_A63F42B_4.xlsx",
    managerId: 16,
  },
  {
    id: 17,
    fraccion: "XLIII",
    titulo: "Ingresos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLIII-A",
    titulo: "Ingresos recibidos por cualquier concepto por el sujeto obligado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F43A.xlsx",
    excel2: "LTAIPT_A63F43A_2.xlsx",
    excel3: "LTAIPT_A63F43A_3.xlsx",
    excel4: "LTAIPT_A63F43A_4.xlsx",
    managerId: 17,
  },
  {
    fraccion: "XLIII-B",
    titulo: "Responsables de recibir, administrar y ejercer los ingresos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F43B.xlsx",
    excel2: "LTAIPT_A63F43B_2.xlsx",
    excel3: "LTAIPT_A63F43B_3.xlsx",
    excel4: "LTAIPT_A63F43B_4.xlsx",
    managerId: 17,
  },
  {
    id: 18,
    fraccion: "XLIV",
    titulo: "Donaciones",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    fraccion: "XLIV-A",
    titulo: "Donaciones en dinero realizadas",
    cumplimiento: "No Aplica",
    managerId: 18,
  },
  {
    fraccion: "XLIV-B",
    titulo: "Donaciones en especie realizadas",
    cumplimiento: "No Aplica",
    managerId: 18,
  },
  {
    id: 19,
    fraccion: "XLV",
    titulo: "Catálogo de disposición documental y guía simple de archivos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLV-A",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45A_1.xlsx",
    excel2: "LTAIPT_A63F45A_2.xlsx",
    excel3: "LTAIPT_A63F45A_3.xlsx",
    excel4: "LTAIPT_A63F45A_4.xlsx",
    managerId: 19,
  },
  {
    fraccion: "XLV-B",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45B_1.xlsx",
    excel2: "LTAIPT_A63F45B_2.xlsx",
    excel3: "LTAIPT_A63F45B_3.xlsx",
    excel4: "LTAIPT_A63F45B_4.xlsx",
    managerId: 19,
  },
  {
    fraccion: "XLV-C",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45C_1.xlsx",
    excel2: "LTAIPT_A63F45C_2.xlsx",
    excel3: "LTAIPT_A63F45C_3.xlsx",
    excel4: "LTAIPT_A63F45C_4.xlsx",
    managerId: 19,
  },
  {
    id: 21,
    fraccion: "XLVI",
    titulo: "Actas de sesiones",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLVI-A",
    titulo: "Actas del Consejo Consultivo",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F46A_1.xlsx",
    excel2: "LTAIPT_A63F46A_2.xlsx",
    excel3: "LTAIPT_A63F46A_3.xlsx",
    excel4: "LTAIPT_A63F46A_4.xlsx",
    managerId: 21,
  },
  {
    fraccion: "XLVI-B",
    titulo: "Opiniones y recomendaciones del Consejo Consultivo",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F46B_1.xlsx",
    excel2: "LTAIPT_A63F46B_2.xlsx",
    excel3: "LTAIPT_A63F46B_3.xlsx",
    excel4: "LTAIPT_A63F46B_4.xlsx",
    managerId: 21,
  },
  {
    id: 22,
    fraccion: "XLVII",
    titulo: "Más información relacionada",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLVII-A",
    titulo: "Información de interés público",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47A.xlsx",
    excel2: "LTAIPT_A63F47A_2.xlsx",
    managerId: 22,
  },
  {
    fraccion: "XLVII-B",
    titulo: "Preguntas frecuentes",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47B.xlsx",
    excel2: "LTAIPT_A63F47B_2.xlsx",
    managerId: 22,
  },
  {
    fraccion: "XLVII-C",
    titulo: "Transparencia proactiva",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47C.xlsx",
    excel2: "LTAIPT_A63F47C_2.xlsx",
    managerId: 22,
  },
  {
    fraccion: "C",
    titulo: "Obligaciones aplicables",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F100.xlsx",
    managerId: null,
  },
];
