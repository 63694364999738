import React, { useEffect } from "react";
import TitlePages from "../../../layout/TitlePages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import "../Transparencia.css";

const Arco = () => {
  useEffect(() => {
    document.title = `Formato de solicitud de infomación / ARCO`;
  }, []);
  return (
    <div>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Transparencia", url: "/Transparencia" },
            { label: "Formatos de solicitud de información / ARCO" },
          ]}
        />
        <TitlePages
          title="Transparencia"
          subTitle="Formato de solicitud de infomación / ARCO"
        />
        <h3 className="mt-3">Instructivo</h3>
        <ol>
          <li>
            Dar click en la liga para <strong>DESCARGAR</strong> los formatos de
            solicitud de información/derecho ARCO
          </li>
          <li>
            Capturar información en el formato correspondiente y guardar los
            cambios
          </li>
          <li>
            Enviar <strong>FORMATO(s)</strong> a la cuenta de correo:
            transparencia@itetlax.org.mx
          </li>
        </ol>
        <h3>Ligas para descargar</h3>
        <div className="ms-3 mt-2">
          <p>
            Formato de solicitud de información{" "}
            <a
              className="btn btn-primary"
              href="https://itetlax.org.mx/assets/word/transparencia/1.docx"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFileWord} />
            </a>
          </p>
          <p>
            Formato de solicitud de ejercicio de Derechos ARCO{" "}
            <a
              className="btn btn-primary"
              href="https://itetlax.org.mx/assets/word/transparencia/2.docx"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFileWord} />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Arco;
