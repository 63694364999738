import React, { useState } from "react"; // Importar useState
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faExpand,
  faChevronRight,
  faChevronLeft,
  faQuestionCircle, // Ícono de ayuda
} from "@fortawesome/free-solid-svg-icons";

// Estilos CSS para la animación
const styles = `
  .help-icon {
    transition: transform 0.3s ease;
  }
  .help-icon:hover {
    transform: translateY(-5px);
  }
`;

const SinExpandir = () => {
  const [isVisible, setIsVisible] = useState(false); // Estado para controlar la visibilidad

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Alternar visibilidad
  };

  return (
    <div style={{ marginTop: "-30px", marginBottom: "0" }}> {/* Margen inferior eliminado */}
      {/* Inyectar estilos CSS */}
      <style>{styles}</style>
      <div
        onClick={toggleVisibility}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        {/* Ícono de ayuda personalizado con tooltip y animación */}
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="help-icon"
          style={{ fontSize: "30px", color: "#860e65", marginRight: "8px" }}
          title="Ayuda de tabla registros" // Tooltip
        />
        {/* Título al lado del ícono */}
        <span style={{ color: "#860e65" }}>Ayuda en tabla de registros</span>
        {/* Salto de línea */}
        <br />
      </div>

      {/* Contenido desplegable */}
      {isVisible && (
        <div>
          <br />
          La presente tabla tiene la función de realizar busquedas, filtros y
          ordenamiento.
          <ol>
            <li>
              Es posible realizar búsquedas en los registros, presionando el
              botón{" "}
              <strong>
                <FontAwesomeIcon icon={faSearch} />
              </strong>{" "}
              y en el cuadro de búsqueda ingresar el texto. Para cerrar el cuadro
              de búsqueda, es necesario limpiar el contenido y dar click en el
              botón
              <strong>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="SearchOffIcon"
                  style={{ width: 26, height: 26 }}
                >
                  <path
                    fill="#a43c7c"
                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3 6.08 3 3.28 5.64 3.03 9h2.02C5.3 6.75 7.18 5 9.5 5 11.99 5 14 7.01 14 9.5S11.99 14 9.5 14c-.17 0-.33-.03-.5-.05v2.02c.17.02.33.03.5.03 1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5z"
                  ></path>
                  <path
                    fill="#a43c7c"
                    d="M6.47 10.82 4 13.29l-2.47-2.47-.71.71L3.29 14 .82 16.47l.71.71L4 14.71l2.47 2.47.71-.71L4.71 14l2.47-2.47z"
                  ></path>
                </svg>
              </strong>
            </li>
            <li>
              Se habilitaron filtrados de búsqueda, para poder hacer uso de
              ellos, es necesario dar click en el botón{" "}
              <strong>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="FilterListIcon"
                  style={{ width: 26, height: 26 }}
                >
                  <path
                    fill="#a43c7c"
                    d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
                  ></path>
                </svg>
              </strong>
              . Abajo del nombre de la columna aparecen los filtros disponibles,
              ya sea por texto o lista. Para ocultarlos, dar click en{" "}
              <strong>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="FilterListOffIcon"
                  style={{ width: 26, height: 26 }}
                >
                  <path
                    fill="#a43c7c"
                    d="M10.83 8H21V6H8.83l2 2zm5 5H18v-2h-4.17l2 2zM14 16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h.17L1.39 4.22 2.8 2.81l18.38 18.38-1.41 1.41L14 16.83z"
                  ></path>
                </svg>
              </strong>
            </li>
            <li>
              Para personalizar las columnas a mostrar, es necesario dar click
              en el botón{" "}
              <strong>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ViewColumnIcon"
                  style={{ width: 26, height: 26 }}
                >
                  <path
                    fill="#a43c7c"
                    d="M14.67 5v14H9.33V5h5.34zm1 14H21V5h-5.33v14zm-7.34 0V5H3v14h5.33z"
                  ></path>
                </svg>
              </strong>{" "}
              y seleccionar las columnas a ocultar.
            </li>
            <li>
              Es posible expandir la vista de la tabla, para ello basta dar click
              en el botón{" "}
              <strong>
                <FontAwesomeIcon icon={faExpand} />
              </strong>
            </li>
            <li>
              La presente tabla por default muestra diez registros, para poder
              ver un mayor número de registros, es necesario que, en la parte
              inferior de la tabla, en el apartado{" "}
              <strong>Filas por página</strong> se seleccione la cantidad de
              registros que quiera ver. Si los registros superan el número
              máximo que se encuentra en esa lista, el restro de registros se
              pueden ver mediante paginación
              <ul>
                <li>
                  Dando click en el bóton{" "}
                  <strong>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </strong>{" "}
                  para avanzar una página
                </li>
                <li>
                  En el botón{" "}
                  <strong>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </strong>{" "}
                  para retroceder una página
                </li>
                <li>
                  Click en el botón{" "}
                  <strong>
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="FirstPageIcon"
                      style={{ width: 26, height: 26 }}
                    >
                      <path
                        fill="#a43c7c"
                        d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"
                      ></path>
                    </svg>
                  </strong>{" "}
                  para ir a la primer página,
                </li>
                <li>
                  Click en el botón{" "}
                  <strong>
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="LastPageIcon"
                      style={{ width: 26, height: 26 }}
                    >
                      <path
                        fill="#a43c7c"
                        d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"
                      ></path>
                    </svg>
                  </strong>{" "}
                  para ir a la última página,
                </li>
              </ul>
            </li>
          </ol>
          <strong>Nota: </strong>En el caso de no visualizar los anexos, en la
          parte inferior de la tabla se encuentra una barra de desplazamiento,
          para poder visualizar el resto del texto
        </div>
      )}
    </div>
  );
};

export default SinExpandir;