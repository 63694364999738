import React, { useState, useEffect, useRef } from "react";
import "./Countdown.css";

const Countdown = () => {
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  const daysRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  // 🔥 CORRECTO: Fecha de finalización → 1 de junio de 2025, 9:00 AM (México Central)
  const countToDate = new Date("2025-06-01T08:00:00-06:00").getTime();

  const updateCountdown = () => {
    const currentDate = new Date().getTime();
    const timeBetweenDates = Math.floor((countToDate - currentDate) / 1000); // Segundos restantes

    if (timeBetweenDates <= 0) {
      setDays("00");
      setHours("00");
      setMinutes("00");
      setSeconds("00");
      return;
    }

    // Cálculo de días, horas, minutos y segundos
    const newDays = Math.floor(timeBetweenDates / 86400);
    const newHours = Math.floor((timeBetweenDates % 86400) / 3600);
    const newMinutes = Math.floor((timeBetweenDates % 3600) / 60);
    const newSeconds = timeBetweenDates % 60;

    // Actualizar estados
    setDays(String(newDays).padStart(2, "0"));
    setHours(String(newHours).padStart(2, "0"));
    setMinutes(String(newMinutes).padStart(2, "0"));
    setSeconds(String(newSeconds).padStart(2, "0"));

    // Animaciones
    if (String(newDays).padStart(2, "0") !== days) animateCard(daysRef.current, newDays);
    if (String(newHours).padStart(2, "0") !== hours) animateCard(hoursRef.current, newHours);
    if (String(newMinutes).padStart(2, "0") !== minutes) animateCard(minutesRef.current, newMinutes);
    animateCard(secondsRef.current, newSeconds);
  };

  const animateCard = (element, newValue) => {
    if (element) {
      element.classList.add("flip");
      setTimeout(() => {
        element.setAttribute("data-value", String(newValue).padStart(2, "0"));
        element.classList.remove("flip");
      }, 500);
    }
  };

  useEffect(() => {
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="countdown-container">
      <div className="countdown-content">
        <div className="countdown-title">LA JORNADA ELECTORAL INICIA EN:</div>
        <div className="countdown-horizontal">
          {[
            { ref: daysRef, value: days, label: "DÍAS" },
            { ref: hoursRef, value: hours, label: "HORAS" },
            { ref: minutesRef, value: minutes, label: "MINUTOS" },
            { ref: secondsRef, value: seconds, label: "SEGUNDOS" },
          ].map((item, index) => (
            <div className="countdown-item" key={index}>
              <div className="countdown-card">
                <div className="countdown-number" ref={item.ref} data-value={item.value}>
                  {item.value}
                </div>
              </div>
              <h1 className="tit-clock">{item.label}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Countdown;
