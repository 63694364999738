import React from "react";
import TitlePages from "../../../../layout/TitlePages";
import Breadcrumbs from "../../../../layout/Breadcrumbs";
import { Link } from "react-router-dom";

const ImageLink = ({ to, src, alt, text }) => (
  <div className="folder-item">
    <Link to={to}>
      <img src={src} className="img-fluid hover-effect" alt={alt} />
    </Link>
    <p className="text-strong">{text}</p>
  </div>
);

const PlanesdeTrabajo = () => {
  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Transparencia", url: "/Transparencia" },
          { label: "Convenios" },
        ]}
      />

      <TitlePages title="Transparencia" subTitle="Convenios" />

      <div className="w-100 Grid__folder margin-bottom-2">
        <ImageLink
          to="/Convenios2014"
          src="https://itetlax.org.mx/assets/img/icons/27.png"
          alt="Plan de Trabajo 2014"
        />
        <ImageLink
          to="/Convenios2015"
          src="https://itetlax.org.mx/assets/img/icons/28.png"
          alt="Plan de Trabajo 2015"
        />
        <ImageLink
          to="/Convenios2016"
          src="https://itetlax.org.mx/assets/img/icons/29.png"
          alt="Plan de Trabajo 2016"
        />
        <ImageLink
          to="/Convenios2017"
          src="https://itetlax.org.mx/assets/img/icons/30.png"
          alt="Plan de Trabajo 2017"
        />
        <ImageLink
          to="/Convenios2018"
          src="https://itetlax.org.mx/assets/img/icons/31.png"
          alt="Plan de Trabajo 2018"
        />
        <ImageLink
          to="/Convenios2019"
          src="https://itetlax.org.mx/assets/img/icons/32.png"
          alt="Plan de Trabajo 2019"
        />
        <ImageLink
          to="/Convenios2020"
          src="https://itetlax.org.mx/assets/img/icons/33.png"
          alt="Plan de Trabajo 2020"
        />
        <ImageLink
          to="/Convenios2021"
          src="https://itetlax.org.mx/assets/img/icons/34.png"
          alt="Plan de Trabajo 2021"
        />
        <ImageLink
          to="/Convenios2022"
          src="https://itetlax.org.mx/assets/img/icons/35.png"
          alt="Plan de Trabajo 2022"
        />
        <ImageLink
          to="/Convenios2023"
          src="https://itetlax.org.mx/assets/img/icons/36.png"
          alt="Plan de Trabajo 2023"
        />
        <ImageLink
          to="/Convenios2024"
          src="https://itetlax.org.mx/assets/img/icons/2024.png"
          alt="Plan de Trabajo 2024"
        />

        <ImageLink
          to="/Convenios2025"
          src="https://itetlax.org.mx/assets/img/icons/2025.png"
          alt="Plan de Trabajo 2025"
        />
      </div>
    </>
  );
};

export default PlanesdeTrabajo;
