import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import TitlePages from "../../layout/TitlePages";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { useCompleteTableConfig } from "../../constants";
import { dataLicitaciones } from "../../data/Licitaciones/dataLicitaciones";

const baseUrl = "https://itetlax.org.mx/assets/pdf/licitaciones/";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}.pdf`;
  const fileName = url.split("/").pop();

  return (
    <a className="link" href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
      {` ${fileName}`}
    </a>
  );
};

const Licitaciones = () => {
  useEffect(() => {
    document.title = `Licitaciones`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "num",
        header: "No. Licitación",
        footer: "No. Licitación",
        size: 80,
      },
      {
        accessorKey: "nombreLicitacion",
        header: "Nombre Licitación",
        footer: "Nombre Licitación",
        size: 500,
      },
    ],
    []
  );

  const PdfLink = ({ num, nombreLicitacion }) => {
    const url = `https://itetlax.org.mx/assets/pdf/licitaciones/${num}.pdf`;

    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="d-flex align-items-center"
        style={{
          textDecoration: "none",
          color: "black",
          padding: "8px 12px",
          display: "flex",
          gap: "8px",
          width: "100%",
        }}
      >
        <FontAwesomeIcon
          icon={faFilePdf}
          className="text-danger"
          style={{ fontSize: "1.3rem" }}
        />
        <span style={{ whiteSpace: "nowrap" }}>{nombreLicitacion}</span>{" "}
        {/* Evita saltos de línea */}
      </a>
    );
  };

  const renderArchivos = ({ row }) => {
    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered align-middle w-100">
            <tbody>
              <tr>
                <td className="p-0">
                  <PdfLink
                    num={row.original.num}
                    nombreLicitacion={row.original.nombreLicitacion}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const table = useCompleteTableConfig(
    dataLicitaciones,
    columns,
    renderArchivos
  );

  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs path={[{ label: "Licitaciones" }]} />
        <TitlePages title="Licitaciones" subTitle="Licitaciones" />
        <MaterialReactTable table={table} />
      </div>
    </>
  );
};

export default Licitaciones;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};
