import React from "react";
import TitlePages from "../../../../layout/TitlePages";
import Breadcrumbs from "../../../../layout/Breadcrumbs";
import { convenios2019 } from "../../../../data/Convenios/dataConvenios"; // Importa solo los convenios de 2019

const Convenios2019 = () => {
  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Transparencia", url: "/Convenios" },
            { label: "Convenios", url: "/Convenios" },
            { label: "2019" },
          ]}
        />

        <TitlePages title="Transparencia" subTitle="Convenios 2019" />

        <div className="w-100 mt-3 Content__grid">
          {convenios2019.map((plan) => (
            <div key={plan.id} className="margin-bottom-1">
              <a href={plan.url} target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid hover-effect"
                  src={plan.image}
                  alt={"Convenios " + plan.title}
                />
              </a>
              <p className="mt-2">{plan.title.toUpperCase()}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Convenios2019;