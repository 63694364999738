export const dataPlanes = [
    {
      id: 1,
      title: "Plan de Trabajo Electoral.",
      url: "https://itetlax.org.mx/assets/pdf/PlanesElectorales/2024/1.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2024, 
    },
    {
      id: 2,
      title: "Plan de Trabajo Electoral Extraordinario.",
      url: "https://itetlax.org.mx/assets/pdf/PlanesElectorales/2024/2.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2024, 
    },
    {
      id: 3,
      title: "Plan de Trabajo conjunto para la promoción de la Participación Ciudadana.",
      url: "https://itetlax.org.mx/assets/pdf/PlanesElectorales/2025/1.pdf",
      image: "https://itetlax.org.mx/assets/img/icons/2.png",
      year: 2025, 
    },
  ];