export const dataBoletines = {
  2015: [
    {
      id: 1,
      monthBoletin: "OCT",
      nameBoletin: "APRUEBA ITE REGLAMENTO DE SESIONES",
    },
    {
      id: 2,
      monthBoletin: "NOV",
      nameBoletin: "MEDIANTE ACUERDO AVALA ITE ADECUACIÓN PRESUPUESTAL",
    },
    {
      id: 3,
      monthBoletin: "DIC",
      nameBoletin:
        "CONCLUYE ITE APROBACIÓN DE REGLAMENTOS INTERNOS Y CAPACITA A PERSONAL EN DERECHO ELECTORAL",
    },
    {
      id: 4,
      monthBoletin: "DIC",
      nameBoletin: "INICIA PROCESO ELECTORAL LOCAL 2015-2016",
    },
    {
      id: 5,
      monthBoletin: "DIC",
      nameBoletin: "DEFINE ITE TOPES DE PRECAMPAÑA",
    },
    {
      id: 6,
      monthBoletin: "DIC",
      nameBoletin: "NECESARIO PRESUPUESTO DE 254 MDP PARA ELECCIONES 2016",
    },
    {
      id: 7,
      monthBoletin: "DIC",
      nameBoletin:
        "LISTOS ACUERDOS DEL ITE QUE REGIRÁN CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 8,
      monthBoletin: "DIC",
      nameBoletin:
        "FIRMA ITE ACUERDO DE COLABORACIÓN CON EL INE Y APRUEBA MULTAS PARA PAN Y PT",
    },
    {
      id: 9,
      monthBoletin: "DIC",
      nameBoletin: "HABRÁ ENTRE 5 Y 7 REGIDORES EN AYUNTAMIENTOS ITE",
    },
    {
      id: 10,
      monthBoletin: "DIC",
      nameBoletin: "AJUSTA ITE CONVOCATORIA DE CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 11,
      monthBoletin: "DIC",
      nameBoletin:
        "APRUEBA ITE LINEAMIENTOS PARA NOTICIARIOS Y CRITERIOS PARA EL USO DE MATERIAL RECICLABLE PARA PROPAGANDA",
    },
  ],

  2016: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "ELIGEN-COMUNIDADES-DE-NATIVITAS-Y-SANTA-APOLONIA-TEACALCO-A-PRESIDENTES-POR-SISTEMA-DE-USOS-Y-COSTUMBRES",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "FIRMARÁN-CONVENIO-ITE-Y-TRIBUNAL-ELECTORAL-DE-JALISCO",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "ABRIRÁN-ITE-Y-TRIEJAL-DOCTORADO-EN-DERECHO-ELECTORAL",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-COMUNIDADES-DE-NATIVITAS-TERRENATE-Y-CHIAUTEMPAN",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA-ITE-PRESUPUESTO-DE-EGRESOS-EN-ESTRICTO-APEGO-A-DECRETO",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin: "APRUEBA-ITE-INTEGRACIÓN-DEL-COMITÉ-TÉCNICO-DE-ARCHIVOS",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "PROCESO-ELECTORAL-2015-2016-DIO-MUESTRA-DE-FORTALECIMIENTO-DEMOCRÁTICO-ITE",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin: "ACATA-ITE-SENTENCIA-DEL-TET-Y-REASIGNA-PRESUPUESTO-2017",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin: "REUTILIZARÁ-ITE-MATERIALES-ELECTORALES",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "ABRE-ITE-BODEGAS-PARA-EXTRACCIÓN-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
    },
    {
      id: 11,
      monthBoletin: "MRZ",
      nameBoletin:
        "AL-50-PREPARACIÓN-DE-DOCUMENTACIÓN-ELECTORAL-QUE-SERÁ-DESTRUÍDA",
    },
    {
      id: 12,
      monthBoletin: "MRZ",
      nameBoletin: "EL-ITE-CUENTA-CON-RECURSOS-PARA-ELECCIONES-EXTRAORDINARIAS",
    },
    {
      id: 13,
      monthBoletin: "MRZ",
      nameBoletin:
        "APRUEBA-ITE-CALENDARIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO",
    },
    {
      id: 14,
      monthBoletin: "MRZ",
      nameBoletin:
        "DECLARA-ITE-INICIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO-2017",
    },
    {
      id: 15,
      monthBoletin: "ABRIL",
      nameBoletin:
        "REALIZÓ-ITE-CONVENCIÓN-ESTATAL-DEL-8°.-PARLAMENTO-INFANTIL-SE-ELIGIERON-A-19-LEGISLADORES",
    },
    {
      id: 16,
      monthBoletin: "JUN",
      nameBoletin: "EXHORTA-ITE-A-TLAXCALTECAS-A-REFLEXIONAR-SU-VOTO",
    },
    {
      id: 17,
      monthBoletin: "JUN",
      nameBoletin: "CUBRE-ITE-SALARIOS-APEGADO-A-LEY",
    },
    {
      id: 18,
      monthBoletin: "JUN",
      nameBoletin: "CELEBRA-ITE-CONFERENCIA-Y-FORO-PARA-PROMOVER-EL-VOTO",
    },
    {
      id: 19,
      monthBoletin: "JUN",
      nameBoletin:
        "SESIONAN-CONSEJEROS-DISTRITALES-Y-MUNICIPALES-PARA-CÓMPUTOS-DE-ELECCIÓN",
    },
    {
      id: 20,
      monthBoletin: "JUN",
      nameBoletin: "INICIA-ITE-CÓMPUTO-FINAL-DE-LA-JORNADA-ELECTORAL",
    },
    {
      id: 21,
      monthBoletin: "JUN",
      nameBoletin:
        "EXHORTA-ITE-A-NO-USAR-CELULARES-PARA-CAPTAR-IMÁGENES-AL-INTERIOR-DE-CASILLAS",
    },
    {
      id: 22,
      monthBoletin: "JUN",
      nameBoletin:
        "LAMENTA-CONSEJO-GENERAL-DEL-ITE-RETENCIÓN-ILEGAL-DE-COLABORADORES",
    },
    {
      id: 23,
      monthBoletin: "JUN",
      nameBoletin:
        "CONCLUYEN-CÓMPUTOS-CONSEJOS-DISTRITALES-Y-MUNICIPALES-ENTREGAN-CONSTANCIAS-DE-JUNRÍA",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin:
        "CONCLUYE-ITE-CÓMPUTO-PARA-GOBERNADOR-Y-ENTREGA-CONSTANCIA-DE-JUNRÍA",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin:
        "RECONOCE-ITE-COMPROMISO-Y-PROFESIONALISMO-DE-CONSEJEROS-DISTRITALES-Y-MUNICIPALES",
    },
    {
      id: 26,
      monthBoletin: "JUN",
      nameBoletin: "CELEBRARÁ-ITE-EJERCICIO-DE-EVALUACICIÓN",
    },
    {
      id: 27,
      monthBoletin: "JUN",
      nameBoletin:
        "DISCUTEN-CONSEJEROS-DISTRITALES-Y-MUNICIPALES-SOBRE-FORTALEZAS-Y-DEBILIDADES-DEL-PROCESO-ELECTORAL",
    },
    {
      id: 28,
      monthBoletin: "JUL",
      nameBoletin: "1-de-julio-de-2016-1",
    },
    {
      id: 29,
      monthBoletin: "JUL",
      nameBoletin: "26-DE-JUL-2016",
    },
    {
      id: 30,
      monthBoletin: "JUL",
      nameBoletin: "31-DE-JUL-2016",
    },
    {
      id: 31,
      monthBoletin: "JUL",
      nameBoletin: "22-DE-JUL-2016",
    },
    {
      id: 32,
      monthBoletin: "AGO",
      nameBoletin: "03-DE-AGOSTO-2016",
    },
    {
      id: 33,
      monthBoletin: "AGO",
      nameBoletin:
        "04-DE-AGOSTO-2016-ACATA-ITE-INSTRUCCIÓN-DE-INE-EN-RETENCIÓN-DE-PRERROGATIVAS-POR-MULTAS",
    },
    {
      id: 34,
      monthBoletin: "AGO",
      nameBoletin: "05-DE-AGOSTO-2016",
    },
    {
      id: 35,
      monthBoletin: "AGO",
      nameBoletin: "08-DE-AGOSTO-2016",
    },
    {
      id: 36,
      monthBoletin: "AGO",
      nameBoletin: "09-DE-AGOSTO-2016",
    },
    {
      id: 37,
      monthBoletin: "AGO",
      nameBoletin:
        "17-DE-AGOSTO-2016-INTERVIENE-ITE-ANTE-INE-PARA-APOYAR-A-PARTIDOS-EN-MATERIA-DE-MULTAS",
    },
    {
      id: 38,
      monthBoletin: "AGO",
      nameBoletin:
        "18-DE-AGOSTO-2016-CONFIRMA-SALA-REGIONAL-TRABAJO-DE-ITE-EN-VALIDACIÓN-DE-ELECCIONES",
    },
    {
      id: 39,
      monthBoletin: "AGO",
      nameBoletin:
        "30-DE-AGOSTO-2016-ADECUA-ITE-PRESUPUESTO-DE-EGRESOS-PARA-HACER-FRENTE-A-ELECCIONES-EXTRAORDINARIAS",
    },
    {
      id: 40,
      monthBoletin: "SEP",
      nameBoletin:
        "05-DE-SEPTIEMBRE-2016-PREPARA-INSTITUTO-TLAXCALTECA-DE-ELECCIONES-PLAN-DE-CULTURA-CÍVICA",
    },
    {
      id: 41,
      monthBoletin: "SEP",
      nameBoletin:
        "06-DE-SEPTIEMBRE-2016-PARTICIPA-ITE-EN-EVALUACIÓN-DE-PROCESOS-ELECTORALES-LOCALES-CON-EL-INE",
    },
    {
      id: 42,
      monthBoletin: "SEP",
      nameBoletin:
        "13-DE-SEPTIEMBRE-2016-EFECTÚA-ITE-RECUENTO-DE-13-CASILLAS-DE-ELECCIÓN-EN-TZOMPANTEPEC-",
    },
    {
      id: 43,
      monthBoletin: "SEP",
      nameBoletin:
        "14-DE-SEPTIEMBRE-2016-DESAHOGAN-ITE-Y-TET-RECUENTO-DE-CASILLA-EN-ELECCIÓN-DE-AYUNTAMIENTO-DE-NANACAMILPA-DE-MARIANO-ARISTA",
    },
    {
      id: 44,
      monthBoletin: "SEP",
      nameBoletin:
        "17-DE-SEPTIEMBRE-2016-RECUENTA-ITE-VOTOS-DE-86-PAQUETES-DE-ELECCIÓN-DISTRITAL-DE-TEOLOCHOLCO",
    },
    {
      id: 45,
      monthBoletin: "SEP",
      nameBoletin:
        "100-18-DE-SEPTIEMBRE-2016-ACUERDAN-ITE-E-INE-ORGANIZAR-EN-CONJUNTO-ELECCIONES-EXTRAORDINARIAS",
    },
    {
      id: 46,
      monthBoletin: "SEP",
      nameBoletin:
        "101-25-DE-SEPTIEMBRE-2016-APOYA-ITE-A-INSTITUCIONES-EDUCATIVAS-EN-ELECCIONES-DE-SOCIEDAD-DE-ALUMNOS-1",
    },
    {
      id: 47,
      monthBoletin: "SEP",
      nameBoletin:
        "102-29-DE-SEPTIEMBRE-2016-APRUEBA-ITE-MECANISMO-PARA-ADOPTAR-PROTOCOLO-DE-ATENCIÓN-A-VIOLENCIA-POLÍTICA-CONTRA-LAS-MUJERES-1",
    },
    {
      id: 48,
      monthBoletin: "SEP",
      nameBoletin:
        "103-30-DE-SEPTIEMBRE-2016-APRUEBA-ITE-ANTEPROYECTO-DE-PRESUPUESTO-2017-RESPONSABLE-Y-RACIONAL-1",
    },
    {
      id: 49,
      monthBoletin: "OCT",
      nameBoletin:
        "104-01-DE-OCTUBRE-2016-ACUERDAN-ITE-Y-GOBIERNO-DEL-ESTADO-PAGO-DE-LAUDOS",
    },
    {
      id: 50,
      monthBoletin: "OCT",
      nameBoletin:
        "105-04-DE-OCTUBRE-2016-TLAXCALA-PIONERA-EN-MEDIDAS-DE-ATENCIÓN-A-VIOLENCIA-POLÍTICA-CONTRA-MUJERES",
    },
    {
      id: 51,
      monthBoletin: "OCT",
      nameBoletin:
        "106-14-DE-OCTUBRE-2016-APRUEBA-ITE-RETENCIÓN-DE-SANCIONES-A-SEIS-PARTIDOS-POR-GASTOS-DE-CAMPAÑA",
    },
    {
      id: 52,
      monthBoletin: "OCT",
      nameBoletin:
        "107-26-DE-OCTUBRE-2016-APRUEBA-ITE-MODIFICACIÓN-A-INTEGRACIÓN-DE-AYUNTAMIENTO-DE-QUILEHTLA",
    },
    {
      id: 53,
      monthBoletin: "OCT",
      nameBoletin:
        "108-28-DE-OCTUBRE-2016-PROMUEVE-ITE-REFLEXIÓN-EN-TORNO-A-LA-PARTICIPACIÓN-POLÍTICA-FEMENINA-EN-MÉXICO",
    },
    {
      id: 54,
      monthBoletin: "NOV",
      nameBoletin:
        "109-07-NOVIEMBRE-DE-2016-RESPALDA-ITE-ELECCIONES-POR-USOS-Y-COSTUMBRES-EN-CINCO-COMUNIDADES",
    },
    {
      id: 55,
      monthBoletin: "NOV",
      nameBoletin:
        "110-15-NOVIEMBRE-DE-2016-CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-17-COMUNIDADES-DE-TLAXCALA",
    },
    {
      id: 56,
      monthBoletin: "NOV",
      nameBoletin:
        "111-28-NOVIEMBRE-DE-2016-DETALLAN-CONSEJEROS-ELECTORALES-PROPUESTA-DE-PRESPUESTO-2017-A-EJECUTIVO-Y-DIPUTADOS",
    },
    {
      id: 57,
      monthBoletin: "NOV",
      nameBoletin:
        "112-30-NOVIEMBRE-DE-2016-APRUEBA-ITE-DESTINO-DE-MULTAS-A-ÓRGANO-DE-CIENCIA-Y-TECNOLOGÍA-LOCAL",
    },
  ],

  2017: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "ELIGEN-COMUNIDADES-DE-NATIVITAS-Y-SANTA-APOLONIA-TEACALCO-A-PRESIDENTES-POR-SISTEMA-DE-USOS-Y-COSTUMBRES",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "FIRMARÁN-CONVENIO-ITE-Y-TRIBUNAL-ELECTORAL-DE-JALISCO",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "ABRIRÁN-ITE-Y-TRIEJAL-DOCTORADO-EN-DERECHO-ELECTORAL",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "CELEBRAN-ELECCIONES-POR-USOS-Y-COSTUMBRES-COMUNIDADES-DE-NATIVITAS-TERRENATE-Y-CHIAUTEMPAN",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA-ITE-PRESUPUESTO-DE-EGRESOS-EN-ESTRICTO-APEGO-A-DECRETO",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin: "APRUEBA-ITE-INTEGRACIÓN-DEL-COMITÉ-TÉCNICO-DE-ARCHIVOS",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "PROCESO-ELECTORAL-2015-2016-DIO-MUESTRA-DE-FORTALECIMIENTO-DEMOCRÁTICO-ITE",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin: "ACATA-ITE-SENTENCIA-DEL-TET-Y-REASIGNA-PRESUPUESTO-2017",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin: "REUTILIZARÁ-ITE-MATERIALES-ELECTORALES",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "ABRE-ITE-BODEGAS-PARA-EXTRACCIÓN-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin:
        "11-AL-50-PREPARACIÓN-DE-DOCUMENTACIÓN-ELECTORAL-QUE-SERÁ-DESTRUÍDA",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin:
        "12-EL-ITE-CUENTA-CON-RECURSOS-PARA-ELECCIONES-EXTRAORDINARIAS",
    },
    {
      id: 13,
      monthBoletin: "MAR",
      nameBoletin:
        "13-APRUEBA-ITE-CALENDARIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO",
    },
    {
      id: 14,
      monthBoletin: "MAR",
      nameBoletin:
        "14-DECLARA-ITE-INICIO-DEL-PROCESO-ELECTORAL-EXTRAORDINARIO-2017",
    },
    {
      id: 15,
      monthBoletin: "MAR",
      nameBoletin:
        "15-PRESENTA-ITE-OBRA-DE-TEATRO-SOBRE-ACOSO-Y-VIOLENCIA-LABORAL-CONTRA-LA-MUJER",
    },
    {
      id: 16,
      monthBoletin: "MAR",
      nameBoletin:
        "16-DETERMINA-ITE-NO-ADMITIR-A-TRÁMITE-ESCRITOS-DE-NOTIFICACIÓN-PARA-FORMAR-NUEVOS-PARTIDOS-POLÍTICOS",
    },
    {
      id: 17,
      monthBoletin: "MAR",
      nameBoletin:
        "17-APRUEBA-ITE-CRITERIOS-DE-PARIDAD-DE-GÉNERO-PARA-ELECCIÓN-EXTRAORDINARIA",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin:
        "18-INSUFICIENTES-CONDICIONES-PARA-QUE-MUJERES-ACCEDAN-A-CARGOS-DE-ELECCIÓN-PIEDRAS",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin:
        "19-EXHORTA-ITE-A-MIEMBROS-DE-PARLAMENTO-INFANTIL-A-PROMOVER-PARTICIPACIÓN-Y-DIÁLOGO-EN-ESCUELAS-Y-HOGARES",
    },
    {
      id: 20,
      monthBoletin: "ABR",
      nameBoletin: "20-GARANTIZADO-PROCESO-ELECTORAL-EXTRAORDINARIO-ITE",
    },
    {
      id: 21,
      monthBoletin: "ABR",
      nameBoletin:
        "21-APRUEBA-ITE-DISEÑO-Y-MODELOS-DE-DOCUMENTACIÓN-Y-MATERIAL-ELECTORAL",
    },
    {
      id: 22,
      monthBoletin: "ABR",
      nameBoletin:
        "22-DESCARTAN-PARTIDOS-IR-EN-CANDIDATURA-COMÚN-O-COALICIÓN-A-PROCESO-ELECTORAL-EXTRAORDINARIO",
    },
    {
      id: 23,
      monthBoletin: "ABR",
      nameBoletin:
        "23-ASUME-ITE-FUNCIONES-DE-CONSEJOS-MUNICIPALES-PARA-PROCESO-EXTRAORDINARIO",
    },
    {
      id: 24,
      monthBoletin: "ABR",
      nameBoletin:
        "24-PRESENTAN-ITE-Y-TEPJF-SEGUNDA-MESA-DE-DIÁLOGO-SOBRE-SISTEMA-NORMATIVO-INDÍGENA-Y-DE-PARTIDOS",
    },
    {
      id: 25,
      monthBoletin: "ABR",
      nameBoletin: "25-APRUEBA-ITE-TOPE-DE-GASTOS-DE-CAMPAÑA",
    },
    {
      id: 26,
      monthBoletin: "ABR",
      nameBoletin: "COMUNICADO-01",
    },
    {
      id: 27,
      monthBoletin: "MAY",
      nameBoletin: "26-IMPRIMIRÁ-“FORMAS-INTELIGENTES”-DOCUMENTACIÓN-ELECTORAL",
    },
    {
      id: 28,
      monthBoletin: "MAY",
      nameBoletin: "27-APRUEBA-ITE-MODELO-DE-RECEPCIÓN-DE-PAQUETES-ELECTORALES",
    },
    {
      id: 29,
      monthBoletin: "MAY",
      nameBoletin:
        "28-REQUIERE-ITE-A-PAN-PAC-Y-PT-CUMPLIR-CON-PARIDAD-DE-GÉNERO-EN-REGISTRO-DE-CANDIDATOS",
    },
    {
      id: 30,
      monthBoletin: "MAY",
      nameBoletin: "29-APRUEBA-ITE-MEDIDAS-DE-SEGURIDAD-DE-BOLETAS-ELECTORALES",
    },
    {
      id: 31,
      monthBoletin: "MAY",
      nameBoletin:
        "30-IMPULSAN-ITE-TET-E-IEM-OBSERVATORIO-DE-PARTICIPACIÓN-POLÍTICA-DE-LAS-MUJERES",
    },
    {
      id: 32,
      monthBoletin: "MAY",
      nameBoletin: "31-EFECTÚA-ITE-SIMULACROS-SOBRE-EL-PREP-Y-CÓMPUTOS",
    },
    {
      id: 33,
      monthBoletin: "MAY",
      nameBoletin:
        "32-EN-CUMPLIMIENTO-A-SENTENCIA-DE-TEPJ-MODIFICA-ITE-REGISTRO-DEL-PAC-EN-TEXOPA-XALTOCAN",
    },
    {
      id: 34,
      monthBoletin: "JUN",
      nameBoletin: "34-RECIBE-ITE-BOLETAS-DE-TEXOPA",
    },
    {
      id: 35,
      monthBoletin: "JUN",
      nameBoletin: "35-TODO-LISTO-PARA-LA-JORNADA-ELECTORAL-DE-ESTE-DOMINGO",
    },
    {
      id: 36,
      monthBoletin: "JUN",
      nameBoletin: "36-ITE-LLAMA-ITE-A-VOTAR-DE-MANERA-PACÍFICA-Y-ORDENADA",
    },
    {
      id: 37,
      monthBoletin: "JUN",
      nameBoletin:
        "37-ACUDEN-INTEGRANTES-DEL-CONSEJO-GENERAL-A-COLONIA-SANTA-MARTHA-XALOZTOC-A-VERIFICAR-AUTENTICIDAD-DE-BOLETAS-Y-LÍQUIDO-INDELEBLE",
    },
    {
      id: 38,
      monthBoletin: "JUN",
      nameBoletin: "38-CIERRAN-CASILLAS-SIN-INCIDENTES",
    },
    {
      id: 39,
      monthBoletin: "JUN",
      nameBoletin:
        "39-PARTICIPACIÓN-DE-66-EN-LAS-URNAS-ES-MUESTRA-DE-CONFIANZA-EN-ITE",
    },
    {
      id: 40,
      monthBoletin: "JUN",
      nameBoletin:
        "40-A-ESCRUTINIO-Y-CÓMPUTO-CASILLAS-DE-TEXOPA-Y-DE-BARRIO-DE-SANTIAGO",
    },
    {
      id: 41,
      monthBoletin: "JUN",
      nameBoletin:
        "41-DECLARA-ITE-VALIDEZ-DE-ELECCIÓN-EXTRAORDINARIA-2017-Y-ENTREGA-CONSTANCIAS",
    },
    {
      id: 42,
      monthBoletin: "JUN",
      nameBoletin: "42-APRUEBA-ITE-ACUERDO-PARA-RETIRAR-PROPAGANDA",
    },
    {
      id: 43,
      monthBoletin: "JUN",
      nameBoletin:
        "43-FISCALIZARÁ-ITE-A-AGRUPACIONES-QUE-PRETENDAN-CONSTITUIRSE-COMO-PARTIDO-POLÍTICO-LOCAL",
    },
    {
      id: 44,
      monthBoletin: "JUN",
      nameBoletin:
        "44-ABIERTA-CONVOCATORIA-PARA-PLAZAS-DEL-SERVICIO-PROFESIONAL-ELECTORAL-NACIONAL-EN-EL-ITE",
    },
    {
      id: 45,
      monthBoletin: "JUL",
      nameBoletin:
        "45-AJUSTA-ITE-PLAZOS-PARA-QUE-ORGANIZACIÓN-DE-CIUDADANOS-SIGA-PROCESO-DE-CONSTITUCIÓN-EN-PARTIDO",
    },
    {
      id: 46,
      monthBoletin: "JUL",
      nameBoletin:
        "17072017-46-ESTÁ-ITE-A-LA-ALTURA-DE-COMICIOS-PROFESIONALES-PRESIDENTA",
    },
    {
      id: 47,
      monthBoletin: "AGO",
      nameBoletin:
        "14082017-47-REVISAN-ITE-E-INE-LINEAMIENTOS-PARA-CÓMPUTOS-DE-PRÓXIMA-ELECCIÓN",
    },
    {
      id: 48,
      monthBoletin: "AGO",
      nameBoletin:
        "18082017-48-SE-PREPARA-ITE-PARA-ASAMBLEAS-DE-ORGANIZACIÓN-QUE-BUSCA-CONSTITUIRSE-EN-PARTIDO",
    },
    {
      id: 49,
      monthBoletin: "AGO",
      nameBoletin:
        "27082017-49-EFECTUARÁ-ITE-FORO-DE-DEFENSA-DE-DERECHOS-POLÍTICO-–ELECTORALES-DE-INDÍGENAS",
    },
    {
      id: 50,
      monthBoletin: "AGO",
      nameBoletin:
        "30082017-50-FORTALECE-DEMOCRACIA-PARTICIPACIÓN-Y-REPRESENTACIÓN-POLÍTICA-INDÍGENA-PIEDRAS",
    },
    {
      id: 51,
      monthBoletin: "SEP",
      nameBoletin:
        "10092017-51-SIGNAN-PIEDRAS-Y-CÓRDOVA-CONVENIO-PARA-PRÓXIMOS-COMICIOS",
    },
    {
      id: 52,
      monthBoletin: "SEP",
      nameBoletin:
        "12092017-52-ENTREVISTARÁ-ITE-A-ASPIRANTES-A-11-PLAZAS-DEL-SPEN",
    },
    {
      id: 53,
      monthBoletin: "SEP",
      nameBoletin:
        "29092017-ITE-53-APRUEBA-ITE-ANTEPROYECTO-DE-PRESUPUESTO-2018-APEGADO-A-NECESIDADES-DE-PROCESO-ELECTORAL",
    },
    {
      id: 54,
      monthBoletin: "OCT",
      nameBoletin:
        "12102017-54-DESIGNA-ITE-A-TITULARES-DE-DIRECCIONES-Y-ÁREAS-Y-ADECUA-COMISIONES",
    },
    {
      id: 55,
      monthBoletin: "OCT",
      nameBoletin:
        "20102017-55-APRUEBA-ITE-CONVOCATORIA-PARA-PROCESO-ELECTORAL-LOCAL-ORDINARIO-2018",
    },
    {
      id: 56,
      monthBoletin: "OCT",
      nameBoletin:
        "23102017-56-EFECTÚA-ITE-SORTEO-PARA-DEFINIR-ORDEN-DE-DISTRIBUCIÓN-DE-PROMOCIONALES-DE-RADIO-Y-TELEVISIÓN-A-PARTIDOS",
    },
    {
      id: 57,
      monthBoletin: "OCT",
      nameBoletin: "30102017-57-APRUEBA-ITE-INCORPORACIÓN-DE-PERSONAL-DEL-SPEN",
    },
    {
      id: 58,
      monthBoletin: "OCT",
      nameBoletin: "30102017-58-LA-PARIDAD-DE-GÉNERO-DEBE-SER-EFECTIVA-PIEDRAS",
    },
  ],

  2019: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "omunidades",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "ipeet",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "rerrogativas y multas",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "resupuesto Egresos",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin: "ultas partidos",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin: "rograma capacitación",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin: "ultas partidos",
    },
    {
      id: 8,
      monthBoletin: "MAR",
      nameBoletin: "nauguración sala de usos",
    },
    {
      id: 9,
      monthBoletin: "MAR",
      nameBoletin: "aridad-Pta",
    },
    {
      id: 10,
      monthBoletin: "MAR",
      nameBoletin: "iclo conferencias",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin: "oro Violencia",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin: "10-cumplimiento Ixcotla TEPJF",
    },
    {
      id: 13,
      monthBoletin: "MAR",
      nameBoletin: "11-retiro de propaganda",
    },
    {
      id: 14,
      monthBoletin: "ABR",
      nameBoletin: "12-parlamento infantil_1",
    },
    {
      id: 15,
      monthBoletin: "ABR",
      nameBoletin: "13-PES",
    },
    {
      id: 16,
      monthBoletin: "ABR",
      nameBoletin: "14-LIBROS ASTUDILLO",
    },
    {
      id: 17,
      monthBoletin: "ABR",
      nameBoletin: "15-Panel Observatorio",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin: "16-Presentación cuento",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin: "17-ADECUACIÓN PRERROGATIVAS",
    },
    {
      id: 20,
      monthBoletin: "MAY",
      nameBoletin: "18-Estudio de votos",
    },
    {
      id: 21,
      monthBoletin: "MAY",
      nameBoletin: "19-convenio UPET",
    },
    {
      id: 22,
      monthBoletin: "MAY",
      nameBoletin: "20- spen-evaluación",
    },
    {
      id: 23,
      monthBoletin: "MAY",
      nameBoletin: "21- readecuación de Comisiones",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin: "21- readecuación de Comisiones",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin: "22-reformas electorales",
    },
    {
      id: 26,
      monthBoletin: "JUL",
      nameBoletin: "24-constancias parlamento",
    },
    {
      id: 27,
      monthBoletin: "JUL",
      nameBoletin: "25-destrucción boletas",
    },
    {
      id: 28,
      monthBoletin: "JUL",
      nameBoletin: "26-dictámenes Impacto Social",
    },
    {
      id: 29,
      monthBoletin: "JUL",
      nameBoletin: "27-Catálogo",
    },
    {
      id: 30,
      monthBoletin: "AGO",
      nameBoletin: "28- Incentivos",
    },
    {
      id: 31,
      monthBoletin: "AGO",
      nameBoletin: "29- oficialía electoral",
    },
    {
      id: 32,
      monthBoletin: "AGO",
      nameBoletin: "30- informe destrucción papelería",
    },
    {
      id: 33,
      monthBoletin: "SEP",
      nameBoletin: "31-panel pueblos indígenas",
    },

    {
      id: 34,
      monthBoletin: "SEP",
      nameBoletin: "32-foro transparencia",
    },
    {
      id: 35,
      monthBoletin: "SEP",
      nameBoletin: "33-actividad síndicas",
    },
    {
      id: 36,
      monthBoletin: "SEP",
      nameBoletin: "34-Presupuesto 2020",
    },
    {
      id: 37,
      monthBoletin: "OCT",
      nameBoletin: "34-pláticas mujeres-",
    },
    {
      id: 38,
      monthBoletin: "OCT",
      nameBoletin: "35-multas",
    },
    {
      id: 39,
      monthBoletin: "OCT",
      nameBoletin: "36-foro ciudadanía",
    },
    {
      id: 40,
      monthBoletin: "OCT",
      nameBoletin: "37-Presentación de libro",
    },
    {
      id: 41,
      monthBoletin: "OCT",
      nameBoletin: "38-ordinaria octubre",
    },
    {
      id: 42,
      monthBoletin: "NOV",
      nameBoletin: "39-sesión consultora",
    },
    {
      id: 43,
      monthBoletin: "NOV",
      nameBoletin: "40-Concurso de carteles",
    },
    {
      id: 44,
      monthBoletin: "NOV",
      nameBoletin: "41-reglamento comité igualdad",
    },
    {
      id: 45,
      monthBoletin: "NOV",
      nameBoletin: "42-EDU",
    },
    {
      id: 46,
      monthBoletin: "DIC",
      nameBoletin: "43-ALUMNOS FACEBOOK",
    },
    {
      id: 47,
      monthBoletin: "DIC",
      nameBoletin: "44- CURSO-TALLER DISCAPACIDAD",
    },
    {
      id: 48,
      monthBoletin: "DIC",
      nameBoletin: "45-presupuesto diputados",
    },
    {
      id: 49,
      monthBoletin: "DIC",
      nameBoletin: "46-comunidad Nanacamilpa",
    },
    {
      id: 50,
      monthBoletin: "DIC",
      nameBoletin: "47-resolución PRD",
    },
    {
      id: 51,
      monthBoletin: "DIC",
      nameBoletin: "48-Informes comisiones",
    },
  ],

  2020: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "Presupuesto.",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin: "Informe.",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin: "Sistema archivos",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "Construcción",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin: "Sesión ordinaria",
    },
    {
      id: 6,
      monthBoletin: "MAR",
      nameBoletin: "Construcción ciudadanía",
    },
    {
      id: 7,
      monthBoletin: "MAR",
      nameBoletin: "Medidas ante Covid 19",
    },
    {
      id: 8,
      monthBoletin: "ABR",
      nameBoletin: "Elimina presencial",
    },
    {
      id: 9,
      monthBoletin: "JUL",
      nameBoletin: "Fecha de inicio del PELO",
    },
    {
      id: 10,
      monthBoletin: "AGO",
      nameBoletin: "INICIA ITE RETORNO ESCALONADO A ACTIVIDADES PRESENCIALES",
    },
    {
      id: 11,
      monthBoletin: "AGO",
      nameBoletin: "INSTITUCIONES TIENEN DEUDA CON JÓVENES",
    },
    {
      id: 12,
      monthBoletin: "AGO",
      nameBoletin:
        "AJUSTA ITE PRESUPUESTO PARA ATENDER TEMAS DE SALUD DEL PERSONAL POR PANDEMIA",
    },
    {
      id: 13,
      monthBoletin: "AGO",
      nameBoletin:
        "POR AUSENCIA DE PARTES, SE DIFIERE AUDIENCIA DE DENUNCIA POR VIOLENCIA DE GÉNERO EN EL ITE",
    },
    {
      id: 14,
      monthBoletin: "AGO",
      nameBoletin: "Manual de Elecciones",
    },
    {
      id: 15,
      monthBoletin: "SEP",
      nameBoletin: "20-cotejo SPEN",
    },
    {
      id: 16,
      monthBoletin: "SEP",
      nameBoletin: "21- Instancia interna",
    },
    {
      id: 17,
      monthBoletin: "SEP",
      nameBoletin: "22 BOLETÍN 10-09-20 ITE",
    },
    {
      id: 18,
      monthBoletin: "SEP",
      nameBoletin: "23-semáforo amarillo",
    },
    {
      id: 19,
      monthBoletin: "SEP",
      nameBoletin: "24-reglamento usos y costumbres",
    },
    {
      id: 20,
      monthBoletin: "SEP",
      nameBoletin:
        "25- EDITA ITE CATÁLOGO DE PRESIDENCIAS DE COMUNIDAD QUE ELIGEN AUTORIDAD POR USOS Y COSTUMBRES",
    },
    {
      id: 21,
      monthBoletin: "SEP",
      nameBoletin: "26- Voces indigenas",
    },
    {
      id: 22,
      monthBoletin: "SEP",
      nameBoletin: "27- foro derecho a saber",
    },
    {
      id: 23,
      monthBoletin: "SEP",
      nameBoletin: "28- Presupuesto 2021",
    },
    {
      id: 24,
      monthBoletin: "SEP",
      nameBoletin: "29- Presupuesto 2021",
    },
    {
      id: 25,
      monthBoletin: "SEP",
      nameBoletin: "30-ITE",
    },
  ],

  2021: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA ITE COALICIONES “UNIDOS POR TLAXCALA” Y “JUNTOS HAREMOS HISTORIA EN TLAXCALA” PARA LA GUBERNATURA",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "APRUEBA ITE USO DE APLICACIÓN PARA CAPTAR APOYO CIUDADANO DE CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "AMPLÍA ITE PLAZO DE RECEPCIÓN DE SOLICITUDES PARA CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "ORDENA ITE MEDIDAS CAUTELARES SOBRE ANUNCIOS DE INFORME ANUAL DE DIPUTADA",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        "DECLARA ITE INFUNDADOS PROCEDIMIENTOS CONTRA BRITO Y GARRIDO",
    },
    {
      id: 6,
      monthBoletin: "ENE",
      nameBoletin: "IMPARTE ITE CURSO VIRTUAL SOBRE PARIDAD LIBRE DE VIOLENCIA",
    },
    {
      id: 7,
      monthBoletin: "ENE",
      nameBoletin:
        "ACERCA ITE A ASPIRANTES TEMAS DE VIOLENCIA POLÍTICA EN RAZÓN DE GÉNERO Y LINEAMIENTOS DE PARIDAD",
    },
    {
      id: 8,
      monthBoletin: "ENE",
      nameBoletin:
        "DARÁ ITE VISTA A PGJE PARA QUE INVESTIGUE PROBABLE COMISIÓN DE DELITO DE VIOLENCIA POLÍTICA CONTRA SÍNDICA DE TOTOLAC (1)",
    },
    {
      id: 9,
      monthBoletin: "ENE",
      nameBoletin:
        "IMPROCEDENTE SUSPENDER CAPTACIÓN DE APOYO DE LA CIUDADANÍA PARA CANDIDATURA INDEPENDIENTE ITE",
    },
    {
      id: 10,
      monthBoletin: "ENE",
      nameBoletin:
        "10- CREA ITE SISTEMA ESTATAL DE PERSONAS SANCIONADAS EN MATERIA DE VIOLENCIA POLÍTICA CONTRA MUJERES EN RAZÓN DE GÉNERO",
    },
    {
      id: 11,
      monthBoletin: "FEB",
      nameBoletin:
        "11- APRUEBA OBSERVATORIO DE PARTICIPACIÓN POLÍTICA DE LAS MUJERES EN TLAXCALA PROGRAMA DE TRABAJO",
    },
    {
      id: 12,
      monthBoletin: "FEB",
      nameBoletin:
        "12 - FIRMAN ITE Y TET CONVENIO SOBRE REGISTRO DE PERSONAS SANCIONADAS POR VIOLENCIA POLÍTICA CONTRA MUJERES",
    },
    {
      id: 13,
      monthBoletin: "FEB",
      nameBoletin:
        "13 - ORDENA ITE RETIRAR ANUNCIOS PARTIDISTAS DE EQUIPAMIENTOS URBANOS",
    },
    {
      id: 14,
      monthBoletin: "FEB",
      nameBoletin:
        "14 - AUDITARÁ TECNOLÓGICO DE MONTERREY AL PREP DE ELECCIÓN LOCAL",
    },
    {
      id: 15,
      monthBoletin: "FEB",
      nameBoletin:
        "15 - HAY CONDICIONES PARA RECABAR APOYO DE LA CIUDADANÍA QUE REQUIEREN INDEPENDIENTES ITE",
    },
    {
      id: 16,
      monthBoletin: "FEB",
      nameBoletin:
        "16 - ITE ES TARDE PARA EMITIR MEDIDAS A FAVOR DE CANDIDATURAS DE COMUNIDAD LGBTTTIQ+",
    },
    {
      id: 17,
      monthBoletin: "FEB",
      nameBoletin:
        "17 - EMITIRÁ ITE LINEAMIENTOS PARA REGULAR ACTUACIÓN DE CONTENDIENTES QUE NO SE SEPAREN DEL CARGO",
    },
    {
      id: 18,
      monthBoletin: "MAR",
      nameBoletin:
        "18 - APRUEBA ITE LINEAMIENTOS PARA QUIENES CONTIENDAN EN ESTA ELECCIÓN SIN SEPARARSE DE SU CARGO",
    },
    {
      id: 19,
      monthBoletin: "MAR",
      nameBoletin:
        "19 - ALCANZAN 59 PERSONAS PORCENTAJE DE APOYO DE LA CIUDADANÍA PARA CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 20,
      monthBoletin: "MAR",
      nameBoletin:
        "20 - INSTALA ITE LOS 15 CONSEJOS DISTRITALES PARA EL PROCESO ELECTORAL LOCAL ORDINARIO",
    },
    {
      id: 21,
      monthBoletin: "MAR",
      nameBoletin: "21 - RATIFICA ITE INSTANCIA INTERNA ENCARGADA DEL PREP",
    },
    {
      id: 22,
      monthBoletin: "MAR",
      nameBoletin:
        "22 - RESUELVE ITE PROCEDIMIENTOS ORDINARIOS Y ESPECIALES SANCIONADORES",
    },
    {
      id: 23,
      monthBoletin: "MAR",
      nameBoletin:
        "23 - IMPLEMENTA ITE MECANISMO PARA QUE CIUDADANÍA OPINE SOBRE TEMAS PARA DEBATES A LA GUBERNATURA",
    },
    {
      id: 24,
      monthBoletin: "MAR",
      nameBoletin: "24 - MUNICIPIOS, RETO DE LA PARIDAD EN TLAXCALA COELLO",
    },
    {
      id: 25,
      monthBoletin: "MAR",
      nameBoletin: "25 - APRUEBA ITE AMPLIACIÓN DE CONVOCATORIA PARA CAES Y SE",
    },
    {
      id: 26,
      monthBoletin: "ABR",
      nameBoletin:
        "26 - EXHORTA ITE A HACER CAMPAÑAS DE PROPUESTAS Y SIN ARRIESGAR SALUD DE LA POBLACIÓN",
    },
    {
      id: 27,
      monthBoletin: "ABR",
      nameBoletin:
        "27 - 18 DE ABRIL A LAS 1900 HRS., PRIMER DEBATE ENTRE CANDIDATAS Y CANDIDATO A LA GUBERNATURA",
    },
    {
      id: 28,
      monthBoletin: "ABR",
      nameBoletin:
        "28 - REPRESENTANTES DE CANDIDATURAS AVALARON A LAS Y LOS MODERADORES DE DEBATES A GUBERNATURA",
    },
    {
      id: 29,
      monthBoletin: "ABR",
      nameBoletin:
        "29 - PRESENTARÁ ITE GUÍA DE ACTUACIÓN PARA PREVENCIÓN, ATENCIÓN Y",
    },
    {
      id: 30,
      monthBoletin: "MAY",
      nameBoletin:
        "30 - RESERVA ITE APROBACIÓN DE CANDIDATURAS A AYUNTAMIENTOS Y PC",
    },
    {
      id: 31,
      monthBoletin: "MAY",
      nameBoletin:
        "31 - REGISTRA ITE APROBACIÓN DE 18 MIL 503 SOLICITUDES DE REGISTRO PARA AYUNTAMIENTOS Y PC",
    },
    {
      id: 32,
      monthBoletin: "MAY",
      nameBoletin:
        "32 - DOMINGO 16 DE MAYO, SEGUNDO DEBATE ENTRE LAS CANDIDATAS Y EL",
    },
    {
      id: 33,
      monthBoletin: "JUN",
      nameBoletin:
        "35 - IMPROCEDENTE SUSTITUCIÓN DE LILIANA BECERRIL COMO CANDIDATA",
    },
    {
      id: 34,
      monthBoletin: "JUN",
      nameBoletin:
        "36 - LLAMA ITE A CUMPLIR CON EL DERECHO AL VOTO ESTE 6 DE JUNIO",
    },
    {
      id: 35,
      monthBoletin: "JUN",
      nameBoletin: "37 - LLAMA ITE A INCONFORMES DE XICOHTZINCO A PERMITIR QUE",
    },
    {
      id: 36,
      monthBoletin: "JUN",
      nameBoletin:
        "38 - DECLARA ITE QUE PEST, PS, PIS Y PES NO ALCANZARON 3% DE VOTACIÓN",
    },
    {
      id: 37,
      monthBoletin: "JUN",
      nameBoletin: "39 - HA LIQUIDADO ITE AL 80% DEL PERSONAL EVENTUAL",
    },
    {
      id: 38,
      monthBoletin: "JUL",
      nameBoletin: "41 - DESIGNA ITE A INTERVENTORES DE PARTIDOS",
    },
    {
      id: 39,
      monthBoletin: "JUL",
      nameBoletin:
        "42 - Suman INE y OPL esfuerzos para promover la Consulta Popular",
    },
    {
      id: 40,
      monthBoletin: "JUL",
      nameBoletin: "43 - DESAHOGA ITE RECUENTO EN UNA CASILLA DE ELECCIÓN DE",
    },
    {
      id: 41,
      monthBoletin: "AGO",
      nameBoletin: "44 - CONSTANCIAS PARLAMENTO",
    },
    {
      id: 42,
      monthBoletin: "AGO",
      nameBoletin: "45 - constancias de mayoría",
    },
    {
      id: 43,
      monthBoletin: "AGO",
      nameBoletin: "46 - capacitación mujeres",
    },
    {
      id: 44,
      monthBoletin: "AGO",
      nameBoletin: "47 - medidas Dulce Silva",
    },
    {
      id: 45,
      monthBoletin: "AGO",
      nameBoletin: "48 - candidaturas indígenas",
    },
    {
      id: 46,
      monthBoletin: "AGO",
      nameBoletin: "49 - observatorio conversatorio",
    },
    {
      id: 47,
      monthBoletin: "AGO",
      nameBoletin: "50 - observatorio sesión (1)",
    },
    {
      id: 48,
      monthBoletin: "AGO",
      nameBoletin: "51 - integración legislatura",
    },
    {
      id: 49,
      monthBoletin: "SEP",
      nameBoletin: "52 - foro derecho a saber",
    },
    {
      id: 50,
      monthBoletin: "SEP",
      nameBoletin: "53 - conclusión PELO (2)",
    },
    {
      id: 51,
      monthBoletin: "SEP",
      nameBoletin: "54 - pérdida de registro",
    },
    {
      id: 52,
      monthBoletin: "SEP",
      nameBoletin: "55 - Presupuesto",
    },
    {
      id: 53,
      monthBoletin: "OCT",
      nameBoletin: "56 - elecciones extraordinarias",
    },
    {
      id: 54,
      monthBoletin: "OCT",
      nameBoletin: "57 - cancelación de acreditación",
    },
    {
      id: 55,
      monthBoletin: "OCT",
      nameBoletin: "58 - Inicio PELE 2021",
    },
    {
      id: 56,
      monthBoletin: "OCT",
      nameBoletin: "59 - foro exgobernadoras",
    },
    {
      id: 57,
      monthBoletin: "OCT",
      nameBoletin: "60 - FORO PARTICIPACIÓN (1)",
    },
    {
      id: 58,
      monthBoletin: "OCT",
      nameBoletin: "61 - firma convenio",
    },
    {
      id: 59,
      monthBoletin: "OCT",
      nameBoletin: "62 - firma convenio CEDHT",
    },
    {
      id: 60,
      monthBoletin: "OCT",
      nameBoletin: "63 - RINDEN PROTESTA EL Y LAS NUEVAS CONSEJERAS DEL ITE",
    },
    {
      id: 61,
      monthBoletin: "NOV",
      nameBoletin: "64-adecuación",
    },
    {
      id: 62,
      monthBoletin: "NOV",
      nameBoletin: "65-documentación electoral",
    },
    {
      id: 63,
      monthBoletin: "NOV",
      nameBoletin: "67- registro de candidaturas",
    },
    {
      id: 64,
      monthBoletin: "NOV",
      nameBoletin:
        "68- APRUEBA ITE REGISTRO DE 27 FÓRMULAS QUE CONTENDERÁN EN EL PELE 2021",
    },
    {
      id: 65,
      monthBoletin: "NOV",
      nameBoletin:
        "69-APRUEBA ITE MEDIDAS DE SEGURIDAD DE BOLETAS PARA PROCESO EXTRAORDINARIO",
    },
    {
      id: 66,
      monthBoletin: "NOV",
      nameBoletin: "70-documentación electoral",
    },
    {
      id: 67,
      monthBoletin: "NOV",
      nameBoletin:
        "72- EFECTÚA ITE PRIMERA VERIFICACIÓN DE MEDIDAS DE SEGURIDAD EN BOLETAS",
    },
    {
      id: 68,
      monthBoletin: "NOV",
      nameBoletin:
        "73- SOTO PENDIENTE EN TLAXCALA NULIDAD DE ELECCIÓN POR VIOLENCIA",
    },
    {
      id: 69,
      monthBoletin: "NOV",
      nameBoletin:
        "74-LLAMA ITE A CIUDADANÍA DE COMUNIDADES QUE ELIGEN PRESIDENCIA A VOTAR EN PAZ SOCIAL",
    },
    {
      id: 70,
      monthBoletin: "NOV",
      nameBoletin:
        "75-VERIFICARÁN MEDIDAS DE SEGURIDAD DE BOLETAS EN CASILLA DE SANTA CRUZ GUADALUPE",
    },
    {
      id: 71,
      monthBoletin: "NOV",
      nameBoletin: "76- MODIFICA ITE REGLAMENTO DE CONSTITUCIÓN DE PARTIDOS",
    },
    {
      id: 72,
      monthBoletin: "NOV",
      nameBoletin: "77- EFECTUARÁ ITE CÓMPUTOS; APRUEBA COTEJOS Y RECUENTOS",
    },
    {
      id: 73,
      monthBoletin: "DIC",
      nameBoletin:
        "78- DECLARA ITE VALIDEZ DE ELECCIÓN EXTRAORDINARIA; ENTREGA CONSTANCIAS DE MAYORÍA",
    },
  ],

  2022: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 01- DECLARA ITE CONCLUSIÓN DEL PROCESO ELECTORAL EXTRAORDINARIO",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 02- APRUEBA ITE RETENER 6.2 MDP A PARTIDOS POR MULTAS.",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 03 - INICIA ITE ESTE MIÉRCOLES CONCURSO DE DEBATE PARA JÓVENES.",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 04.- DECLARA ITE PROCEDENTE REGISTRO DE RSP COMO PARTIDO LOCAL.",
    },
    {
      id: 5,
      monthBoletin: "ENE",
      nameBoletin:
        " APRUEBA ITE REGISTRO DE FUERZA POR MÉXICO COMO PARTIDO POLÍTICO LOCAL.",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
    },
    {
      id: 9,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
    },
    {
      id: 10,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06.- APRUEBA ITE READECUACIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS.",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin:
        "BOLETÍN 11-APRUEBA ITE DESTRUCCIÓN DE DOCUMENTACIÓN DEL PELE 2021.",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin: "BOLETÍN 12 CREA ITE COORDINACIÓN DE GÉNERO",
    },
    {
      id: 13,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 13 DONA ITE 25 TONELADAS DE DOCUMENTACIÓN ELECTORAL A CONALITEG",
    },
    {
      id: 14,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 14 FIRMAN CONVENIO ITE Y UATx PARA DIFUNDIR INFORMACIÓN EN NÁHUATL Y OTOMÍ",
    },
    {
      id: 15,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 15 FIRMAN CONVENIO ITE Y SECRETARIA DE BIENESTAR PARA DESARROLLO DE CONSULTA A PUEBLOS INDÍGENAS",
    },
    {
      id: 16,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 16 FIRMAN CONVENIO ITE Y CEDHT PARA MATERIALIZAR CONSULTA A PUEBLOS INDÍGENAS",
    },
    {
      id: 17,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 17 FIRMAN CONVENIO ITE E INPI PARA CONSULTA DE PUEBLOS INDÍGENAS",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 18 ELIGEN A INTEGRANTES DEL DÉCIMO TERCER PARLAMENTO INFANTIL POR PRINCIPIO DE MAYORÍA",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin:
        "BOLETÍN 19. ASESORA ITE A AYUNTAMIENTOS EN EJERCICIO DE “VOZ CIUDADANA EN EL CABILDO”",
    },
    {
      id: 20,
      monthBoletin: "MAY",
      nameBoletin: "BOLETÍN 20 INICIA ITE DESARROLLO DE ASAMBLEAS",
    },
    {
      id: 21,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN 21 SOLICITA ITE A CONGRESO DESINCORPORACIÓN DE VEHÍCULOS EN DESUSO",
    },
    {
      id: 22,
      monthBoletin: "MAY",
      nameBoletin:
        "BOLETÍN 22 APRUEBA ITE PROTOCOLOS PARA LA CONSULTA CIUDADANA A PUEBLOS Y COMUNIDADES INDÍGENAS",
    },
    {
      id: 23,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 23. APRUEBA ITE MEDIDAS CAUTELARES Y DE PROTECCIÓN A REGIDORA Y SÍNDICA EN DENUNCIA POR VPMrG",
    },
    {
      id: 24,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 24. APRUEBA ITE CAMBIO DE DENOMINACIÓN A ORGANIZACIÓN QUE PRETENDE CONVERTIRSE EN PARTIDO",
    },
    {
      id: 25,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 25-MOVIMIENTO LABORISTA NO PODRÁ CONTINUAR PROCESO PARA CONSTITUIRSE EN PARTIDO",
    },
    {
      id: 26,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 26-DESINCORPORA ITE APROXIMADAMENTE 10 MIL KG DE MATERIAL ELECTORAL PARA DESTRUCCIÓN",
    },
    {
      id: 27,
      monthBoletin: "JUN",
      nameBoletin: "BOLETÍN 27 PRESENTA OIC DEL ITE CÓDIGO DE ÉTICA",
    },
    {
      id: 28,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 28. RECABA ITE POSTURA DE COMUNIDAD LGBTTTIQ PARA PROPUESTA DE REFORMA ELECTORAL",
    },
    {
      id: 29,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 29 FORMULA ITE NUEVO REQUERIMIENTO A ORGANZACIONES QUE PRETENDEN CONSTITUIRSE COMO PARTIDO",
    },
    {
      id: 30,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 30-FIRMA ITE CONVENIO CON 12 AYUNTAMIENTOS PARA FORTALECER CULTURA DEMOCRÁTICA",
    },
    {
      id: 31,
      monthBoletin: "JUN",
      nameBoletin:
        "BOLETÍN 31. CREA ITE coordinación de sistemas pluriculturales",
    },
    {
      id: 32,
      monthBoletin: "JUL",
      nameBoletin:
        "BOLETÍN 32 A DESTRUCCIÓN YO RECICLAJE 41 TONS DE DOCUMENTACIÓN Y MATERIAL ELECTORAL LOCAL",
    },
    {
      id: 33,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN 33. CREA ITE MICROSITIO SOBRE PARTICIPACIÓN DE MUJERES",
    },
    {
      id: 34,
      monthBoletin: "AGO",
      nameBoletin:
        "BOLETÍN 34-LLEVARÁ A CABO ITE SEMANA DE LA EDUCACIÓN CÍVICA",
    },
    {
      id: 35,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 35 -SATISFECHA DE SERVIR A TLAXCALA DEJA CARGO PRESIDENTA DEL ITE ELIZABETH PIEDRAS",
    },
    {
      id: 36,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 36 ELIGEN CONSEJERAS Y CONSEJEROS ELECTORALES A JUAN CARLOS MINOR MÁRQUEZ COMO PRESIDENTE PROVISIONAL DEL ITE",
    },
    {
      id: 37,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 37 - PARTICIPACIÓN CIUDADANA, FUNDAMENTAL PARA DEMOCRACIA DE CALIDAD-JCMM",
    },
    {
      id: 38,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 38 - SIGNA ITE CONVENIOS CON AYUNTAMIENTOS Y SECTOR EDUCATIVO EN MATERIA DE EDUCACIÓN CÍVICA",
    },
    {
      id: 39,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 39 AÚN HAY RETOS PARA MEJORAR CALIDAD DE CIUDADANÍA_ FAZ MORA",
    },
    {
      id: 40,
      monthBoletin: "SEP",
      nameBoletin: "BOLETÍN 40 CONCLUYE ITE SEMANA DE EDUCACIÓN CÍVICA",
    },
    {
      id: 41,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 41-ATENDIÓ ITE 360 EJERCICIOS DE ASAMBLEAS EN PROCESO DE CONSTITUCIÓN DE PARTIDOS LOCALES",
    },
    {
      id: 42,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 42-RINDE PROTESTA JUAN CARLOS MINOR COMO PRESIDENTE PROVISIONAL DEL ITE",
    },
    {
      id: 43,
      monthBoletin: "SEP",
      nameBoletin:
        "BOLETÍN 43- SOLICITARÁ ITE 52.9 MDP DE PRESUPUESTO PARA 2023; PARTIDOS TENDRÁN 63.9 MDP",
    },
    {
      id: 44,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 44 DICTARÁ SARA LOVERA CONFERENCIA EN TLAXCALA SOBRE CIUDADANÍA DE MUJERES",
    },
    {
      id: 45,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 45 CAPITALIZAR DIFERENCIAS DE MUJERES EN BENEFICIO DEL FEMINISMO, PROPONE SARA LOVERA",
    },
    {
      id: 46,
      monthBoletin: "OCT",
      nameBoletin:
        "BOLETÍN 46. DE LARGO ALIENTO ESTRATEGIA DE IMPULSO A PARTICIPACIÓN DE MUJERES",
    },
    {
      id: 47,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN 47 ENTREGA ITE PREMIO A LUIS FERNANDO ALCOLTZI GANADOR DE LA ETAPA ESTATAL EN EL CONCURSO NACIONAL DE ORATORIA 2022",
    },
    {
      id: 48,
      monthBoletin: "NOV",
      nameBoletin:
        "BOLETÍN 48 EJERCER CARGOS SIN VIOLENCIA ES UN DERECHO-GARAY",
    },
  ],

  2023: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "BOLETÍN 01- ACTUALIZA ITE MULTAS A PARTIDOS PARA 2023",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 02- Trabajo conjunto, clave en impulso a participación de juventudes, coinciden panelistsa",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "BOLETÍN 03- RINDE PROTESTA EMMANUEL ÁVILA GONZÁLEZ COMO PRESIDENTE DEL ITE",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "BOLETÍN 04- EN MAL ESTADO 70% DEL PARQUE VEHICULAR DEL ITE",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 05- REFUERZA ITE ACCIONES DE IMPULSO A PARTICIPACIÓN JUVENIL",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06- Boletín 006-ABRE ITE CONVOCATORIA PARA OBSERVACIÓN EN CONSULTA A COMUNIDADADES",
    },
  ],

  2024: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "APRUEBA ITE PROCESO OPERATIVO DEL PREP PARA EL PROCESO ELECTORAL LOCAL ",
    },
    {
      id: 2,
      monthBoletin: "ENE",
      nameBoletin:
        "RECIBE ITE EXAMEN QUE APLICARÁ A ASPIRANTES A INTEGRAR CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 3,
      monthBoletin: "ENE",
      nameBoletin:
        "DEBERÁN PARTIDOS POSTULAR A PERSONA MIGRANTE EN LISTAS PARA DIPUTACIONES LOCALES",
    },
    {
      id: 4,
      monthBoletin: "ENE",
      nameBoletin: "VOTARÁN DE FORMA ANTICIPADA PERSONAS CON IMPEDIMENTO FÍSICO PARA ACUDIR A CASILLAS",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin:
        "PRESENTA ITE REGLAMENTO DE ASISTENCIA A COMUNIDADES QUE ELIGEN AUTORIDAD POR USOS Y COSTUMBRES",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "INICIA ITE VERIFICACIÓN DE INMUEBLES PARA CONSEJOS MUNICIPALES Y DISTRITALES",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "ACCIONES AFIRMATIVAS, ANTESALA DE PROCESO MÁS INCLUYENTE DE TLAXCALA",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "ADJUDICA ITE A EMPRESAS QUE PRODUCIRÁN BOLETAS, MATERIAL ELECTORAL Y QUE OPERARÁ PREP",
    },
    {
      id: 9,
      monthBoletin: "MAR",
      nameBoletin:
        "SON ASPIRANTES A CANDIDATURAS INDEPENDIENTES 9 PERSONAS",
    },
    {
      id: 10,
      monthBoletin: "MAR",
      nameBoletin:
        "RECIBE ITE MATERIAL ELECTORAL",
    },
    {
      id: 11,
      monthBoletin: "MAR",
      nameBoletin: "RECIBE ITE 216 SOLICITUDES DE REGISTRO DE CANDIDATURAS A DIPUTACIONES LOCALES",
    },
    {
      id: 12,
      monthBoletin: "MAR",
      nameBoletin:
        "EJERCICIO DEMOCRÁTICO ASAMBLEA DE PARLAMENTO INFANTIL ITE",
    },
    {
      id: 13,
      monthBoletin: "ABR",
      nameBoletin:
        "ENTRAN EN FUNCIONES LOS 75 CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 14,
      monthBoletin: "ABR",
      nameBoletin: "APRUEBA ITE 6 MEDIDAS DE SEGURIDAD DE BOLETAS PARA ELECCIÓN DEL 2 DE JUNIO",
    },
    {
      id: 15,
      monthBoletin: "ABR",
      nameBoletin:
        "FIRMA ITE CONVENIO DE COLABORACIÓN CON LA DAM PARA PROMOVER PARTICIPACIÓN DE CONNACIONALES",
    },
    {
      id: 16,
      monthBoletin: "ABR",
      nameBoletin:
        "ENTREGA ITE MEDIDAS DE SEGURIDAD PARA BOLETAS ELECTORALES A EMPRESA",
    },
    {
      id: 17,
      monthBoletin: "ABR",
      nameBoletin:
        "FIRMA ITE CONVENIO CON SMyT PARA DIFUNDIR CAMBIOS EN DISTRITACIÓN",
    },
    {
      id: 18,
      monthBoletin: "ABR",
      nameBoletin:
        "REQUIERE ITE A PRI SUBSANAR INCONSISTENCIAS EN DOCUMENTACIÓN PARA DIPUTACIONES",
    },
    {
      id: 19,
      monthBoletin: "ABR",
      nameBoletin:
        "RESERVA ITE REGISTRO DE LISTA RP DEL PRI",
    },
    {
      id: 20,
      monthBoletin: "ABR",
      nameBoletin:
        "RESOLVERÁ ITE EN TIEMPO SOBRE SOLICITUDES DE REGISTRO DE CANDIDATURAS A AYUNTAMIENTOS Y PC",
    },
    {
      id: 21,
      monthBoletin: "MAY",
      nameBoletin: "PROCEDERÁN REGISTROS DE CANDIDATURAS EN CUANTO PARTIDOS SUBSANEN INCONSISTENCIAS ITE",
    },
    {
      id: 22,
      monthBoletin: "MAY",
      nameBoletin:
        "CONCRETA ITE COLABORACIÓN CON CANIRAC PARA PROMOVER VOTO",
    },
    {
      id: 23,
      monthBoletin: "MAY",
      nameBoletin:
        "APRUEBA ITE REGISTROS DE PT",
    },
    {
      id: 24,
      monthBoletin: "MAY",
      nameBoletin: "APRUEBA ITE TOTALIDAD DE REGISTROS DE CANDIDATURAS A AYUNTAMIENTOS",
    },
    {
      id: 25,
      monthBoletin: "MAY",
      nameBoletin:
        "RECHAZA ITE SESGO EN RESOLUCIÓN DE REGISTRO DE CANDIDATURAS_ PARTIDOS INCUMPLEN REQUISITOS",
    },
    {
      id: 26,
      monthBoletin: "MAY",
      nameBoletin:
        "RECIBE ITE MÁS DE UN MILLÓN DE BOLETAS ELECTORALES PARA ELECCIÓN DE DIPUTACIONES",
    },
    {
      id: 27,
      monthBoletin: "MAY",
      nameBoletin:
        "RECIBE ITE TOTALIDAD DE BOLETAS PARA JORNADA ELECTORAL DEL 2 DE JUNIO",
    },
    {
      id: 28,
      monthBoletin: "MAY",
      nameBoletin:
        "CONMINA ITE A EVITAR USO DE DISPOSITIVOS ELECTRÓNICOS EN CASILLAS EL 2 DE JUNIO",
    },
    {
      id: 30,
      monthBoletin: "JUN",
      nameBoletin:
        "ALCANZA ELECCIÓN LOCAL PARTICIPACIÓN DE 70.3% DE ELECTORADO",
    },
    {
      id: 31,
      monthBoletin: "JUN",
      nameBoletin: "INICIA JORNADA DE CÓMPUTOS DE ELECCIÓN LOCAL",
    },
    {
      id: 32,
      monthBoletin: "JUN",
      nameBoletin:
        "APRUEBA ITE SUSTITUCIÓN DE INTEGRANTES DE CONSEJOS DE HUAMANTLA SANCTORUM DE LÁZARO CÁRDENAS Y TOTOLAC",
    },
    {
      id: 33,
      monthBoletin: "JUN",
      nameBoletin:
        "LLAMA ITE A FUERZAS POLÍTICAS A COADYUVAR CON DESARROLLO DE CÓMPUTOS",
    },
    {
      id: 34,
      monthBoletin: "JUN",
      nameBoletin: "RECHAZA ITE QUE HAYA DETECTADO BOLETAS FALTAS EN CÓMPUTO DE ELECCIÓN DE HUAMANTLA",
    },
    {
      id: 35,
      monthBoletin: "JUN",
      nameBoletin:
        "EFECTÚA ITE CÓMPUTOS DE IXTENCO AXOCOMANITLA Y TECOPILCO",
    },
    {
      id: 36,
      monthBoletin: "JUN",
      nameBoletin:
        "OCUPARÁN MUJERES 59.1% DE REGIDURÍAS EN EL ESTADO",
    },
    {
      id: 37,
      monthBoletin: "JUN",
      nameBoletin:
        "ACCIÓN AFIRMATIVA IMPULSÓ REPRESENTACIÓN DE LA DIVERSIDAD SEXUAL AUTORIDADES ELECTAS LGBTTTIQA+",
    },
    {
      id: 38,
      monthBoletin: "JUL",
      nameBoletin:
        "EFECTÚA ITE RECUENTO DE CASILLAS EN ELECCIÓN DE SANTA CRUZ TECHACHALCO",
    },
    {
      id: 39,
      monthBoletin: "JUL",
      nameBoletin:
        "RECUENTA ITE SIETE CASILLAS DE ELECCIÓN MUNICIPAL DE TEOLOCHOLCO",
    },
    {
      id: 40,
      monthBoletin: "JUL",
      nameBoletin:
        "VERIFICA ITE DESTRUCCIÓN DE PLACAS DE BOLETAS",
    },
    {
      id: 41,
      monthBoletin: "JUL",
      nameBoletin: "ENTREGA ITE CONSTANCIA A DIPUTADO DE MC EN DISTRITO 15_ REASIGNA DIPUTACIONES DE RP",
    },
    {
      id: 42,
      monthBoletin: "JUL",
      nameBoletin:
        "ENTREGA ITE CONSTANCIAS A INTEGRANTES DEL PARLAMENTO JUVENIL 2024",
    },
    {
      id: 43,
      monthBoletin: "AGO",
      nameBoletin:
        "RECONOCEN JUVENTUDES QUE ACCIONES AFIRMATIVAS IMPULSAN SU PARTICIPACIÓN",
    },
    {
      id: 44,
      monthBoletin: "AGO",
      nameBoletin: "DECLARA ITE INTEGRACIÓN DE LEGISLATURA",
    },
    {
      id: 45,
      monthBoletin: "SEP",
      nameBoletin:
        "DECLARA ITE CONCLUSIÓN DE PROCESO ELECTORAL LOCAL ORDINARIO",
    },
    {
      id: 46,
      monthBoletin: "SEP",
      nameBoletin:
        "EXPONE ITE IMÁGENES DE 30 AÑOS DE VIDA INSTITUCIONAL EN EL PALACIO DE LA CULTURA",
    },
    {
      id: 47,
      monthBoletin: "SEP",
      nameBoletin:
        "LLAMA ITE A LA CIVILIDAD EN PROCESO ELECTORAL EXTRAORDINARIO",
    },
    {
      id: 48,
      monthBoletin: "SEP",
      nameBoletin:
        "APRUEBA ITE PRESUPUESTO DE EGRESOS 2025",
    },
    {
      id: 49,
      monthBoletin: "OCT",
      nameBoletin:
        "RINDE PROTESTA ANAKAREN MONSERRAT ROJAS CUAUTLE COMO CONSEJERA ELECTORAL DEL ITE",
    },
    {
      id: 50,
      monthBoletin: "OCT",
      nameBoletin:
        "DECLARA ITE PÉRDIDA DE REGISTRO DEL PRD",
    },
    {
      id: 51,
      monthBoletin: "OCT",
      nameBoletin: "AJUSTA ITE PRESUPUESTO POR PROCESO ELECTORAL EXTRAORDINARIO 2024",
    },
    {
      id: 52,
      monthBoletin: "OCT",
      nameBoletin:
        "EXHORTA ITE A PARTIDOS A SOLICITAR DEBATES EN ELECCIÓN EXTRAORDINARIA",
    },
    {
      id: 53,
      monthBoletin: "OCT",
      nameBoletin:
        "SEGURIDAD PÚBLICA ECONOMÍA Y PARIDAD Y VIOLENCIA DE GÉNERO TEMAS PARA DEBATES DE PELE 2024",
    },
    {
      id: 54,
      monthBoletin: "NOV",
      nameBoletin: "ITE IMPROCEDENTE POSTULACIÓN DEL PRI PARA PRESIDENCIA DE SANTA MARÍA CAPULAC",
    },
    {
      id: 55,
      monthBoletin: "NOV",
      nameBoletin:
        "RECIBE ITE BOLETAS PARA JORNADA ELECTORAL DEL 24 DE NOVIEMBRE",
    },
    {
      id: 56,
      monthBoletin: "NOV",
      nameBoletin:
        "VERIFICA ITE MEDIDAS DE SEGURIDAD EN BOLETAS DE ELECCIÓN EXTRAORDINARIA",
    },
    {
      id: 57,
      monthBoletin: "NOV",
      nameBoletin:
        "PRESENTAN ITE E INE LOGÍSTICA PARA TRASLADO DE PAQUETERÍA ELECTORAL ESTE DOMINGO ANTE CUERPOS DE SEGURIDAD",
    },
    {
      id: 58,
      monthBoletin: "NOV",
      nameBoletin:
        "EXHORTA ITE A CIUDADANÍA DE SAN LUCAS TECOPILCO Y CAPULAC A ACUDIR HOY A LAS URNAS EN AMBIENTE PACÍFICO",
    },
    {
      id: 59,
      monthBoletin: "NOV",
      nameBoletin:
        "EFECTÚA ITE SEGUNDA VERIFICACIÓN DE MEDIDAS DE SEGURIDAD DE BOLETAS EN CASILLAS",
    },
    {
      id: 60,
      monthBoletin: "NOV",
      nameBoletin:
        "CONCLUYE JORNADA ELECTORAL DE PROCESO EXTRAORDINARIO; RECIBE ITE PAQUETES ELECTORALES",
    },
    {
      id: 61,
      monthBoletin: "NOV",
      nameBoletin: "ENTREGA ITE CONSTANCIA DE MAYORÍA A GANADORAS DE ELECCIÓN",
    },
    {
      id: 62,
      monthBoletin: "NOV",
      nameBoletin:
        "VIOLENTAR A MUJERES SIN CONSECUENCIAS PERPETRA DELITO CLAUDIA ZAVALA",
    },
    {
      id: 63,
      monthBoletin: "NOV",
      nameBoletin:
        "APRUEBA ITE REGISTRO DE PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA TLAXCALA",
    },
    {
      id: 64,
      monthBoletin: "DIC",
      nameBoletin: "DECLARA ITE CONCLUSIÓN DE PROCESO ELECTORAL EXTRAORDINARIO 2024",
    },
    {
      id: 65,
      monthBoletin: "DIC",
      nameBoletin:
        "MOTIVA ITE REFLEXIÓN SOBRE PARTICIPACIÓN EN ELECCIONES DE RESIDENTES EN EL EXTRANJERO",
    },
    {
      id: 66,
      monthBoletin: "DIC",
      nameBoletin:
        "PREPARADO ITE PARA INÉDITA ELECCIÓN DE CARGOS DEL PODER JUDICIAL Y DOS TRIBUNALES",
    },
  ],
  
  2025: [
    {
      id: 1,
      monthBoletin: "ENE",
      nameBoletin: "BOLETÍN 01- DISCUTEN EN TLAXCALA EXPERTOS NACIONALES SOBRE INCLUSIÓN EN MATERIA ELECTORAL",
    },
    {
      id: 2,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 02- DONA ITE DOCUMENTACIÓN ELECTORAL PARA ELABORAR LIBROS DE TEXTO GRATUITO",
    },
    {
      id: 3,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 03- SE REÚNE PRESIDENTE DEL ITE CON LA SECRETARIA EJECUTIVA DEL INE PARA DAR SEGUIMIENTO A ELECCIÓN",
    },
    {
      id: 4,
      monthBoletin: "FEB",
      nameBoletin: "BOLETÍN 04- POR ELECCIÓN JUDICIAL, MODIFICA ITE SU REGLAMENTO DE SESIONES",
    },
    {
      id: 5,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 05- APRUEBA ITE PRESUPUESTO PARA ELECCIÓN JUDICIAL",
    },
    {
      id: 6,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 06- APRUEBA ITE CALENDARIO ELECTORAL DE ELECCIÓN JUDICIAL 2025",
    },
    {
      id: 7,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 07- RATIFICARÁ ITE PARA ELECCIÓN JUDICIAL A INTEGRANTES DE CONSEJOS, CAE Y SEL DE PELO 2023-2024 ",
    },
    {
      id: 8,
      monthBoletin: "FEB",
      nameBoletin:
        "BOLETÍN 08- APRUEBA ITE CRITERIOS PARA GARANTIZAR PARIDAD DE GÉNERO EN ELECCIÓN JUDICIAL",
    },
  ],
};
