import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import { FilterComponent, useSimpleTableConfig } from "../../../constants";
import {
  dataMonitoreoMIyD,
  dataMonitoreoPG,
  dataMonitoreoRyT,
} from "../../../data/2024/dataMonitoreos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/monitoreos/2024/";

const PdfLink = ({ url, folder }) => {
  const fullUrl = `${baseUrl}${folder}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCellPG = ({ cell }) => (
  <PdfLink folder="pg" url={cell.getValue() + ".pdf"} />
);
const renderCellRyT = ({ cell }) => (
  <PdfLink folder="ryt" url={cell.getValue() + ".pdf"} />
);
const renderCellMIyD = ({ cell }) => (
  <PdfLink folder="miyd" url={cell.getValue() + ".pdf"} />
);

const Monitoreos2024 = () => {
  useEffect(() => {
    document.title = `Monitoreos 2024`;
  }, []);

  const columnsPG = useMemo(
    () => [
      {
        accessorKey: "monthBoletin",
        header: "MES",
        Filter: FilterComponent,
        size: 50,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnFilters: false,
        Cell: renderCellPG,
      },
    ],
    []
  );

  const columnsRyT = useMemo(
    () => [
      {
        accessorKey: "monthBoletin",
        header: "MES",
        Filter: FilterComponent,
        size: 50,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnFilters: false,
        Cell: renderCellRyT,
      },
    ],
    []
  );

  const columnsMIyD = useMemo(
    () => [
      {
        accessorKey: "monthBoletin",
        header: "MES",
        Filter: FilterComponent,
        size: 50,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnFilters: false,
        Cell: renderCellMIyD,
      },
    ],
    []
  );

  const tablePG = useSimpleTableConfig(dataMonitoreoPG, columnsPG);
  const tableRyT = useSimpleTableConfig(dataMonitoreoRyT, columnsRyT);
  const tableMIyD = useSimpleTableConfig(dataMonitoreoMIyD, columnsMIyD);

  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Comunicación Social" },
            { label: "Monitoreos", url: "/Monitoreo" },
            { label: `Monitoreos 2024` },
          ]}
        />
        <TitlePages title="Comunicación Social" subTitle="" />
        <br />
        <br />
        <div className="w-50 mx-auto">
          <TitlePages title="" subTitle="Informe Final" />
        </div>
        <p className="fw-bolder">
          Informe Final de Cumplimiento de los Partidos Políticos sobre la
          Distribución de los Promocionales de Radio y Televisión en Razón de
          Género para la Etapa de Campaña del Proceso Electoral Local Ordinario
          2023-2024 del Estado de Tlaxcala"
        </p>
        <a
          href="https://itetlax.org.mx/assets/pdf/monitoreos/2024/informe_final.pdf"
          target="_blank"
          rel="noreferrer"
          className="btn btn-ite"
        >
          DESCARGA AQUÍ
        </a>
        <br />
        <br />
        <div className="w-50 mx-auto">
          <TitlePages title="" subTitle="Monitoreo con perspectiva de género" />
        </div>
        <MaterialReactTable table={tablePG} />
        <br />
        <br />
        <br />
        <div className="w-50 mx-auto">
          <TitlePages title="" subTitle="Monitoreo radio y televisión" />
        </div>
        <MaterialReactTable table={tableRyT} />
        <br />
        <br />
        <br />
        <div className="w-50 mx-auto">
          <TitlePages
            title=""
            subTitle="Monitoreo medios impresos y digitales"
          />
        </div>
        <MaterialReactTable table={tableMIyD} />
      </div>
    </>
  );
};

export default Monitoreos2024;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
  folder: PropTypes.string.isRequired,
};
