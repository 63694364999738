import React from "react";
import TitlePages from "../../../../layout/TitlePages";
import Breadcrumbs from "../../../../layout/Breadcrumbs";
import { Link } from "react-router-dom";


const ImageLink = ({ to, src, alt, text }) => (
  <div className="folder-item">
    <Link to={to}>
      <img src={src} className="img-fluid hover-effect" alt={alt} />
    </Link>
    <p className="text-strong">{text}</p>
  </div>
);

const PlanesdeTrabajo = () => {
  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Transparencia", url: "/Transparencia" },
          { label: "Planes de Trabajo" },
        ]}
      />

      <TitlePages
        title="Transparencia"
        subTitle="Planes de Trabajo"
      />

      <div className="w-100 Grid__folder margin-bottom-2">
        <ImageLink
          to="/Planes2024" 
          src="https://itetlax.org.mx/assets/img/icons/2024.png" 
          alt="Plan de Trabajo 2024"
        />
        <ImageLink
          to="/Planes2025" 
          src="https://itetlax.org.mx/assets/img/icons/2025.png" 
          alt="Plan de Trabajo 2025"
        />
      </div>
    </>
  );
};

export default PlanesdeTrabajo;