import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./Header.css";

export default class Header extends Component {
  render() {
    return (
      <>
        <div className="Header__social-network">
          <div
            className="Header__social-network-individual"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              width: "40px",
              height: "40px",
            }}
          >
            <a
              href="https://itetlax.org.mx/assets/pdf/transparencia/1actual.pdf"
              target="_blank"
              rel="noreferrer"
              className="Header__btn-socialnetwork"
              title="Directorio general"
              style={{
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                textDecoration: "none",
              }}
            >
              <svg
                className="phone-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="4 4 13 15" 
                fill="currentColor"
                width="24" 
                height="24" 
              >
                <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
              </svg>
            </a>
          </div>
          <div className="Header__social-network-individual">
            <a
              href="https://www.youtube.com/channel/UCUBfUX_c54NfhhvZRzK0k1w"
              target="_blank"
              rel="noreferrer"
              className=" Header__btn-socialnetwork"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
          <div className="Header__social-network-individual">
            <a
              href="https://twitter.com/ITETLAX"
              target="_blank"
              rel="noreferrer"
              className=" Header__btn-socialnetwork"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <div className="Header__social-network-individual">
            <a
              href="https://es-la.facebook.com/InstitutoTlaxcaltecadeElecciones/"
              target="_blank"
              rel="noreferrer"
              className=" Header__btn-socialnetwork"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
          <div className="Header__social-network-individual">
            <a
              href="https://www.instagram.com/ite_tlaxcala"
              target="_blank"
              rel="noreferrer"
              className=" Header__btn-socialnetwork"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>{" "}
        </div>
        <div className="Header__principal mt-4 ">
          <div>
            <Link to="./">
              <img
                src="https://itetlax.org.mx/assets/img/logoite.webp"
                alt="Instituto Tlaxcalteca de Elecciones"
                className="img-fluid Header__logoITE"
              />
            </Link>
          </div>
          <div>Instituto Tlaxcalteca de Elecciones</div>
        </div>
      </>
    );
  }
}
