export const dataArt63 = {
  2023: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F01.xlsx",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02A.xlsx",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02B.xlsx",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F03.xlsx",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F04.xlsx",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F05.xlsx",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F06.xlsx",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F07.xlsx",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "VIII-A",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08A.xlsx",
      managerId: 2,
    },
    {
      fraccion: "VIII-B",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08B.xlsx",
      managerId: 2,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F09.xlsx",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "X-A",
      titulo: "Plazas vacantes del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10A.xlsx",
      managerId: 3,
    },
    {
      fraccion: "X-B",
      titulo:
        "Total de plazas vacantes y ocupadas del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10B.xlsx",
      managerId: 3,
    },
    {
      fraccion: "XI",
      titulo: "Contrataciones de servicios profesionales por honorarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F11.xlsx",
      managerId: null,
    },
    {
      fraccion: "XII",
      titulo:
        "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F12.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIII",
      titulo: "Domicilio de la Unidad de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F13.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIV",
      titulo: "Concursos para ocupar cargos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F14.xlsx",
      managerId: null,
    },
    {
      id: 4,
      fraccion: "XV",
      titulo: "Subsidios, estímulos y apoyos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XV-A",
      titulo: "Programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      fraccion: "XV-B",
      titulo: "Padrón de beneficiarios de programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      id: 5,
      fraccion: "XVI",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XVI-A",
      titulo: "Normatividad laboral",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F16A.xlsx",
      managerId: 5,
    },
    {
      fraccion: "XVI-B",
      titulo: "Recursos públicos entregados a sindicatos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F16B.xlsx",
      managerId: 5,
    },
    {
      fraccion: "XVII",
      titulo: "Información curricular de los(as) servidores(as) públicas(os)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F17.xlsx",
      managerId: null,
    },
    {
      fraccion: "XVIII",
      titulo: "Sanciones administrativa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F18.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIX",
      titulo: "Servicios que ofrece el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F19.xlsx",
      managerId: null,
    },
    {
      fraccion: "XX",
      titulo: "Trámites que se realizan",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F20.xlsx",
      managerId: null,
    },
    {
      id: 6,
      fraccion: "XXI",
      titulo: "Presupuesto asignado",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXI-A",
      titulo: "Presupuesto asignado anual",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21A.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-B",
      titulo: "Ejercicio de los egresos presupuestarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21B.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-C",
      titulo: "Cuenta pública",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21C.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXII",
      titulo: "Deuda Pública",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 7,
      fraccion: "XXIII",
      titulo: "Gastos de publicidad oficial",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXIII-A",
      titulo: "Programa Anual de Comunicación Social o equivalente",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23A.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-B",
      titulo: "Contratación de servicios de publicidad oficial",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23B.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-C",
      titulo: "Utilización de los tiempos oficiales en radio y tv",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23C.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-D",
      titulo:
        "Hipervínculo a información de tiempos oficiales en radio y televisión",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23D.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIV",
      titulo: "Resultados de auditorías realizadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F24_1.xlsx",
      excel2: "LTAIPT_A63F24_2.xlsx",
      excel3: "LTAIPT_A63F24_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXV",
      titulo: "Resultados de la dictaminación de los estados financieros",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F25.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVI",
      titulo: "Personas que usan recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F26.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVII",
      titulo:
        "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F27.xlsx",
      managerId: null,
    },
    {
      id: 8,
      fraccion: "XXVIII",
      titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXVIII-A",
      titulo:
        "Procedimientos de licitación pública e invitación a cuando menos tres personas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28A.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXVIII-B",
      titulo: "Procedimientos de adjudicación directa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28B.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXIX",
      titulo: "Informes emitidos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXX",
      titulo: "Estadísticas generadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F30.xlsx",
      managerId: null,
    },
    {
      id: 9,
      fraccion: "XXXI",
      titulo: "Informe financiero",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXI-A",
      titulo: "Gasto por Capítulo, Concepto y Partida",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31A.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXI-B",
      titulo: "Informes financieros contables, presupuestales y programáticos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31B.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXII",
      titulo: "Padrón de proveedores y contratistas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F32.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXXIII",
      titulo:
        "Convenios de coordinación, de concertación con el sector social o privado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F33_1.xlsx",
      excel2: "LTAIPT_A63F33_2.xlsx",
      excel3: "LTAIPT_A63F33_3.xlsx",
      managerId: null,
    },
    {
      id: 10,
      fraccion: "XXXIV",
      titulo: "Inventario",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIV-A",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34A.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-B",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34B.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-C",
      titulo: "Inventario de bajas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34C.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-D",
      titulo: "Inventario de bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34D.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-E",
      titulo: "Inventario de altas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34E.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-F",
      titulo: "Inventario de bajas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34F.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-G",
      titulo: "Inventario de bienes muebles e inmuebles donados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34G.xlsx",
      managerId: 10,
    },
    {
      id: 11,
      fraccion: "XXXV",
      titulo: "Recomendaciones derechos humanos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXV-A",
      titulo: "Recomendaciones de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35A.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-B",
      titulo: "Casos especiales de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35B.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-C",
      titulo:
        "Recomendaciones de organismos internacionales de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35C.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXVI",
      titulo: "Resoluciones y laudos emitidos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 12,
      fraccion: "XXXVII",
      titulo: "Participación ciudadana",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVII-A",
      titulo: "Mecanismos de participación ciudadana",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37A.xlsx",
      managerId: 12,
    },
    {
      fraccion: "XXXVII-B",
      titulo: "Resultado de los mecanismos de participación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37B.xlsx",
      managerId: 12,
    },
    {
      id: 13,
      fraccion: "XXXVIII",
      titulo: "Otros programas",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVIII-A",
      titulo: "Programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38A.xlsx",
      managerId: 13,
    },
    {
      fraccion: "XXXVIII-B",
      titulo: "Trámites para acceder a programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38B.xlsx",
      managerId: 13,
    },
    {
      id: 14,
      fraccion: "XXXIX",
      titulo: "Actas y resoluciones Comité de Transparencia",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIX-A",
      titulo: "Informe de sesiones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39A.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-B",
      titulo: "Informe de Resoluciones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39B.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-C",
      titulo: "Integrantes del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39C.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-D",
      titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39D.xlsx",
      managerId: 14,
    },
    {
      id: 15,
      fraccion: "XL",
      titulo: "Evaluación y encuesta programas financiados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XL-A",
      titulo:
        "Evaluaciones y encuestas a programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40A.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XL-B",
      titulo: "Encuestas sobre programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40B.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XLI",
      titulo: "Estudios financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F41.xlsx",
      managerId: null,
    },
    {
      id: 16,
      fraccion: "XLII",
      titulo: "Jubilados y pensionados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLII-A",
      titulo: "Hipervínculo al listado de pensionados y jubilados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42A.xlsx",
      managerId: 16,
    },
    {
      fraccion: "XLII-B",
      titulo: "Listado de jubilados y pensionados y el monto que reciben",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42B.xlsx",
      managerId: 16,
    },
    {
      id: 17,
      fraccion: "XLIII",
      titulo: "Ingresos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIII-A",
      titulo:
        "Ingresos recibidos por cualquier concepto por el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43A.xlsx",
      managerId: 17,
    },
    {
      fraccion: "XLIII-B",
      titulo: "Responsables de recibir, administrar y ejercer los ingresos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43B.xlsx",
      managerId: 17,
    },
    {
      id: 18,
      fraccion: "XLIV",
      titulo: "Donaciones",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIV-A",
      titulo: "Donaciones en dinero realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLIV-B",
      titulo: "Donaciones en especie realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLV",
      titulo: "Catálogo de disposición documental y guía simple de archivos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F45_1.xlsx",
      excel2: "LTAIPT_A63F45_2.xlsx",
      excel3: "LTAIPT_A63F45_3.xlsx",
      managerId: null,
    },
    {
      id: 19,
      fraccion: "XLVI",
      titulo: "Actas de sesiones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVI-A",
      titulo: "Actas del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46A_1.xlsx",
      excel2: "LTAIPT_A63F46A_2.xlsx",
      excel3: "LTAIPT_A63F46A_3.xlsx",
      excel4: "LTAIPT_A63F46A_4.xlsx",
      managerId: 19,
    },
    {
      fraccion: "XLVI-B",
      titulo: "Opiniones y recomendaciones del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46B_1.xlsx",
      excel2: "LTAIPT_A63F46B_2.xlsx",
      excel3: "LTAIPT_A63F46B_3.xlsx",
      excel4: "LTAIPT_A63F46B_4.xlsx",
      managerId: 19,
    },
    {
      id: 20,
      fraccion: "XLVII",
      titulo: "Más información relacionada",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVII-A",
      titulo: "Información de interés público",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47A.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-B",
      titulo: "Preguntas frecuentes",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47B.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-C",
      titulo: "Transparencia proactiva",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47C.xlsx",
      managerId: 20,
    },
    {
      fraccion: "C",
      titulo: "Obligaciones aplicables",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F100.xlsx",
      managerId: null,
    },
  ],

  2022: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F01.xlsx",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02A.xlsx",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02B.xlsx",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F03.xlsx",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F04.xlsx",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F05_1.xlsx",
      excel2: "LTAIPT_A63F05_2.xlsx",
      excel3: "LTAIPT_A63F05_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F06_1.xlsx",
      excel2: "LTAIPT_A63F06_2.xlsx",
      excel3: "LTAIPT_A63F06_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F07.xlsx",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "VIII-A",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08A_1.xlsx",
      excel2: "LTAIPT_A63F08A_2.xlsx",
      excel3: "LTAIPT_A63F08A_3.xlsx",
      managerId: 2,
    },
    {
      fraccion: "VIII-B",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08B_1.xlsx",
      excel2: "LTAIPT_A63F08B_2.xlsx",
      excel3: "LTAIPT_A63F08B_3.xlsx",
      managerId: 2,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F09_1.xlsx",
      excel2: "LTAIPT_A63F09_2.xlsx",
      excel3: "LTAIPT_A63F09_3.xlsx",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "X-A",
      titulo: "Plazas vacantes del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10A.xlsx",
      managerId: 3,
    },
    {
      fraccion: "X-B",
      titulo:
        "Total de plazas vacantes y ocupadas del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10B.xlsx",
      managerId: 3,
    },
    {
      fraccion: "XI",
      titulo: "Contrataciones de servicios profesionales por honorarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F11_1.xlsx",
      excel2: "LTAIPT_A63F11_2.xlsx",
      excel3: "LTAIPT_A63F11_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "XII",
      titulo:
        "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F12.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIII",
      titulo: "Domicilio de la Unidad de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F13.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIV",
      titulo: "Concursos para ocupar cargos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F14_1.xlsx",
      excel2: "LTAIPT_A63F14_2.xlsx",
      excel3: "LTAIPT_A63F14_3.xlsx",
      managerId: null,
    },
    {
      id: 4,
      fraccion: "XV",
      titulo: "Subsidios, estímulos y apoyos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XV-A",
      titulo: "Programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      fraccion: "XV-B",
      titulo: "Padrón de beneficiarios de programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      id: 5,
      fraccion: "XVI",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XVI-A",
      titulo: "Normatividad laboral",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F16A_1.xlsx",
      excel2: "LTAIPT_A63F16A_2.xlsx",
      managerId: 5,
    },
    {
      fraccion: "XVI-B",
      titulo: "Recursos públicos entregados a sindicatos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F16B_1.xlsx",
      excel2: "LTAIPT_A63F16B_2.xlsx",
      excel3: "LTAIPT_A63F16B_3.xlsx",
      managerId: 5,
    },
    {
      fraccion: "XVII",
      titulo: "Información curricular de los(as) servidores(as) públicas(os)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F17.xlsx",
      managerId: null,
    },
    {
      fraccion: "XVIII",
      titulo: "Sanciones administrativa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F18_1.xlsx",
      excel2: "LTAIPT_A63F18_2.xlsx",
      excel3: "LTAIPT_A63F18_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIX",
      titulo: "Servicios que ofrece el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F19.xlsx",
      managerId: null,
    },
    {
      fraccion: "XX",
      titulo: "Trámites que se realizan",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F20.xlsx",
      managerId: null,
    },
    {
      id: 6,
      fraccion: "XXI",
      titulo: "Presupuesto asignado",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXI-A",
      titulo: "Presupuesto asignado anual",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21A.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-B",
      titulo: "Ejercicio de los egresos presupuestarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21B_1.xlsx",
      excel2: "LTAIPT_A63F21B_2.xlsx",
      excel3: "LTAIPT_A63F21B_3.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-C",
      titulo: "Cuenta pública",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21C.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXII",
      titulo: "Deuda Pública",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 7,
      fraccion: "XXIII",
      titulo: "Gastos de publicidad oficial",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXIII-A",
      titulo: "Programa Anual de Comunicación Social o equivalente",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23A.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-B",
      titulo: "Contratación de servicios de publicidad oficial",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23B_1.xlsx",
      excel2: "LTAIPT_A63F23B_2.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-C",
      titulo: "Utilización de los tiempos oficiales en radio y tv",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23C_1.xlsx",
      excel2: "LTAIPT_A63F23C_2.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-D",
      titulo:
        "Hipervínculo a información de tiempos oficiales en radio y televisión",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23D_1.xlsx",
      excel2: "LTAIPT_A63F23D_2.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIV",
      titulo: "Resultados de auditorías realizadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F24_1.xlsx",
      excel2: "LTAIPT_A63F24_2.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXV",
      titulo: "Resultados de la dictaminación de los estados financieros",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F25.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVI",
      titulo: "Personas que usan recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F26_1.xlsx",
      excel2: "LTAIPT_A63F26_2.xlsx",
      excel3: "LTAIPT_A63F26_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVII",
      titulo:
        "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F27_1.xlsx",
      excel2: "LTAIPT_A63F27_2.xlsx",
      excel3: "LTAIPT_A63F27_3.xlsx",
      managerId: null,
    },
    {
      id: 8,
      fraccion: "XXVIII",
      titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXVIII-A",
      titulo:
        "Procedimientos de licitación pública e invitación a cuando menos tres personas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28A_1.xlsx",
      excel2: "LTAIPT_A63F28A_2.xlsx",
      excel3: "LTAIPT_A63F28A_3.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXVIII-B",
      titulo: "Procedimientos de adjudicación directa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28B_1.xlsx",
      excel2: "LTAIPT_A63F28B_2.xlsx",
      excel3: "LTAIPT_A63F28B_3.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXIX",
      titulo: "Informes emitidos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F29.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXX",
      titulo: "Estadísticas generadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F30.xlsx",
      managerId: null,
    },
    {
      id: 9,
      fraccion: "XXXI",
      titulo: "Informe financiero",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXI-A",
      titulo: "Gasto por Capítulo, Concepto y Partida",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31A_1.xlsx",
      excel2: "LTAIPT_A63F31A_2.xlsx",
      excel3: "LTAIPT_A63F31A_3.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXI-B",
      titulo: "Informes financieros contables, presupuestales y programáticos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31B_1.xlsx",
      excel2: "LTAIPT_A63F31B_2.xlsx",
      excel3: "LTAIPT_A63F31B_3.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXII",
      titulo: "Padrón de proveedores y contratistas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F32_1.xlsx",
      excel2: "LTAIPT_A63F32_2.xlsx",
      excel3: "LTAIPT_A63F32_3.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXXIII",
      titulo:
        "Convenios de coordinación, de concertación con el sector social o privado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F33.xlsx",
      managerId: null,
    },
    {
      id: 10,
      fraccion: "XXXIV",
      titulo: "Inventario",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIV-A",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34A_1.xlsx",
      excel2: "LTAIPT_A63F34A_2.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-B",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34B_1.xlsx",
      excel2: "LTAIPT_A63F34B_2.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-C",
      titulo: "Inventario de bajas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34C_1.xlsx",
      excel2: "LTAIPT_A63F34C_2.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-D",
      titulo: "Inventario de bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34D_1.xlsx",
      excel2: "LTAIPT_A63F34D_2.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-E",
      titulo: "Inventario de altas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34E_1.xlsx",
      excel2: "LTAIPT_A63F34E_2.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-F",
      titulo: "Inventario de bajas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34F.xlsx",
      excel2: "LTAIPT_A63F34G.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-G",
      titulo: "Inventario de bienes muebles e inmuebles donados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34G_1.xlsx",
      excel2: "LTAIPT_A63F34G_2.xlsx",
      managerId: 10,
    },
    {
      id: 11,
      fraccion: "XXXV",
      titulo: "Recomendaciones derechos humanos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXV-A",
      titulo: "Recomendaciones de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35A.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-B",
      titulo: "Casos especiales de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35B.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-C",
      titulo:
        "Recomendaciones de organismos internacionales de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35C.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXVI",
      titulo: "Resoluciones y laudos emitidos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 12,
      fraccion: "XXXVII",
      titulo: "Participación ciudadana",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVII-A",
      titulo: "Mecanismos de participación ciudadana",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37A.xlsx",
      managerId: 12,
    },
    {
      fraccion: "XXXVII-B",
      titulo: "Resultado de los mecanismos de participación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37B.xlsx",
      managerId: 12,
    },
    {
      id: 13,
      fraccion: "XXXVIII",
      titulo: "Otros programas",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVIII-A",
      titulo: "Programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38A_1.xlsx",
      excel2: "LTAIPT_A63F38A_2.xlsx",
      managerId: 13,
    },
    {
      fraccion: "XXXVIII-B",
      titulo: "Trámites para acceder a programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38B_1.xlsx",
      excel2: "LTAIPT_A63F38B_2.xlsx",
      managerId: 13,
    },
    {
      id: 14,
      fraccion: "XXXIX",
      titulo: "Actas y resoluciones Comité de Transparencia",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIX-A",
      titulo: "Informe de sesiones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39A.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-B",
      titulo: "Informe de Resoluciones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39B.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-C",
      titulo: "Integrantes del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39C.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-D",
      titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39D.xlsx",
      managerId: 14,
    },
    {
      id: 15,
      fraccion: "XL",
      titulo: "Evaluación y encuesta programas financiados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XL-A",
      titulo:
        "Evaluaciones y encuestas a programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40A.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XL-B",
      titulo: "Encuestas sobre programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40B.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XLI",
      titulo: "Estudios financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F41_1.xlsx",
      excel2: "LTAIPT_A63F41_2.xlsx",
      excel3: "LTAIPT_A63F41_3.xlsx",
      managerId: null,
    },
    {
      id: 16,
      fraccion: "XLII",
      titulo: "Jubilados y pensionados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLII-A",
      titulo: "Hipervínculo al listado de pensionados y jubilados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42A.xlsx",
      managerId: 16,
    },
    {
      fraccion: "XLII-B",
      titulo: "Listado de jubilados y pensionados y el monto que reciben",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42B.xlsx",
      managerId: 16,
    },
    {
      id: 17,
      fraccion: "XLIII",
      titulo: "Ingresos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIII-A",
      titulo:
        "Ingresos recibidos por cualquier concepto por el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43A_1.xlsx",
      excel2: "LTAIPT_A63F43A_2.xlsx",
      excel3: "LTAIPT_A63F43A_3.xlsx",
      managerId: 17,
    },
    {
      fraccion: "XLIII-B",
      titulo: "Responsables de recibir, administrar y ejercer los ingresos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43B_1.xlsx",
      excel2: "LTAIPT_A63F43B_2.xlsx",
      excel3: "LTAIPT_A63F43B_3.xlsx",
      managerId: 17,
    },
    {
      id: 18,
      fraccion: "XLIV",
      titulo: "Donaciones",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIV-A",
      titulo: "Donaciones en dinero realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLIV-B",
      titulo: "Donaciones en especie realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLV",
      titulo: "Catálogo de disposición documental y guía simple de archivos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F45_1.xlsx",
      excel2: "LTAIPT_A63F45_2.xlsx",
      managerId: null,
    },
    {
      id: 19,
      fraccion: "XLVI",
      titulo: "Actas de sesiones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVI-A",
      titulo: "Actas del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46A_1.xlsx",
      excel2: "LTAIPT_A63F46A_2.xlsx",
      excel3: "LTAIPT_A63F46A_3.xlsx",
      excel4: "LTAIPT_A63F46A_4.xlsx",
      managerId: 19,
    },
    {
      fraccion: "XLVI-B",
      titulo: "Opiniones y recomendaciones del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46B_1.xlsx",
      excel2: "LTAIPT_A63F46B_2.xlsx",
      excel3: "LTAIPT_A63F46B_3.xlsx",
      excel4: "LTAIPT_A63F46B_4.xlsx",
      managerId: 19,
    },
    {
      id: 20,
      fraccion: "XLVII",
      titulo: "Más información relacionada",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVII-A",
      titulo: "Información de interés público",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47A.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-B",
      titulo: "Preguntas frecuentes",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47B.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-C",
      titulo: "Transparencia proactiva",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47C.xlsx",
      managerId: 20,
    },
    {
      fraccion: "C",
      titulo: "Obligaciones aplicables",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F100.xlsx",
      managerId: null,
    },
  ],

  2021: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F01.xlsx",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02A.xlsx",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F02B.xlsx",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F03.xlsx",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F04.xlsx",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F05.xlsx",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F06.xlsx",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F07.xlsx",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "VIII-A",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08A.xlsx",
      managerId: 2,
    },
    {
      fraccion: "VIII-B",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F08B.xlsx",
      managerId: 2,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F09.xlsx",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "X-A",
      titulo: "Plazas vacantes del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10A.xlsx",
      managerId: 3,
    },
    {
      fraccion: "X-B",
      titulo:
        "Total de plazas vacantes y ocupadas del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F10B.xlsx",
      managerId: 3,
    },
    {
      fraccion: "XI",
      titulo: "Contrataciones de servicios profesionales por honorarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F11.xlsx",
      managerId: null,
    },
    {
      fraccion: "XII",
      titulo:
        "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F12.xls",
      managerId: null,
    },
    {
      fraccion: "XIII",
      titulo: "Domicilio de la Unidad de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F13.xlsx",
      managerId: null,
    },
    {
      fraccion: "XIV",
      titulo: "Concursos para ocupar cargos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F14.xlsx",
      managerId: null,
    },
    {
      id: 4,
      fraccion: "XV",
      titulo: "Subsidios, estímulos y apoyos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XV-A",
      titulo: "Programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      fraccion: "XV-B",
      titulo: "Padrón de beneficiarios de programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      id: 5,
      fraccion: "XVI",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XVI-A",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F16A.xlsx",
      managerId: 5,
    },
    {
      fraccion: "XVII",
      titulo: "Información curricular de los(as) servidores(as) públicas(os)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F17.xlsx",
      managerId: null,
    },
    {
      fraccion: "XVIII",
      titulo: "Sanciones administrativa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F18.xls",
      managerId: null,
    },
    {
      fraccion: "XIX",
      titulo: "Servicios que ofrece el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F19.xlsx",
      managerId: null,
    },
    {
      fraccion: "XX",
      titulo: "Trámites que se realizan",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F20.xlsx",
      managerId: null,
    },
    {
      id: 6,
      fraccion: "XXI",
      titulo: "Presupuesto asignado",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXI-A",
      titulo: "Presupuesto asignado anual",
      cumplimiento: "Aplica",
      excel: "LTAIPT_A63F21A.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-B",
      titulo: "Ejercicio de los egresos presupuestarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21B.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXI-C",
      titulo: "Cuenta pública",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F21C.xlsx",
      managerId: 6,
    },
    {
      fraccion: "XXII",
      titulo: "Deuda Pública",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 7,
      fraccion: "XXIII",
      titulo: "Gastos de publicidad oficial",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXIII-A",
      titulo: "Programa Anual de Comunicación Social o equivalente",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23A.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-B",
      titulo: "Contratación de servicios de publicidad oficial",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23B.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-C",
      titulo: "Utilización de los tiempos oficiales en radio y tv",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23C.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIII-D",
      titulo:
        "Hipervínculo a información de tiempos oficiales en radio y televisión",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F23D.xlsx",
      managerId: 7,
    },
    {
      fraccion: "XXIV",
      titulo: "Resultados de auditorías realizadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F24.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXV",
      titulo: "Resultados de la dictaminación de los estados financieros",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F25.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVI",
      titulo: "Personas que usan recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F26.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXVII",
      titulo:
        "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F27.xlsx",
      managerId: null,
    },
    {
      id: 8,
      fraccion: "XXVIII",
      titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXVIII-A",
      titulo:
        "Procedimientos de licitación pública e invitación a cuando menos tres personas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28A.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXVIII-B",
      titulo: "Procedimientos de adjudicación directa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F28B.xlsx",
      managerId: 8,
    },
    {
      fraccion: "XXIX",
      titulo: "Informes emitidos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F29.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXX",
      titulo: "Estadísticas generadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F30.xlsx",
      managerId: null,
    },
    {
      id: 9,
      fraccion: "XXXI",
      titulo: "Informe financiero",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXI-A",
      titulo: "Gasto por Capítulo, Concepto y Partida",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31A.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXI-B",
      titulo: "Informes financieros contables, presupuestales y programáticos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F31B.xlsx",
      managerId: 9,
    },
    {
      fraccion: "XXXII",
      titulo: "Padrón de proveedores y contratistas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F32.xlsx",
      managerId: null,
    },
    {
      fraccion: "XXXIII",
      titulo:
        "Convenios de coordinación, de concertación con el sector social o privado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F33.xlsx",
      managerId: null,
    },
    {
      id: 10,
      fraccion: "XXXIV",
      titulo: "Inventario",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIV-A",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34A.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-B",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34B.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-C",
      titulo: "Inventario de bajas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34C.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-D",
      titulo: "Inventario de bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34D.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-E",
      titulo: "Inventario de altas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34E.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-F",
      titulo: "Inventario de bajas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34F.xlsx",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-G",
      titulo: "Inventario de bienes muebles e inmuebles donados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F34G.xlsx",
      managerId: 10,
    },
    {
      id: 11,
      fraccion: "XXXV",
      titulo: "Recomendaciones derechos humanos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXV-A",
      titulo: "Recomendaciones de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35A.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-B",
      titulo: "Casos especiales de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35B.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXV-C",
      titulo:
        "Recomendaciones de organismos internacionales de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35C.xlsx",
      managerId: 11,
    },
    {
      fraccion: "XXXVI",
      titulo: "Resoluciones y laudos emitidos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 12,
      fraccion: "XXXVII",
      titulo: "Participación ciudadana",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVII-A",
      titulo: "Mecanismos de participación ciudadana",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37A.xlsx",
      managerId: 12,
    },
    {
      fraccion: "XXXVII-B",
      titulo: "Resultado de los mecanismos de participación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F37B.xlsx",
      managerId: 12,
    },
    {
      id: 13,
      fraccion: "XXXVIII",
      titulo: "Otros programas",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVIII-A",
      titulo: "Programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38A.xlsx",
      managerId: 13,
    },
    {
      fraccion: "XXXVIII-B",
      titulo: "Trámites para acceder a programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F38B.xlsx",
      managerId: 13,
    },
    {
      id: 14,
      fraccion: "XXXIX",
      titulo: "Actas y resoluciones Comité de Transparencia",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIX-A",
      titulo: "Informe de sesiones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39A.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-B",
      titulo: "Informe de Resoluciones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39B.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-C",
      titulo: "Integrantes del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39C.xlsx",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-D",
      titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F39D.xlsx",
      managerId: 14,
    },
    {
      id: 15,
      fraccion: "XL",
      titulo: "Evaluación y encuesta programas financiados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XL-A",
      titulo:
        "Evaluaciones y encuestas a programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40A.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XL-B",
      titulo: "Encuestas sobre programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F40B.xlsx",
      managerId: 15,
    },
    {
      fraccion: "XLI",
      titulo: "Estudios financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F41.xlsx",
      managerId: null,
    },
    {
      id: 16,
      fraccion: "XLII",
      titulo: "Jubilados y pensionados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLII-A",
      titulo: "Hipervínculo al listado de pensionados y jubilados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42A.xlsx",
      managerId: 16,
    },
    {
      fraccion: "XLII-B",
      titulo: "Listado de jubilados y pensionados y el monto que reciben",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F42B.xlsx",
      managerId: 16,
    },
    {
      id: 17,
      fraccion: "XLIII",
      titulo: "Ingresos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIII-A",
      titulo:
        "Ingresos recibidos por cualquier concepto por el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43A.xlsx",
      managerId: 17,
    },
    {
      fraccion: "XLIII-B",
      titulo: "Responsables de recibir, administrar y ejercer los ingresos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F43B.xlsx",
      managerId: 17,
    },
    {
      id: 18,
      fraccion: "XLIV",
      titulo: "Donaciones",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIV-A",
      titulo: "Donaciones en dinero realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLIV-B",
      titulo: "Donaciones en especie realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLV",
      titulo: "Catálogo de disposición documental y guía simple de archivos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F45_1.xlsx",
      excel2: "LTAIPT_A63F45_2.xlsx",
      excel3: "LTAIPT_A63F45_3.xlsx",
      managerId: null,
    },
    {
      id: 19,
      fraccion: "XLVI",
      titulo: "Actas de sesiones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVI-A",
      titulo: "Actas del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46A_1.xlsx",
      excel2: "LTAIPT_A63F46A_2.xlsx",
      excel3: "LTAIPT_A63F46A_3.xlsx",
      managerId: 19,
    },
    {
      fraccion: "XLVI-B",
      titulo: "Opiniones y recomendaciones del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F46B_1.xlsx",
      excel2: "LTAIPT_A63F46B_2.xlsx",
      excel3: "LTAIPT_A63F46B_3.xlsx",
      managerId: 19,
    },
    {
      id: 20,
      fraccion: "XLVII",
      titulo: "Más información relacionada",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVII-A",
      titulo: "Información de interés público",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47A.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-B",
      titulo: "Preguntas frecuentes",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47B.xlsx",
      managerId: 20,
    },
    {
      fraccion: "XLVII-C",
      titulo: "Transparencia proactiva",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F47C.xlsx",
      managerId: 20,
    },
    {
      fraccion: "C",
      titulo: "Obligaciones aplicables",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F100.xlsx",
      managerId: null,
    },
  ],

  2020: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F01.xlsx",
      pdf1: "LTAIPT2018_A63F01.pdf",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F02A.xlsx",
      pdf1: "LTAIPT2018_A63F02A.pdf",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F02B.xlsx",
      pdf1: "LTAIPT2018_A63F02B.pdf",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F03.xlsx",
      pdf1: "LTAIPT2018_A63F03.pdf",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F04.xlsx",
      pdf1: "LTAIPT2018_A63F04.pdf",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F05.xlsx",
      pdf1: "LTAIPT2018_A63F05.pdf",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F06.xlsx",
      pdf1: "LTAIPT2018_A63F06.pdf",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F07.xlsx",
      pdf1: "LTAIPT2018_A63F07.pdf",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F08.xlsx",
      pdf1: "LTAIPT2018_A63F08.pdf",
      managerId: null,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F09.xlsx",
      pdf1: "LTAIPT2018_A63F09.pdf",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "X-A",
      titulo: "Plazas vacantes del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F10A.xlsx",
      pdf1: "LTAIPT2018_A63F10A.pdf",
      managerId: 3,
    },
    {
      fraccion: "X-B",
      titulo:
        "Total de plazas vacantes y ocupadas del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F10B.xlsx",
      pdf1: "LTAIPT2018_A63F10B.pdf",
      managerId: 3,
    },
    {
      fraccion: "XI",
      titulo: "Contrataciones de servicios profesionales por honorarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F11.xlsx",
      pdf1: "LTAIPT2018_A63F11.pdf",
      managerId: null,
    },
    // {
    //   fraccion: "XII",
    //   titulo:
    //     "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
    //   cumplimiento: "Aplica",
    //     "Fraccion XII",
    //   excel1: "LTAIPT2018_A63F12.xlsx",
    //   pdf1: "LTAIPT2018_A63F12.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XIII",
    //   titulo: "Domicilio de la Unidad de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XIII",
    //   excel1: "LTAIPT2018_A63F13.xlsx",
    //   pdf1: "LTAIPT2018_A63F13.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XIV",
    //   titulo: "Concursos para ocupar cargos públicos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XIV",
    //   excel1: "LTAIPT2018_A63F14.xlsx",
    //   pdf1: "LTAIPT2018_A63F14.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 4,
    //   fraccion: "XV",
    //   titulo: "Subsidios, estímulos y apoyos",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XV",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XV-A",
    //   titulo: "Programas sociales",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XV-A",
    //   managerId: 4,
    // },
    // {
    //   fraccion: "XV-B",
    //   titulo: "Padrón de beneficiarios de programas sociales",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XV-B",
    //   managerId: 4,
    // },
    // {
    //   id: 5,
    //   fraccion: "XVI",
    //   titulo: "Condiciones generales de trabajo y sindicatos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XVI",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XVI-A",
    //   titulo: "Condiciones generales de trabajo y sindicatos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XVI",
    //   excel1: "LTAIPT2018_A63F16A.xlsx",
    //   pdf1: "LTAIPT2018_A63F16A.pdf",
    //   managerId: 5,
    // },
    // {
    //   fraccion: "XVII",
    //   titulo: "Información curricular de los(as) servidores(as) públicas(os)",
    //   cumplimiento: "Aplica",
    //     "Fraccion XVII",
    //   excel1: "LTAIPT2018_A63F17.xlsx",
    //   pdf1: "LTAIPT2018_A63F17.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XVIII",
    //   titulo: "Sanciones administrativa",
    //   cumplimiento: "Aplica",
    //     "Fraccion XVIII",
    //   excel1: "LTAIPT2018_A63F18.xlsx",
    //   pdf1: "LTAIPT2018_A63F18.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XIX",
    //   titulo: "Servicios que ofrece el sujeto obligado",
    //   cumplimiento: "Aplica",
    //     "Fraccion XIX",
    //   excel1: "LTAIPT2018_A63F19.xlsx",
    //   pdf1: "LTAIPT2018_A63F19.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XX",
    //   titulo: "Trámites que se realizan",
    //   cumplimiento: "Aplica",
    //     "Fraccion XX",
    //   excel1: "LTAIPT2018_A63F20.xlsx",
    //   pdf1: "LTAIPT2018_A63F20.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 6,
    //   fraccion: "XXI",
    //   titulo: "Presupuesto asignado",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXI",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXI-A",
    //   titulo: "Presupuesto asignado anual",
    //   cumplimiento: "Aplica",
    //     "Fraccion XX-B",
    //   excel1: "LTAIPT2018_A63F21A.xlsx",
    //   pdf1: "LTAIPT2018_A63F21A.pdf",
    //   managerId: 6,
    // },
    // {
    //   fraccion: "XXI-B",
    //   titulo: "Ejercicio de los egresos presupuestarios",
    //   cumplimiento: "Aplica",
    //     "Fraccion XX-B",
    //   excel1: "LTAIPT2018_A63F21B.xlsx",
    //   pdf1: "LTAIPT2018_A63F21B.pdf",
    //   managerId: 6,
    // },
    // {
    //   fraccion: "XXI-C",
    //   titulo: "Cuenta pública",
    //   cumplimiento: "Aplica",
    //     "Fraccion XX-C",
    //   excel1: "LTAIPT2018_A63F21C.xlsx",
    //   pdf1: "LTAIPT2018_A63F21C.pdf",
    //   managerId: 6,
    // },
    // {
    //   fraccion: "XXII",
    //   titulo: "Deuda Pública",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XXII",
    //   managerId: null,
    // },
    // {
    //   id: 7,
    //   fraccion: "XXIII",
    //   titulo: "Gastos de publicidad oficial",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXIII-A",
    //   titulo: "Programa Anual de Comunicación Social o equivalente",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIII-A",
    //   excel1: "LTAIPT2018_A63F23A.xlsx",
    //   pdf1: "LTAIPT2018_A63F23A.pdf",
    //   managerId: 7,
    // },
    // {
    //   fraccion: "XXIII-B",
    //   titulo: "Contratación de servicios de publicidad oficial",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIII-B",
    //   excel1: "LTAIPT2018_A63F23B.xlsx",
    //   pdf1: "LTAIPT2018_A63F23B.pdf",
    //   managerId: 7,
    // },
    // {
    //   fraccion: "XXIII-C",
    //   titulo: "Utilización de los tiempos oficiales en radio y tv",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIII-C",
    //   excel1: "LTAIPT2018_A63F23C.xlsx",
    //   pdf1: "LTAIPT2018_A63F23C.pdf",
    //   managerId: 7,
    // },
    // {
    //   fraccion: "XXIII-D",
    //   titulo:
    //     "Hipervínculo a información de tiempos oficiales en radio y televisión",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIII-D",
    //   excel1: "LTAIPT2018_A63F23D.xlsx",
    //   pdf1: "LTAIPT2018_A63F23D.pdf",
    //   managerId: 7,
    // },
    // {
    //   fraccion: "XXIV",
    //   titulo: "Resultados de auditorías realizadas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIV",
    //   excel1: "LTAIPT2018_A63F24.xlsx",
    //   pdf1: "LTAIPT2018_A63F24.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXV",
    //   titulo: "Resultados de la dictaminación de los estados financieros",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXV",
    //   excel1: "LTAIPT2018_A63F25.xlsx",
    //   pdf1: "LTAIPT2018_A63F25.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXVI",
    //   titulo: "Personas que usan recursos públicos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXVI",
    //   excel1: "LTAIPT2018_A63F26.xlsx",
    //   pdf1: "LTAIPT2018_A63F26.pdf",
    // },
    // {
    //   fraccion: "XXVII",
    //   titulo:
    //     "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXVII",
    //   excel1: "LTAIPT2018_A63F27.xlsx",
    //   pdf1: "LTAIPT2018_A63F27.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 8,
    //   fraccion: "XXVIII",
    //   titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXVIII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXVIII-A",
    //   titulo:
    //     "Procedimientos de licitación pública e invitación a cuando menos tres personas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXVIII-A",
    //   excel1: "LTAIPT2018_A63F28A.xlsx",
    //   pdf1: "LTAIPT2018_A63F28A.pdf",
    //   managerId: 8,
    // },
    // {
    //   fraccion: "XXVIII-B",
    //   titulo: "Procedimientos de adjudicación directa",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXVIII-B",
    //   excel1: "LTAIPT2018_A63F28B.xlsx",
    //   pdf1: "LTAIPT2018_A63F28B.pdf",
    //   managerId: 8,
    // },
    // {
    //   fraccion: "XXIX",
    //   titulo: "Informes emitidos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXIX",
    //   excel: "LTAIPT2018_A63F29.xlsx",
    //   pdf: "LTAIPT2018_A63F29.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXX",
    //   titulo: "Estadísticas generadas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXX",
    //   excel: "LTAIPT2018_A63F30.xlsx",
    //   pdf: "LTAIPT2018_A63F30.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 9,
    //   fraccion: "XXXI",
    //   titulo: "Informe financiero",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXI",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXI-A",
    //   titulo: "Gasto por Capítulo, Concepto y Partida",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXI-A",
    //   excel1: "LTAIPT2018_A63F31A.xlsx",
    //   pdf1: "LTAIPT2018_A63F31A.pdf",
    //   managerId: 9,
    // },
    // {
    //   fraccion: "XXXI-B",
    //   titulo: "Informes financieros contables, presupuestales y programáticos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXI-B",
    //   excel1: "LTAIPT2018_A63F31B.xlsx",
    //   pdf1: "LTAIPT2018_A63F31B.pdf",
    //   managerId: 9,
    // },
    // {
    //   fraccion: "XXXII",
    //   titulo: "Padrón de proveedores y contratistas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXII",
    //   excel1: "LTAIPT2018_A63F32.xlsx",
    //   pdf1: "LTAIPT2018_A63F32.pdf",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXIII",
    //   titulo:
    //     "Convenios de coordinación, de concertación con el sector social o privado",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIII",
    //   excel1: "LTAIPT2018_A63F33.xlsx",
    //   pdf1: "LTAIPT2018_A63F33.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 10,
    //   fraccion: "XXXIV",
    //   titulo: "Inventario",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXIV-A",
    //   titulo: "Inventario de altas practicadas a bienes muebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-A",
    //   excel1: "LTAIPT2018_A63F34A.xlsx",
    //   pdf1: "LTAIPT2018_A63F34A.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-B",
    //   titulo: "Inventario de altas practicadas a bienes muebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-B",
    //   excel1: "LTAIPT2018_A63F34B.xlsx",
    //   pdf1: "LTAIPT2018_A63F34B.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-C",
    //   titulo: "Inventario de bajas practicadas a bienes muebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-C",
    //   excel1: "LTAIPT2018_A63F34C.xlsx",
    //   pdf1: "LTAIPT2018_A63F34C.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-D",
    //   titulo: "Inventario de bienes inmuebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-D",
    //   excel1: "LTAIPT2018_A63F34D.xlsx",
    //   pdf1: "LTAIPT2018_A63F34D.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-E",
    //   titulo: "Inventario de altas practicadas a bienes inmuebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-E",
    //   excel1: "LTAIPT2018_A63F34E.xlsx",
    //   pdf1: "LTAIPT2018_A63F34E.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-F",
    //   titulo: "Inventario de bajas practicadas a bienes inmuebles",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-F",
    //   excel1: "LTAIPT2018_A63F34F.xlsx",
    //   pdf1: "LTAIPT2018_A63F34F.pdf",
    //   managerId: 10,
    // },
    // {
    //   fraccion: "XXXIV-G",
    //   titulo: "Inventario de bienes muebles e inmuebles donados",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIV-G",
    //   excel1: "LTAIPT2018_A63F34G.xlsx",
    //   pdf1: "LTAIPT2018_A63F34G.pdf",
    //   managerId: 10,
    // },
    // {
    //   id: 11,
    //   fraccion: "XXXV",
    //   titulo: "Recomendaciones derechos humanos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXV",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXV-A",
    //   titulo: "Recomendaciones de organismos garantes de derechos humanos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXV-A",
    //   excel1: "LTAIPT_A63F35A.xlsx",
    //   pdf1: "LTAIPT2018_A63F35A.pdf",
    //   managerId: 11,
    // },
    // {
    //   fraccion: "XXXV-B",
    //   titulo: "Casos especiales de organismos garantes de derechos humanos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXV-B",
    //   excel1: "LTAIPT_A63F35B.xlsx",
    //   pdf1: "LTAIPT2018_A63F35B.pdf",
    //   managerId: 11,
    // },
    // {
    //   fraccion: "XXXV-C",
    //   titulo:
    //     "Recomendaciones de organismos internacionales de derechos humanos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXV-C",
    //   excel1: "LTAIPT_A63F35C.xlsx",
    //   pdf1: "LTAIPT2018_A63F35C.pdf",
    //   managerId: 11,
    // },
    // {
    //   fraccion: "XXXVI",
    //   titulo: "Resoluciones y laudos emitidos",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XXXVI",
    //   managerId: null,
    // },
    // {
    //   id: 12,
    //   fraccion: "XXXVII",
    //   titulo: "Participación ciudadana",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXVII-A",
    //   titulo: "Mecanismos de participación ciudadana",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVII-A",
    //   excel1: "LTAIPT2018_A63F37A.xlsx",
    //   pdf1: "LTAIPT2018_A63F37A.pdf",
    //   managerId: 12,
    // },
    // {
    //   fraccion: "XXXVII-B",
    //   titulo: "Resultado de los mecanismos de participación",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVII-B",
    //   excel1: "LTAIPT2018_A63F37B.xlsx",
    //   pdf1: "LTAIPT2018_A63F37B.pdf",
    //   managerId: 12,
    // },
    // {
    //   id: 13,
    //   fraccion: "XXXVIII",
    //   titulo: "Otros programas",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVIII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXVIII-A",
    //   titulo: "Programas que ofrecen",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVIII-A",
    //   excel1: "LTAIPT2018_A63F38A.xlsx",
    //   pdf1: "LTAIPT2018_A63F38A.pdf",
    //   managerId: 13,
    // },
    // {
    //   fraccion: "XXXVIII-B",
    //   titulo: "Trámites para acceder a programas que ofrecen",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXVIII-B",
    //   excel1: "LTAIPT2018_A63F38B.xlsx",
    //   pdf1: "LTAIPT2018_A63F38B.pdf",
    //   managerId: 13,
    // },
    // {
    //   id: 14,
    //   fraccion: "XXXIX",
    //   titulo: "Actas y resoluciones Comité de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIX",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XXXIX-A",
    //   titulo: "Informe de sesiones del Comité de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIX-A",
    //   excel1: "LTAIPT2018_A63F39A.xlsx",
    //   pdf1: "LTAIPT2018_A63F39A.pdf",
    //   managerId: 14,
    // },
    // {
    //   fraccion: "XXXIX-B",
    //   titulo: "Informe de Resoluciones del Comité de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIX-B",
    //   excel1: "LTAIPT2018_A63F39B.xlsx",
    //   pdf1: "LTAIPT2018_A63F39B.pdf",
    //   managerId: 14,
    // },
    // {
    //   fraccion: "XXXIX-C",
    //   titulo: "Integrantes del Comité de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIX-C",
    //   excel1: "LTAIPT2018_A63F39C.xlsx",
    //   pdf1: "LTAIPT2018_A63F39C.pdf",
    //   managerId: 14,
    // },
    // {
    //   fraccion: "XXXIX-D",
    //   titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
    //   cumplimiento: "Aplica",
    //     "Fraccion XXXIX-D",
    //   excel1: "LTAIPT2018_A63F39D.xlsx",
    //   pdf1: "LTAIPT2018_A63F39D.pdf",
    //   managerId: 14,
    // },
    // {
    //   id: 15,
    //   fraccion: "XL",
    //   titulo: "Evaluación y encuesta programas financiados",
    //   cumplimiento: "Aplica",
    //     "Fraccion XL",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XL-A",
    //   titulo:
    //     "Evaluaciones y encuestas a programas financiados con recursos públicos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XL-A",
    //   excel1: "LTAIPT2018_A63F40A.xlsx",
    //   pdf1: "LTAIPT2018_A63F40A.pdf",
    //   managerId: 15,
    // },
    // {
    //   fraccion: "XL-B",
    //   titulo: "Encuestas sobre programas financiados con recursos públicos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XL-B",
    //   excel1: "LTAIPT2018_A63F40B.xlsx",
    //   pdf1: "LTAIPT2018_A63F40B.pdf",
    //   managerId: 15,
    // },
    // {
    //   fraccion: "XLI",
    //   titulo: "Estudios financiados con recursos públicos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLI",
    //   excel1: "LTAIPT2018_A63F41.xlsx",
    //   pdf1: "LTAIPT2018_A63F41.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 16,
    //   fraccion: "XLII",
    //   titulo: "Jubilados y pensionados",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XLII-A",
    //   titulo: "Hipervínculo al listado de pensionados y jubilados",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLII-A",
    //   excel1: "LTAIPT2018_A63F42A.xlsx",
    //   pdf1: "LTAIPT2018_A63F42A.pdf",
    //   managerId: 16,
    // },
    // {
    //   fraccion: "XLII-B",
    //   titulo: "Listado de jubilados y pensionados y el monto que reciben",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLII-B",
    //   excel1: "LTAIPT2018_A63F42B.xlsx",
    //   pdf1: "LTAIPT2018_A63F42B.pdf",
    //   managerId: 16,
    // },
    // {
    //   id: 17,
    //   fraccion: "XLIII",
    //   titulo: "Ingresos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLIII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XLIII-A",
    //   titulo:
    //     "Ingresos recibidos por cualquier concepto por el sujeto obligado",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLIII-A",
    //   excel1: "LTAIPT2018_A63F43A.xlsx",
    //   pdf1: "LTAIPT2018_A63F43A.pdf",
    //   managerId: 17,
    // },
    // {
    //   fraccion: "XLIII-B",
    //   titulo: "Responsables de recibir, administrar y ejercer los ingresos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLIII-B",
    //   excel1: "LTAIPT2018_A63F43B.xlsx",
    //   pdf1: "LTAIPT2018_A63F43B.pdf",
    //   managerId: 17,
    // },
    // {
    //   id: 18,
    //   fraccion: "XLIV",
    //   titulo: "Donaciones",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XLIV",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XLIV-A",
    //   titulo: "Donaciones en dinero realizadas",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XLIV-A",
    //   managerId: 18,
    // },
    // {
    //   fraccion: "XLIV-B",
    //   titulo: "Donaciones en especie realizadas",
    //   cumplimiento: "No Aplica",
    //     "Fraccion XLIV-B",
    //   managerId: 18,
    // },
    // {
    //   fraccion: "XLV",
    //   titulo: "Catálogo de disposición documental y guía simple de archivos",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLV",
    //   excel1: "LTAIPT2018_A63F45.xlsx",
    //   pdf1: "LTAIPT2018_A63F45.pdf",
    //   managerId: null,
    // },
    // {
    //   id: 19,
    //   fraccion: "XLVI",
    //   titulo: "Actas de sesiones",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVI",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XLVI-A",
    //   titulo: "Actas del Consejo Consultivo",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVI-A",
    //   excel1: "LTAIPT2018_A63F46A.xlsx",
    //   pdf1: "LTAIPT2018_A63F46A.pdf",
    //   managerId: 19,
    // },
    // {
    //   fraccion: "XLVI-B",
    //   titulo: "Opiniones y recomendaciones del Consejo Consultivo",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVI-B",
    //   excel1: "LTAIPT2018_A63F46B.xlsx",
    //   pdf1: "LTAIPT2018_A63F46B.pdf",
    //   managerId: 19,
    // },
    // {
    //   id: 20,
    //   fraccion: "XLVII",
    //   titulo: "Más información relacionada",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVII",
    //   managerId: null,
    // },
    // {
    //   fraccion: "XLVII-A",
    //   titulo: "Información de interés público",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVII-A",
    //   excel1: "LTAIPT2018_A63F47A.xlsx",
    //   pdf1: "LTAIPT2018_A63F47A.pdf",
    //   managerId: 20,
    // },
    // {
    //   fraccion: "XLVII-B",
    //   titulo: "Preguntas frecuentes",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVII-B",
    //   excel1: "LTAIPT2018_A63F47B.xlsx",
    //   pdf1: "LTAIPT2018_A63F47B.pdf",
    //   managerId: 20,
    // },
    // {
    //   fraccion: "XLVII-C",
    //   titulo: "Transparencia proactiva",
    //   cumplimiento: "Aplica",
    //     "Fraccion XLVII-C",
    //   excel1: "LTAIPT2018_A63F47C.xlsx",
    //   pdf1: "LTAIPT2018_A63F47C.pdf",
    //   managerId: 20,
    // },
    // {
    //   fraccion: "C",
    //   titulo: "Obligaciones aplicables",
    //   cumplimiento: "Aplica",
    //     "Fraccion C",
    //   excel1: "LTAIPT2018_A63F100.xlsx",
    //   pdf1: "LTAIPT2018_A63F100.pdf",
    // },
  ],

  2019: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F01.xlsx",
      pdf1: "LTAIPT2018_A63F01.pdf",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F02A_1.xlsx",
      excel2: "LTAIPT2018_A63F02A_2.xlsx",
      excel3: "LTAIPT2018_A63F02A_3.xlsx",
      pdf1: "LTAIPT2018_A63F02A_1.pdf",
      pdf2: "LTAIPT2018_A63F02A_2.pdf",
      pdf3: "LTAIPT2018_A63F02A_3.pdf",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F02B_1.xlsx",
      excel2: "LTAIPT2018_A63F02B_2.xlsx",
      excel3: "LTAIPT2018_A63F02B_3.xlsx",
      pdf1: "LTAIPT2018_A63F02B_1.pdf",
      pdf2: "LTAIPT2018_A63F02B_2.pdf",
      pdf3: "LTAIPT2018_A63F02B_3.pdf",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F03_1.xlsx",
      excel2: "LTAIPT2018_A63F03_2.xlsx",
      excel3: "LTAIPT2018_A63F03_3.xlsx",
      pdf1: "LTAIPT2018_A63F03_1.pdf",
      pdf2: "LTAIPT2018_A63F03_2.pdf",
      pdf3: "LTAIPT2018_A63F03_3.pdf",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F04_1.xlsx",
      excel2: "LTAIPT2018_A63F04_2.xlsx",
      pdf1: "LTAIPT2018_A63F04_1.pdf",
      pdf2: "LTAIPT2018_A63F04_2.pdf",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F05_1.xlsx",
      excel2: "LTAIPT2018_A63F05_2.xlsx",
      excel3: "LTAIPT2018_A63F05_3.xlsx",
      excel4: "LTAIPT2018_A63F05_4.xlsx",
      pdf1: "LTAIPT2018_A63F05_1.pdf",
      pdf2: "LTAIPT2018_A63F05_2.pdf",
      pdf3: "LTAIPT2018_A63F05_3.pdf",
      pdf4: "LTAIPT2018_A63F05_4.pdf",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F06_1.xlsx",
      excel2: "LTAIPT2018_A63F06_2.xlsx",
      excel3: "LTAIPT2018_A63F06_3.xlsx",
      excel4: "LTAIPT2018_A63F06_4.xlsx",
      pdf1: "LTAIPT2018_A63F06_1.pdf",
      pdf2: "LTAIPT2018_A63F06_2.pdf",
      pdf3: "LTAIPT2018_A63F06_3.pdf",
      pdf4: "LTAIPT2018_A63F06_4.pdf",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F07_1.xlsx",
      excel2: "LTAIPT2018_A63F07_2.xlsx",
      excel3: "LTAIPT2018_A63F07_3.xlsx",
      pdf1: "LTAIPT2018_A63F07_1.pdf",
      pdf2: "LTAIPT2018_A63F07_2.pdf",
      pdf3: "LTAIPT2018_A63F07_3.pdf",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F08_1.xlsx",
      excel2: "LTAIPT2018_A63F08_2.xlsx",
      pdf1: "LTAIPT2018_A63F08_1.pdf",
      pdf2: "LTAIPT2018_A63F08_2.pdf",
      managerId: null,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F09_1.xlsx",
      excel2: "LTAIPT2018_A63F09_2.xlsx",
      excel3: "LTAIPT2018_A63F09_3.xlsx",
      pdf1: "LTAIPT2018_A63F09_1.pdf",
      pdf2: "LTAIPT2018_A63F09_2.pdf",
      pdf3: "LTAIPT2018_A63F09_3.pdf",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "X-A",
      titulo: "Plazas vacantes del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F10A_1.xlsx",
      excel2: "LTAIPT2018_A63F10A_2.xlsx",
      excel3: "LTAIPT2018_A63F10A_3.xlsx",
      pdf1: "LTAIPT2018_A63F10A_1.pdf",
      pdf2: "LTAIPT2018_A63F10A_2.pdf",
      pdf3: "LTAIPT2018_A63F10A_3.pdf",
      managerId: 3,
    },
    {
      fraccion: "X-B",
      titulo:
        "Total de plazas vacantes y ocupadas del personal de base y confianza",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F10B_1.xlsx",
      excel2: "LTAIPT2018_A63F10B_2.xlsx",
      excel3: "LTAIPT2018_A63F10B_3.xlsx",
      pdf1: "LTAIPT2018_A63F10B_1.pdf",
      pdf2: "LTAIPT2018_A63F10B_2.pdf",
      pdf3: "LTAIPT2018_A63F10B_3.pdf",
      managerId: 3,
    },
    {
      fraccion: "XI",
      titulo: "Contrataciones de servicios profesionales por honorarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F11_1.xlsx",
      excel2: "LTAIPT2018_A63F11_2.xlsx",
      excel3: "LTAIPT2018_A63F11_3.xlsx",
      pdf1: "LTAIPT2018_A63F11_1.pdf",
      pdf2: "LTAIPT2018_A63F11_2.pdf",
      pdf3: "LTAIPT2018_A63F11_3.pdf",
      managerId: null,
    },
    {
      fraccion: "XII",
      titulo:
        "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F12_1.xlsx",
      excel2: "LTAIPT2019_A63F12_2.xlsx",
      pdf1: "LTAIPT2018_A63F12_1.pdf",
      pdf2: "LTAIPT2019_A63F12_2.pdf",
      managerId: null,
    },
    {
      fraccion: "XIII",
      titulo: "Domicilio de la Unidad de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F13.xlsx",
      pdf1: "LTAIPT2018_A63F13.pdf",
      managerId: null,
    },
    {
      fraccion: "XIV",
      titulo: "Concursos para ocupar cargos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F14_1.xlsx",
      excel2: "LTAIPT2018_A63F14_2.xlsx",
      excel3: "LTAIPT2018_A63F14_3.xlsx",
      pdf1: "LTAIPT2018_A63F14_1.pdf",
      pdf2: "LTAIPT2018_A63F14_2.pdf",
      pdf3: "LTAIPT2018_A63F14_3.pdf",
      managerId: null,
    },
    {
      id: 4,
      fraccion: "XV",
      titulo: "Subsidios, estímulos y apoyos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XV-A",
      titulo: "Programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      fraccion: "XV-B",
      titulo: "Padrón de beneficiarios de programas sociales",
      cumplimiento: "No Aplica",
      managerId: 4,
    },
    {
      id: 5,
      fraccion: "XVI",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XVI-A",
      titulo: "Condiciones generales de trabajo y sindicatos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F16A_1.xlsx",
      excel2: "LTAIPT2018_A63F16A_1.xlsx",
      pdf1: "LTAIPT2018_A63F16A_1.pdf",
      pdf2: "LTAIPT2018_A63F16A_1.pdf",
      managerId: 5,
    },
    {
      fraccion: "XVII",
      titulo: "Información curricular de los(as) servidores(as) públicas(os)",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F17_1.xlsx",
      excel2: "LTAIPT2018_A63F17_2.xlsx",
      excel3: "LTAIPT2018_A63F17_3.xlsx",
      pdf1: "LTAIPT2018_A63F17_1.pdf",
      pdf2: "LTAIPT2018_A63F17_2.pdf",
      pdf3: "LTAIPT2018_A63F17_3.pdf",
      managerId: null,
    },
    {
      fraccion: "XVIII",
      titulo: "Sanciones administrativa",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F18_1.xlsx",
      excel2: "LTAIPT2019_A63F18_2.xlsx",
      pdf1: "LTAIPT2018_A63F18_1.pdf",
      pdf2: "LTAIPT2019_A63F18_2.pdf",
      managerId: null,
    },
    {
      fraccion: "XIX",
      titulo: "Servicios que ofrece el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F19.xlsx",
      pdf1: "LTAIPT2018_A63F19.pdf",
      managerId: null,
    },
    {
      fraccion: "XX",
      titulo: "Trámites que se realizan",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F20.xlsx",
      pdf1: "LTAIPT2018_A63F20.pdf",
      managerId: null,
    },
    {
      id: 6,
      fraccion: "XXI",
      titulo: "Presupuesto asignado",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXI-A",
      titulo: "Presupuesto asignado anual",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F21A_1.xlsx",
      excel2: "LTAIPT2018_A63F21A_2.xlsx",
      pdf1: "LTAIPT2018_A63F21A_1.pdf",
      pdf2: "LTAIPT2018_A63F21A_2.pdf",
      managerId: 6,
    },
    {
      fraccion: "XXI-B",
      titulo: "Ejercicio de los egresos presupuestarios",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F21B_1.xlsx",
      excel2: "LTAIPT2018_A63F21B_2.xlsx",
      pdf1: "LTAIPT2018_A63F21B_1.pdf",
      pdf2: "LTAIPT2018_A63F21B_2.pdf",
      managerId: 6,
    },
    {
      fraccion: "XXI-C",
      titulo: "Cuenta pública",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F21C_1.xlsx",
      excel2: "LTAIPT2018_A63F21C_2.xlsx",
      pdf1: "LTAIPT2018_A63F21C_1.pdf",
      pdf2: "LTAIPT2018_A63F21C_2.pdf",
      managerId: 6,
    },
    {
      fraccion: "XXII",
      titulo: "Deuda Pública",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 7,
      fraccion: "XXIII",
      titulo: "Gastos de publicidad oficial",
      cumplimiento: "Aplica",
      eddxcel5: "",
      managerId: null,
    },
    {
      fraccion: "XXIII-A",
      titulo: "Programa Anual de Comunicación Social o equivalente",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F23A_1.xlsx",
      excel2: "LTAIPT2018_A63F23A_2.xlsx",
      excel3: "LTAIPT2018_A63F23A_3.xlsx",
      excel4: "LTAIPT2018_A63F23A_4.xlsx",
      pdf1: "LTAIPT2018_A63F23A_1.pdf",
      pdf2: "LTAIPT2018_A63F23A_2.pdf",
      pdf3: "LTAIPT2018_A63F23A_3.pdf",
      pdf4: "LTAIPT2018_A63F23A_4.pdf",
      managerId: 7,
    },
    {
      fraccion: "XXIII-B",
      titulo: "Contratación de servicios de publicidad oficial",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F23B_1.xlsx",
      excel2: "LTAIPT2018_A63F23B_2.xlsx",
      excel3: "LTAIPT2018_A63F23B_3.xlsx",
      excel4: "LTAIPT2018_A63F23B_4.xlsx",
      pdf1: "LTAIPT2018_A63F23B_1.pdf",
      pdf2: "LTAIPT2018_A63F23B_2.pdf",
      pdf3: "LTAIPT2018_A63F23B_3.pdf",
      pdf4: "LTAIPT2018_A63F23B_4.pdf",
      managerId: 7,
    },
    {
      fraccion: "XXIII-C",
      titulo: "Utilización de los tiempos oficiales en radio y tv",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F23C_1.xlsx",
      excel2: "LTAIPT2018_A63F23C_2.xlsx",
      excel3: "LTAIPT2018_A63F23C_3.xlsx",
      excel4: "LTAIPT2018_A63F23C_4.xlsx",
      pdf1: "LTAIPT2018_A63F23C_1.pdf",
      pdf2: "LTAIPT2018_A63F23C_2.pdf",
      pdf3: "LTAIPT2018_A63F23C_3.pdf",
      pdf4: "LTAIPT2018_A63F23C_4.pdf",
      managerId: 7,
    },
    {
      fraccion: "XXIII-D",
      titulo:
        "Hipervínculo a información de tiempos oficiales en radio y televisión",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F23D_1.xlsx",
      excel2: "LTAIPT2018_A63F23D_2.xlsx",
      excel3: "LTAIPT2018_A63F23D_3.xlsx",
      excel4: "LTAIPT2018_A63F23D_4.xlsx",
      pdf1: "LTAIPT2018_A63F23D_1.pdf",
      pdf2: "LTAIPT2018_A63F23D_2.pdf",
      pdf3: "LTAIPT2018_A63F23D_3.pdf",
      pdf14: "LTAIPT2018_A63F23D_4.pdf",
      managerId: 7,
    },
    {
      fraccion: "XXIV",
      titulo: "Resultados de auditorías realizadas",
      cumplimiento: "Aplica",
      excel11: "LTAIPT2018_A63F24_1.xlsx",
      exc1el2: "LTAIPT2018_A63F24_2.xlsx",
      excel3: "LTAIPT2019_A63F24_3.xlsx",
      excel4: "LTAIPT2018_A63F24_4.xlsx",
      pdf1: "LTAIPT2018_A63F24_1.pdf",
      pdf2: "LTAIPT2018_A63F24_2.pdf",
      pdf3: "LTAIPT2019_A63F24_3.pdf",
      pdf4: "LTAIPT2018_A63F24_4.pdf",
      managerId: null,
    },
    {
      fraccion: "XXV",
      titulo: "Resultados de la dictaminación de los estados financieros",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F25_1.xlsx",
      excel2: "LTAIPT2018_A63F25_2.xlsx",
      excel3: "LTAIPT2018_A63F25_3.xlsx",
      pdf1: "LTAIPT2018_A63F25_1.pdf",
      pdf2: "LTAIPT2018_1A63F25_2.pdf",
      pdf3: "LTAIPT20118_A63F25_3.pdf",
      managerId: null,
    },
    {
      fraccion: "XXVI",
      titulo: "Personas que usan recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F26_1.xlsx",
      excel2: "LTAIPT2018_A63F26_2.xlsx",
      excel3: "LTAIPT2018_A63F26_3.xlsx",
      excel4: "LTAIPT2018_A63F26_4.xlsx",
      pdf1: "LTAIPT2018_A63F26_1.pdf",
      pdf2: "LTAIPT2018_A63F26_2.pdf",
      pdf3: "LTAIPT2018_A63F26_3.pdf",
      pdf4: "LTAIPT2018_A63F26_4.pdf",
      managerId: null,
    },
    {
      fraccion: "XXVII",
      titulo:
        "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F27_1.xlsx",
      excel2: "LTAIPT2018_A63F27_2.xlsx",
      excel3: "LTAIPT2018_A63F27_3.xlsx",
      excel4: "LTAIPT2018_A63F27_4.xlsx",
      pdf1: "LTAIPT2018_A63F27_1.pdf",
      pdf2: "LTAIPT2018_A63F27_2.pdf",
      pdf3: "LTAIPT2018_A63F27_3.pdf",
      pdf4: "LTAIPT2018_A63F27_4.pdf",
      managerId: null,
    },
    {
      id: 8,
      fraccion: "XXVIII",
      titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXVIII-A",
      titulo:
        "Procedimientos de licitación pública e invitación a cuando menos tres personas",
      cumplimiento: "Aplica",
      managerId: 8,
    },
    {
      fraccion: "XXVIII-B",
      titulo: "Procedimientos de adjudicación directa",
      cumplimiento: "Aplica",
      managerId: 8,
    },
    {
      fraccion: "XXIX",
      titulo: "Informes emitidos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F29_1.xlsx",
      excel2: "LTAIPT2018_A63F29_2.xlsx",
      pdf1: "LTAIPT2018_A63F29_1.pdf",
      pdf2: "LTAIPT2018_A63F29_2.pdf",
      managerId: null,
    },
    {
      fraccion: "XXX",
      titulo: "Estadísticas generadas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F30_1.xlsx",
      excel2: "LTAIPT2018_A63F30_2.xlsx",
      excel3: "LTAIPT2018_A63F30_3.xlsx",
      excel4: "LTAIPT2018_A63F30_4.xlsx",
      excel5: "LTAIPT2018_A63F30_5.xlsx",
      pdf1: "LTAIPT2018_A63F30_1.pdf",
      pdf2: "LTAIPT2018_A63F30_2.pdf",
      pdf3: "LTAIPT2018_A63F30_3.pdf",
      pdf4: "LTAIPT2018_A63F30_4.pdf",
      pdf5: "LTAIPT2018_A63F30_5.pdf",
      managerId: null,
    },
    {
      id: 9,
      fraccion: "XXXI",
      titulo: "Informe financiero",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXI-A",
      titulo: "Gasto por Capítulo, Concepto y Partida",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F31A_1.xlsx",
      excel2: "LTAIPT2018_A63F31A_2.xlsx",
      excel3: "LTAIPT2018_A63F31A_3.xlsx",
      pdf1: "LTAIPT2018_A63F31A_1.pdf",
      pdf2: "LTAIPT2018_A63F31A_2.pdf",
      pdf3: "LTAIPT2018_A63F31A_3.pdf",
      managerId: 9,
    },
    {
      fraccion: "XXXI-B",
      titulo: "Informes financieros contables, presupuestales y programáticos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F31B_1.xlsx",
      excel2: "LTAIPT2018_A63F31B_2.xlsx",
      excel3: "LTAIPT2018_A63F31B_3.xlsx",
      pdf1: "LTAIPT2018_A63F31B_1.pdf",
      pdf2: "LTAIPT2018_A63F31B_2.pdf",
      pdf3: "LTAIPT2018_A63F31B_3.pdf",
      managerId: 9,
    },
    {
      fraccion: "XXXII",
      titulo: "Padrón de proveedores y contratistas",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F32_1.xlsx",
      excel2: "LTAIPT2018_A63F32_2.xlsx",
      excel3: "LTAIPT2018_A63F32_3.xlsx",
      excel4: "LTAIPT2018_A63F32_4.xlsx",
      pdf1: "LTAIPT2018_A63F32_1.pdf",
      pdf2: "LTAIPT2018_A63F32_2.pdf",
      pdf3: "LTAIPT2018_A63F32_3.pdf",
      pdf4: "LTAIPT2018_A63F32_4.pdf",
      managerId: null,
    },
    {
      fraccion: "XXXIII",
      titulo:
        "Convenios de coordinación, de concertación con el sector social o privado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F33_1.xlsx",
      excel2: "LTAIPT2018_A63F33_2.xlsx",
      excel3: "LTAIPT2018_A63F33_3.xlsx",
      pdf1: "LTAIPT2018_A63F33_1.pdf",
      pdf2: "LTAIPT2018_A63F33_2.pdf",
      pdf3: "LTAIPT2018_A63F33_3.pdf",
      managerId: null,
    },
    {
      id: 10,
      fraccion: "XXXIV",
      titulo: "Inventario",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIV-A",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34A_1.xlsx",
      excel2: "LTAIPT2018_A63F34A_2.xlsx",
      pdf1: "LTAIPT2018_A63F34A_1.pdf",
      pdf2: "LTAIPT2018_A63F34A_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-B",
      titulo: "Inventario de altas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34B_1.xlsx",
      excel2: "LTAIPT2018_A63F34B_2.xlsx",
      pdf1: "LTAIPT2018_A63F34B_1.pdf",
      pdf2: "LTAIPT2018_A63F34B_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-C",
      titulo: "Inventario de bajas practicadas a bienes muebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34C_1.xlsx",
      excel2: "LTAIPT2018_A63F34C_2.xlsx",
      pdf1: "LTAIPT2018_A63F34C_1.pdf",
      pdf2: "LTAIPT2018_A63F34C_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-D",
      titulo: "Inventario de bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34D_1.xlsx",
      excel2: "LTAIPT2018_A63F34D_2.xlsx",
      pdf1: "LTAIPT2018_A63F34D_1.pdf",
      pdf2: "LTAIPT2018_A63F34D_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-E",
      titulo: "Inventario de altas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34E_1.xlsx",
      excel2: "LTAIPT2018_A63F34E_2.xlsx",
      pdf1: "LTAIPT2018_A63F34E_1.pdf",
      pdf2: "LTAIPT2018_A63F34E_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-F",
      titulo: "Inventario de bajas practicadas a bienes inmuebles",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34F_1.xlsx",
      excel2: "LTAIPT2018_A63F34F_2.xlsx",
      pdf1: "LTAIPT2018_A63F34F_1.pdf",
      pdf2: "LTAIPT2018_A63F34F_2.pdf",
      managerId: 10,
    },
    {
      fraccion: "XXXIV-G",
      titulo: "Inventario de bienes muebles e inmuebles donados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F34G_1.xlsx",
      excel2: "LTAIPT2018_A63F34G_2.xlsx",
      pdf1: "LTAIPT2018_A63F34G_1.pdf",
      pdf2: "LTAIPT2018_A63F34G_2.pdf",
      managerId: 10,
    },
    {
      id: 11,
      fraccion: "XXXV",
      titulo: "Recomendaciones derechos humanos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXV-A",
      titulo: "Recomendaciones de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35A.xlsx",
      pdf1: "LTAIPT2018_A63F35A_1.pdf",
      managerId: 11,
    },
    {
      fraccion: "XXXV-B",
      titulo: "Casos especiales de organismos garantes de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35B.xlsx",
      pdf1: "LTAIPT2018_A63F35B_1.pdf",
      managerId: 11,
    },
    {
      fraccion: "XXXV-C",
      titulo:
        "Recomendaciones de organismos internacionales de derechos humanos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A63F35C.xlsx",
      pdf1: "LTAIPT2018_A63F35C_1.pdf",
      managerId: 11,
    },
    {
      fraccion: "XXXVI",
      titulo: "Resoluciones y laudos emitidos",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      id: 12,
      fraccion: "XXXVII",
      titulo: "Participación ciudadana",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVII-A",
      titulo: "Mecanismos de participación ciudadana",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F37A_1.xlsx",
      excel2: "LTAIPT2018_A63F37A_2.xlsx",
      excel3: "LTAIPT2018_A63F37A_3.xlsx",
      pdf1: "LTAIPT2018_A63F37A_1.pdf",
      pdf2: "LTAIPT2018_A63F37A_2.pdf",
      pdf3: "LTAIPT2018_A63F37A_3.pdf",
      managerId: 12,
    },
    {
      fraccion: "XXXVII-B",
      titulo: "Resultado de los mecanismos de participación",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F37B_1.xlsx",
      excel2: "LTAIPT2018_A63F37B_2.xlsx",
      excel3: "LTAIPT2018_A63F37B_3.xlsx",
      pdf1: "LTAIPT2018_A63F37B_1.pdf",
      pdf2: "LTAIPT2018_A63F37B_2.pdf",
      pdf3: "LTAIPT2018_A63F37B_3.pdf",
      managerId: 12,
    },
    {
      id: 13,
      fraccion: "XXXVIII",
      titulo: "Otros programas",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXVIII-A",
      titulo: "Programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F38A_1.xlsx",
      excel2: "LTAIPT2018_A63F38A_2.xlsx",
      excel3: "LTAIPT2018_A63F38A_3.xlsx",
      excel4: "LTAIPT2018_A63F38A_4.xlsx",
      excel5: "LTAIPT2018_A63F38A_5.xlsx",
      excel6: "LTAIPT2018_A63F38A_6.xlsx",
      pdf1: "LTAIPT2018_A63F38A_1.pdf",
      pdf2: "LTAIPT2018_A63F38A_2.pdf",
      pdf3: "LTAIPT2018_A63F38A_3.pdf",
      pdf4: "LTAIPT2018_A63F38A_4.pdf",
      pdf5: "LTAIPT2018_A63F38A_5.pdf",
      pdf6: "LTAIPT2018_A63F38A_6.pdf",
      managerId: 13,
    },
    {
      fraccion: "XXXVIII-B",
      titulo: "Trámites para acceder a programas que ofrecen",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F38B_1.xlsx",
      excel2: "LTAIPT2018_A63F38B_2.xlsx",
      excel3: "LTAIPT2018_A63F38B_3.xlsx",
      excel4: "LTAIPT2018_A63F38B_4.xlsx",
      excel5: "LTAIPT2018_A63F38B_5.xlsx",
      excel6: "LTAIPT2018_A63F38B_6.xlsx",
      pdf1: "LTAIPT2018_A63F38B_1.pdf",
      pdf2: "LTAIPT2018_A63F38B_2.pdf",
      pdf3: "LTAIPT2018_A63F38B_3.pdf",
      pdf4: "LTAIPT2018_A63F38B_4.pdf",
      pdf5: "LTAIPT2018_A63F38B_5.pdf",
      pdf6: "LTAIPT2018_A63F38A_6.pdf",
      managerId: 13,
    },
    {
      id: 14,
      fraccion: "XXXIX",
      titulo: "Actas y resoluciones Comité de Transparencia",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XXXIX-A",
      titulo: "Informe de sesiones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel2: "LTAIPT2018_A63F39A_2.xlsx",
      excel3: "LTAIPT2018_A63F39A_3.xlsx",
      pdf2: "LTAIPT2018_A63F39A_2.pdf",
      pdf3: "LTAIPT2018_A63F39A_3.pdf",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-B",
      titulo: "Informe de Resoluciones del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel2: "LTAIPT2018_A63F39B_2.xlsx",
      excel3: "LTAIPT2018_A63F39B_3.xlsx",
      pdf2: "LTAIPT2018_A63F39B_2.pdf",
      pdf3: "LTAIPT2018_A63F39B_3.pdf",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-C",
      titulo: "Integrantes del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F39C_1.xlsx",
      excel2: "LTAIPT2018_A63F39C_2.xlsx",
      excel3: "LTAIPT2018_A63F39C_3.xlsx",
      pdf1: "LTAIPT2018_A63F39C_1.pdf",
      pdf2: "LTAIPT2018_A63F39C_2.pdf",
      pdf3: "LTAIPT2018_A63F39C_3.pdf",
      managerId: 14,
    },
    {
      fraccion: "XXXIX-D",
      titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F39D_1.xlsx",
      excel2: "LTAIPT2018_A63F39D_2.xlsx",
      excel3: "LTAIPT2018_A63F39D_3.xlsx",
      pdf1: "LTAIPT2018_A63F39D_1.pdf",
      pdf2: "LTAIPT2018_A63F39D_2.pdf",
      pdf3: "LTAIPT2018_A63F39D_3.pdf",
      managerId: 14,
    },
    {
      id: 15,
      fraccion: "XL",
      titulo: "Evaluación y encuesta programas financiados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XL-A",
      titulo:
        "Evaluaciones y encuestas a programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F40A_1.xlsx",
      excel2: "LTAIPT2018_A63F40A_2.xlsx",
      pdf1: "LTAIPT2018_A63F40A_1.pdf",
      pdf2: "LTAIPT2018_A63F40A_2.pdf",
      managerId: 15,
    },
    {
      fraccion: "XL-B",
      titulo: "Encuestas sobre programas financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F40B_1.xlsx",
      excel2: "LTAIPT2018_A63F40B_2.xlsx",
      pdf1: "LTAIPT2018_A63F40B_1.pdf",
      pdf2: "LTAIPT2018_A63F40B_2.pdf",
      managerId: 15,
    },
    {
      fraccion: "XLI",
      titulo: "Estudios financiados con recursos públicos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F41_1.xlsx",
      excel2: "LTAIPT2018_A63F41_2.xlsx",
      excel3: "LTAIPT2018_A63F41_3.xlsx",
      excel4: "LTAIPT2018_A63F41_4.xlsx",
      excel5: "LTAIPT2018_A63F41_5.xlsx",
      pdf1: "LTAIPT2018_A63F41_1.pdf",
      pdf2: "LTAIPT2018_A63F41_2.pdf",
      pdf3: "LTAIPT2018_A63F41_3.pdf",
      pdf4: "LTAIPT2018_A63F41_4.pdf",
      pdf5: "LTAIPT2018_A63F41_5.pdf",
      managerId: null,
    },
    {
      id: 16,
      fraccion: "XLII",
      titulo: "Jubilados y pensionados",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLII-A",
      titulo: "Hipervínculo al listado de pensionados y jubilados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F42A_1.xlsx",
      excel2: "LTAIPT2018_A63F42A_2.xlsx",
      excel3: "LTAIPT2018_A63F42A_3.xlsx",
      excel4: "LTAIPT2018_A63F42A_4.xlsx",
      pdf1: "LTAIPT2018_A63F42A_1.pdf",
      pdf2: "LTAIPT2018_A63F42A_2.pdf",
      pdf3: "LTAIPT2018_A63F42A_3.pdf",
      pdf4: "LTAIPT2018_A63F42A_4.pdf",
      managerId: 16,
    },
    {
      fraccion: "XLII-B",
      titulo: "Listado de jubilados y pensionados y el monto que reciben",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F42B_1.xlsx",
      excel2: "LTAIPT2018_A63F42B_2.xlsx",
      excel3: "LTAIPT2018_A63F42B_3.xlsx",
      excel4: "LTAIPT2018_A63F42B_4.xlsx",
      excel5: "LTAIPT2018_A63F42B_5.xlsx",
      pdf1: "LTAIPT2018_A63F42B_1.pdf",
      pdf2: "LTAIPT2018_A63F42B_2.pdf",
      pdf3: "LTAIPT2018_A63F42B_3.pdf",
      pdf4: "LTAIPT2018_A63F42B_4.pdf",
      managerId: 16,
    },
    {
      id: 17,
      fraccion: "XLIII",
      titulo: "Ingresos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIII-A",
      titulo:
        "Ingresos recibidos por cualquier concepto por el sujeto obligado",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F43A_1.xlsx",
      excel2: "LTAIPT2018_A63F43A_2.xlsx",
      excel3: "LTAIPT2018_A63F43A_3.xlsx",
      excel4: "LTAIPT2018_A63F43A_4.xlsx",
      pdf1: "LTAIPT2018_A63F43A_1.pdf",
      pdf2: "LTAIPT2018_A63F43A_2.pdf",
      pdf3: "LTAIPT2018_A63F43A_3.pdf",
      pdf4: "LTAIPT2018_A63F43A_4.pdf",
      managerId: 17,
    },
    {
      fraccion: "XLIII-B",
      titulo: "Responsables de recibir, administrar y ejercer los ingresos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F43B_1.xlsx",
      excel2: "LTAIPT2018_A63F43B_2.xlsx",
      excel3: "LTAIPT2018_A63F43B_3.xlsx",
      excel4: "LTAIPT2018_A63F43B_4.xlsx",
      pdf1: "LTAIPT2018_A63F43B_1.pdf",
      pdf2: "LTAIPT2018_A63F43B_2.pdf",
      pdf3: "LTAIPT2018_A63F43B_3.pdf",
      pdf4: "LTAIPT2018_A63F43B_4.pdf",
      managerId: 17,
    },
    {
      id: 18,
      fraccion: "XLIV",
      titulo: "Donaciones",
      cumplimiento: "No Aplica",
      managerId: null,
    },
    {
      fraccion: "XLIV-A",
      titulo: "Donaciones en dinero realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLIV-B",
      titulo: "Donaciones en especie realizadas",
      cumplimiento: "No Aplica",
      managerId: 18,
    },
    {
      fraccion: "XLV",
      titulo: "Catálogo de disposición documental y guía simple de archivos",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F45.xlsx",
      pdf1: "LTAIPT2018_A63F45.pdf",
      managerId: null,
    },
    {
      id: 19,
      fraccion: "XLVI",
      titulo: "Actas de sesiones",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVI-A",
      titulo: "Actas del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F46A_1.xlsx",
      excel2: "LTAIPT2018_A63F46A_2.xlsx",
      excel3: "LTAIPT2018_A63F46A_3.xlsx",
      excel4: "LTAIPT2018_A63F46A_4.xlsx",
      pdf1: "LTAIPT2018_A63F46A_1.pdf",
      pdf2: "LTAIPT2018_A63F46A_2.pdf",
      pdf3: "LTAIPT2018_A63F46A_3.pdf",
      pdf4: "LTAIPT2018_A63F46A_4.pdf",
      managerId: 19,
    },
    {
      fraccion: "XLVI-B",
      titulo: "Opiniones y recomendaciones del Consejo Consultivo",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F46B_1.xlsx",
      excel2: "LTAIPT2018_A63F46B_2.xlsx",
      excel3: "LTAIPT2018_A63F46B_3.xlsx",
      excel4: "LTAIPT2018_A63F46B_4.xlsx",
      pdf1: "LTAIPT2018_A63F46B_1.pdf",
      pdf2: "LTAIPT2018_A63F46B_2.pdf",
      pdf3: "LTAIPT2018_A63F46B_3.pdf",
      pdf4: "LTAIPT2018_A63F46B_4.pdf",
      managerId: 19,
    },
    {
      id: 20,
      fraccion: "XLVII",
      titulo: "Más información relacionada",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "XLVII-A",
      titulo: "Información de interés público",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F47A_1.xlsx",
      excel2: "LTAIPT2018_A63F47A_2.xlsx",
      excel3: "LTAIPT2018_A63F47A_3.xlsx",
      pdf1: "LTAIPT2018_A63F47A_1.pdf",
      pdf2: "LTAIPT2018_A63F47A_2.pdf",
      pdf3: "LTAIPT2018_A63F47A_3.pdf",
      managerId: 20,
    },
    {
      fraccion: "XLVII-B",
      titulo: "Preguntas frecuentes",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F47B_1.xlsx",
      excel2: "LTAIPT2018_A63F47B_2.xlsx",
      excel3: "LTAIPT2018_A63F47B_3.xlsx",
      pdf1: "LTAIPT2018_A63F47B_1.pdf",
      pdf2: "LTAIPT2018_A63F47B_2.pdf",
      pdf3: "LTAIPT2018_A63F47B_3.pdf",
      managerId: 20,
    },
    {
      fraccion: "XLVII-C",
      titulo: "Transparencia proactiva",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A63F47C_1.xlsx",
      excel2: "LTAIPT2018_A63F47C_2.xlsx",
      excel3: "LTAIPT2018_A63F47C_3.xlsx",
      pdf1: "LTAIPT2018_A63F47C_1.pdf",
      pdf2: "LTAIPT2018_A63F47C_2.pdf",
      pdf3: "LTAIPT2018_A63F47C_3.pdf",
      managerId: 20,
    },
    {
      fraccion: "C",
      titulo: "Obligaciones aplicables",
      cumplimiento: "Aplica",
      managerId: null,
    },
  ],

  2018: [
    {
      fraccion: "I",
      titulo: "Marco Normativo Aplicable de Sujeto Obligado",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F01.xlsx",
      pdf: "LTAIPT2018_A63F01.pdf",
      managerId: null,
    },
    {
      id: 1,
      fraccion: "II",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "II-A",
      titulo: "Estructura Orgánica",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F02A.xlsx",
      pdf: "LTAIPT2018_A63F02A.pdf",
      managerId: 1,
    },
    {
      fraccion: "II-B",
      titulo: "Organigrama",
      cumplimiento: "Aplica",
      managerId: 1,
    },
    {
      fraccion: "III",
      titulo: "Facultades de las áreas",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      fraccion: "IV",
      titulo: "Metas y objetivos de las áreas",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F04.xlsx",
      pdf: "LTAIPT2018_A63F04.pdf",
      managerId: null,
    },
    {
      fraccion: "V",
      titulo: "Los indicadores relacionados con temas de interés",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F05.xlsx",
      pdf: "LTAIPT2018_A63F05.pdf",
      managerId: null,
    },
    {
      fraccion: "VI",
      titulo: "Indicadores de objetivos y resultados",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F06.xlsx",
      pdf: "LTAIPT2018_A63F06.pdf",
      managerId: null,
    },
    {
      fraccion: "VII",
      titulo: "Directorio de servidores públicos",
      cumplimiento: "Aplica",
      managerId: null,
    },
    {
      id: 2,
      fraccion: "VIII",
      titulo: "Remuneración bruta y neta",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F08.xlsx",
      pdf: "LTAIPT2018_A63F08.pdf",
      managerId: null,
    },
    {
      fraccion: "IX",
      titulo: "Gastos por concepto de viáticos y representación",
      cumplimiento: "Aplica",
      excel: "LTAIPT2018_A63F09.xlsx",
      pdf: "LTAIPT2018_A63F09.pdf",
      managerId: null,
    },
    {
      id: 3,
      fraccion: "X",
      titulo: "Personal plazas y vacantes",
      cumplimiento: "Aplica",
    },
  ],
};
