import React, { useState } from "react";
import ListBadge from "../../layout/ListBadge";
import { dataPartidosPoliticos2 } from "../../data/dataEnlacesPP";

export const Accordion = ({ idAccordion, children }) => {
  return (
    <div className="accordion mb-4" id={idAccordion}>
      {children}
    </div>
  );
};

export const AccordionItem = ({
  flushID,
  titleItem,
  customTitle,
  contentItem,
  idAccordion,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${flushID}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`collapse-${flushID}`}
        >
          {customTitle || titleItem}{" "}
        </button>
      </h2>
      <div
        id={`collapse-${flushID}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading-${flushID}`}
        data-bs-parent={`#${idAccordion}`}
      >
        <div className="accordion-body">{contentItem}</div>
      </div>
    </div>
  );
};

const ProcesExtraordin = () => {
  const plataformasElectorales = dataPartidosPoliticos2.find(
    (item) => item.id === 3
  );

  return (
    <div className="margin-bottom-1">
      <h4 className="centered-text-0">
        Proceso Electoral Local Extraordinario
        <br />
        2024
      </h4>
      <h4 className="left-aligned-text mb-5">Coaliciones</h4>
      <Accordion idAccordion="coalicionesAccordion">
        {plataformasElectorales.children
          .filter((item) => item.id === 1)
          .map((item) => (
            <AccordionItem
              key={item.id}
              flushID={item.flushID}
              titleItem={item.titleItem}
              customTitle={`Coalición "Sigamos haciendo historia en Tlaxcala"`}
              contentItem={
                <ListBadge ifNumbered={""} listsBadgeItem={[item]} />
              }
              idAccordion="coalicionesAccordion"
            />
          ))}
      </Accordion>

      {plataformasElectorales.children
        .filter((item) => item.id !== 1)
        .map((item) => (
          <div key={item.id} className="mb-3">
            <ListBadge ifNumbered={""} listsBadgeItem={[item]} />
          </div>
        ))}
    </div>
  );
};

export default ProcesExtraordin;
