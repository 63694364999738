import React, { useEffect } from "react";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";

const EstructuraOrganica = () => {
  useEffect(() => {
    document.title = `Estructura Organica`;
  }, []);
  return (
    <>
      <Breadcrumbs path={[{ label: `Estructura Orgánica` }]} />
      <TitlePages title="Estructura Orgánica" subTitle="" />
      <a
        href="https://itetlax.org.mx/assets/img/estOrg/1.webp"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://itetlax.org.mx/assets/img/estOrg/1.webp"
          className="img-fluid w-100 mx-auto"
          style={{ marginBottom: "110px" }}
          alt="Estructura Organica"
        />
      </a>
    </>
  );
};
export default EstructuraOrganica;
