export const dataNormatividad = [
  {
    id: 1,
    nameDoc:
      "Lineamientos para el uso del correo electrónico institucional del Instituto Tlaxcalteca De Elecciones.",
    post: "",
    lastUpdate: "",
    pdf: "https://itetlax.org.mx/assets/pdf/archivistico/Lineamientos.pdf",
    pdfMobile: "",
    word: "",
    excel: "",
  },
];

export const dataPlaneacion = [
  {
    id: 1,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2022",
    pdf: "2",
  },
  {
    id: 2,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2023",
    word: "3",
  },
  {
    id: 3,
    nameDoc:
      "Informe Anual de Cumplimiento del Programa de Desarrollo Archivístico",
    pdf: "4",
  },
  {
    id: 4,
    nameDoc: "Integración de Sistema Institucional de Archivo",
    pdf: "11",
  },
  {
    id: 5,
    nameDoc: "Sistema Institucional de Archivos (SIA)",
    pdf: "11",
  },
  {
    id: 6,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2024",
    word: "9",
  },
  {
    id: 7,
    nameDoc:
      "Informe Anual de cumplimiento del Programa de Desarrollo Archivístico 2023",
    word: "10",
  },
];
export const dataInstrumentos = [
  {
    id: 1,
    nameDoc: "Cuadro General de Clasificación Archivística",
    post: "06/11/2023",
    lastUpdate: "",
    pdf: "1",
    excel: "",
  },
  {
    id: 2,
    nameDoc: "Catálogo de Disposición Documental",
    post: "06/11/2023",
    lastUpdate: "",
    pdf: "8",
    excel: "",
  },
  
  {
    id: 3,
    nameDoc: "Ficha Técnica De Valoración Documental",
    post: "03/03/2023",
    lastUpdate: "",
    pdf: "5",
    excel: "",
  },
 
];
