import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";

const TextParagraph = ({ children, strongText }) => (
  <p>
    {children}
    {strongText && <span className="text-strong">{strongText}</span>}
  </p>
);

const ParticipationStep = ({ step }) => <li>{step}</li>;

export const ParlamentoInfantil = () => {
  useEffect(() => {
    document.title = `Parlamento Infantil`;
  }, []);

  return (
    <>
      <Breadcrumbs
        path={[{ label: "Cultura Cívica" }, { label: "Parlamento Infantil" }]}
      />

      <TitlePages title="Cultura Cívica" subTitle="Parlamento Infantil" />

      <div className="margin-bottom-2">
        <TextParagraph strongText="edición anual del Parlamento Infantil.">
          Cada año el{" "}
          <span className="text-strong">
            Instituto Tlaxcalteca de Elecciones
          </span>{" "}
          colabora con aliados estratégicos como el Congreso del Estado, la
          Secretaría de Educación Pública del Estado, la Comisión Estatal de
          Derechos Humanos, COESPO-SIPINNA, Sistema DIF Estatal, entre otros,
          para convocar a la niñez tlaxcalteca a participar en la
        </TextParagraph>
        <h3>¿Cómo participar?</h3>
        <ol>
          <ParticipationStep step="Revisar la convocatoria que emita la Comisión Organizadora." />
          <ParticipationStep step="Tener entre 16 y 29 años, en caso de se mayor de edad, presentar copia de credencial de elector del participante, de lo contario copia de la credencial de elector del padre, madre o tutor/a de la persona joven participante, comprobante de domicilio con vigencia no mayor a sesenta días." />
          <ParticipationStep step="Realizar un escrito dirigido a la Comisión Organizadora, en la que deberán señalar los motivos por los que desea participar en el Parlamento Juvenil." />
          <ParticipationStep step="En caso de que la persona joven participante sea menor de edad, deberá presentar un escrito de autorización del padre, madre o tutor/a." />
          <ParticipationStep step="Reunir los requisitos y presentarlos ante el Congreso del Estado o en la Dirección del Organización Electoral, Capacitación y Educación Cívica del ITE." />
        </ol>
        <h3>Datos de contacto:</h3>
        <p>
          Dirección de Organización Electoral, Capacitación y Educación Cívica
          <br /> Ex – Fábrica San Manuel S/N, Barrio Nuevo, C.P. 90640, San
          Miguel Contla, Santa Cruz Tlaxcala, Tlaxcala.
          <br />
          Correo electrónico:{" "}
          <span className="text-strong">
            organizacionelectoral@itetlax.org.mx
          </span>
          <br />
          Tel. 246 46 50340 ext. 105 y 106
        </p>
      </div>
    </>
  );
};

export default ParlamentoInfantil;

TextParagraph.propTypes = {
  children: PropTypes.node,
  strongText: PropTypes.string,
};

ParticipationStep.propTypes = {
  step: PropTypes.string.isRequired,
};
