import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import { FilterComponent, useSimpleTableConfig } from "../../../constants";
import { dataMonitoreos } from "../../../data/dataMonitoreos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/monitoreos/";

const PdfLink = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCell =
  (year) =>
  ({ cell }) =>
    <PdfLink url={`${baseUrl}${year}/${cell.getValue()}.pdf`} />;

const MonitoreosTable = ({ year }) => {
  const data = useMemo(() => dataMonitoreos[year] || [], [year]);

  useEffect(() => {
    document.title = `Monitoreos ${year}`;
  }, [year]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "monthBoletin",
        header: "MES",
        size: 30,
        enableResizing: false,
      },
      {
        accessorKey: "nameBoletin",
        header: "Boletín.",
        Filter: FilterComponent,
        size: 150,
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnFilters: false,
        Cell: renderCell(year),
      },
    ],
    [year]
  );

  const table = useSimpleTableConfig(data, columns);

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Comunicación Social" },
          { label: "Monitoreos", url: "/Monitoreo" },
          { label: `Monitoreos ${year}` },
        ]}
      />
      <div className="margin-bottom-1">
        <TitlePages
          title="Comunicación Social"
          subTitle={`Monitoreos ${year}`}
        />
        <MaterialReactTable table={table} />
      </div>
    </>
  );
};
export default MonitoreosTable;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

renderCell.propTypes = {
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }).isRequired,
};

MonitoreosTable.propTypes = {
  year: PropTypes.string.isRequired,
};
